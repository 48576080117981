import {
  AutoCompleteResultItem,
  Category,
} from '@BookingPlatform/grpc/v1/Attraction/Attraction_pb';
import { useMsal } from '@azure/msal-react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CircularProgress from '@mui/material/CircularProgress';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Popper from '@mui/material/Popper';
import TextField from '@mui/material/TextField';
import {
  AttractionSearchFieldProps,
  AttractionSearchFieldValue,
} from 'pages/things-to-do/ThingsToDo.interface';
import { ChangeEvent, FC, FocusEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AttractionAutocompleteResultItem } from 'shared/components/autocomplete-result-item/AutocompleteResultItem';
import 'shared/components/search-container/SearchField.scss';
import { PageRoute } from 'shared/interfaces/config.interface';
import utils from 'shared/services/utilities.service';
import requestStoreService, { RequestStatus } from 'shared/store/request.reducer';
import { getLocations } from 'shared/store/request.thunk';
import { AppDispatch, RootState } from 'shared/store/root.store';
import { mapSearchFieldToAttractionType, sliceList } from 'utils/mapToValue';

const SearchField: FC<AttractionSearchFieldProps> = ({
  id,
  label,
  defaultValue,
  placeholder,
  error,
  onChange,
}) => {
  const { t } = useTranslation();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [listLoading, setListLoading] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [resultsLength, setResultsLength] = useState(1);

  const [inputValue, setInputValue] = useState(defaultValue?.name ?? '');
  const [selectedValue, setSelectedValue] = useState<AutoCompleteResultItem.AsObject | undefined>(
    defaultValue,
  );

  const [locations, setLocations] = useState<Array<AttractionSearchFieldValue>>([]);
  const [products, setProducts] = useState<Array<AttractionSearchFieldValue>>([]);
  const searchList = useSelector(requestStoreService.selector('locations'));

  const { instance, accounts } = useMsal();
  const { brandId, salesChannelId } = useSelector((state: RootState) => state.userSettings);

  const getList = useCallback(
    (searchString: string) => {
      if (searchString) {
        dispatch(
          getLocations({
            searchString,
            instance,
            account: accounts[0],
            brandId,
            salesChannelId,
          }),
        );
      }
    },
    [dispatch, accounts, instance, brandId, salesChannelId],
  );

  const debouncedGetList = useMemo(() => utils.debounce(getList, 500), [getList]);

  const searchInputChange = (event: any) => {
    const value = event.target.value.trimStart();
    const lengthReached = value.trim().length > 2;

    setLocations([]);
    setProducts([]);

    setInputValue(value);
    setListLoading(lengthReached);
    setDropdownOpen(lengthReached);

    if (lengthReached) {
      debouncedGetList(value.trim());
    }
  };

  useEffect(() => {
    setListLoading(searchList.status === RequestStatus.Loading);
  }, [searchList.status]);

  useEffect(() => {
    setInputValue(defaultValue?.name ?? '');
    setSelectedValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (searchList.value) {
      let locationList: AttractionSearchFieldValue[] = [];
      let productList: AttractionSearchFieldValue[] = [];

      searchList?.value?.categoriesList.forEach((categoryGroup) => {
        if (categoryGroup.category === Category.ATTRACTION_LOCATION) {
          const mappedLocationList = mapSearchFieldToAttractionType(
            categoryGroup.resultsList,
            Category.ATTRACTION_LOCATION,
          );
          locationList = sliceList(mappedLocationList);
        }

        if (categoryGroup.category === Category.ATTRACTION) {
          const mappedProductList = mapSearchFieldToAttractionType(
            categoryGroup.resultsList,
            Category.ATTRACTION,
          );
          productList = sliceList(mappedProductList);
        }
      });

      setLocations(locationList);
      setProducts(productList);
      setResultsLength(locationList.length + productList.length);
    }
  }, [searchList.value]);

  const onInputFocus = (event: FocusEvent<HTMLInputElement>) => {
    if (resultsLength <= 1 && selectedValue?.id) {
      getList(inputValue);
    }

    setDropdownOpen(inputValue.length > 2);
    setInitialLoad(false);
    event.target.select();
  };

  const selectListItem = (item: AttractionSearchFieldValue) => {
    onChange({ target: { value: item, name: id } } as any as ChangeEvent<any>);
    setDropdownOpen(false);
    setInputValue(item.name);
    setSelectedValue(item);
    if (item.type === 0) {
      // Navigate to product details page
      navigate(`${PageRoute.ProductDetails}/${item.id}`);
    }
  };

  return (
    <ClickAwayListener
      onClickAway={() => {
        setDropdownOpen(false);
        setInputValue(selectedValue?.name ? selectedValue.name : '');
      }}
    >
      <div className="search-wrap">
        <TextField
          id={id}
          className="search-field"
          label={label}
          placeholder={placeholder}
          error={error}
          autoFocus={!initialLoad}
          helperText={error && !dropdownOpen && t('productDetails.thingsToDo.searchError')}
          autoComplete="off"
          onFocus={onInputFocus}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
            }
          }}
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              setDropdownOpen(false);
              const target = e.target as EventTarget & HTMLInputElement;
              setInputValue(selectedValue?.name ?? defaultValue?.name ?? '');
              target.blur();
            }
          }}
          InputProps={{
            endAdornment: listLoading && <CircularProgress color="inherit" size={20} />,
          }}
          value={inputValue}
          onChange={searchInputChange}
        />

        <Popper
          className="search-popper"
          id="autocomplete-popper"
          placement="bottom-start"
          open={dropdownOpen}
          anchorEl={document.getElementById(id)}
          disablePortal
        >
          <div className="search-list">
            {!!resultsLength && !listLoading && (
              <>
                <div className="list">
                  {locations?.map((item) => (
                    <AttractionAutocompleteResultItem
                      key={item.id}
                      item={item}
                      type={`attraction${item.type}`}
                      selectListItem={selectListItem}
                    />
                  ))}
                </div>
                {!!products.length && !!locations.length && <div className="list-separator" />}
                <div className="list">
                  {products?.map((item) => (
                    <AttractionAutocompleteResultItem
                      key={item.id}
                      item={item}
                      type={`attraction${item.type}`}
                      selectListItem={selectListItem}
                    />
                  ))}
                </div>
              </>
            )}

            {listLoading && (
              <div className="list-info loading">
                {t('generic.loading')}
                ...
              </div>
            )}
            {!resultsLength && !listLoading && (
              <div className="list-info no-results">
                <ErrorOutlineIcon />
                {t('common.search.noResults')}
              </div>
            )}
          </div>
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

export default SearchField;
