import { List, ListItem, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useConfigContext } from 'shared/contexts/config.context';
import { Currency } from 'shared/interfaces/config.interface';
import { RootState } from 'shared/store/root.store';
import { getIconStringForCurrency } from 'utils/currencyUtils';
import { calculateTotalPriceOfTickets } from 'utils/seatmapUtils';
import { ExtendedTicketType, SelectedTicketsError } from './SeatingMap.interface';

const SelectedSeats: FC<{
  selectedSeats: ExtendedTicketType[];
  errors: SelectedTicketsError;
}> = ({ selectedSeats, errors }) => {
  const config = useConfigContext();
  const { t } = useTranslation();
  const { value: supplierList } = useSelector((state: RootState) => state.performanceAvailability);

  const currencyIcon = useMemo(() => {
    return getIconStringForCurrency(
      supplierList?.[0].availabilitydetails?.currencycode as Currency,
      config?.currencyList,
    );
  }, [config?.currencyList, supplierList]);

  return (
    <>
      {selectedSeats.length > 0 && (
        <TableContainer component={Paper}>
          <Table
            sx={{
              minWidth: 650,
              '& .MuiTableCell-root': { verticalAlign: 'text-top', textAlign: 'center' },
            }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow
                sx={{
                  backgroundColor: '#E0EFE9',
                  '*': { fontSize: '1em', fontWeight: 'bold' },
                }}
              >
                <TableCell>Ticket type</TableCell>
                <TableCell>{t('general.quantity')}</TableCell>
                <TableCell>{t('generic.ticketPrice')}</TableCell>
                <TableCell>{t('generic.totalPrice')}</TableCell>
                <TableCell sx={{ width: '35%' }}>
                  {t('productDetails.entertainment.seats')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                '& .MuiTableCell-root': {
                  ':first-of-type, :last-of-type': {
                    padding: '0 2em',
                  },
                },
              }}
            >
              {selectedSeats.map((item, index) => (
                <React.Fragment key={index}>
                  <TableRow
                    key={item.tickettoken}
                    sx={{
                      '& .MuiTableRow-root': {
                        padding: '8px !important',
                      },
                      td: {
                        padding: 0,
                      },
                      '& .MuiTableCell-root': {
                        borderBottom: errors[item.tickettype.tickettypecode] ? 'none' : '',
                      },
                    }}
                  >
                    <TableCell>{item.tickettype.tickettypename}</TableCell>
                    <TableCell>{item.quantity}</TableCell>
                    <TableCell>
                      {item.price} {currencyIcon}
                    </TableCell>
                    <TableCell>
                      {calculateTotalPriceOfTickets(item.quantity, item.price)} {currencyIcon}
                    </TableCell>
                    <TableCell>
                      <List sx={{ width: '100%', maxWidth: 360 }}>
                        {item.seatcodesList.map((seatCode, index) => (
                          <ListItem key={index} sx={{ justifyContent: 'center' }}>
                            {seatCode.presetname}
                          </ListItem>
                        ))}
                      </List>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    {errors[item.tickettype.tickettypecode] && (
                      <TableCell colSpan={5}>
                        <Typography
                          sx={{
                            margin: '1em 0',
                            minWidth: 650,
                            color: 'red',
                            fontStyle: 'italic',
                            fontSize: '.9rem',
                            textAlign: 'left',
                            '& .MuiTableCell-root': {
                              verticalAlign: 'text-top',
                            },
                          }}
                        >
                          {errors[item.tickettype.tickettypecode]}
                        </Typography>
                      </TableCell>
                    )}
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default SelectedSeats;
