import { ItemToReserve, TicketType } from '@BookingPlatform/grpc/v1/Entertainment/Entertainment_pb';
import {
  ExtendedTicketType,
  SelectedTicketsError,
} from 'pages/entertainment/results/EventDetailsPage/SeatingMap/SeatingMap.interface';
import { ExtendedTicketCategories } from 'pages/entertainment/results/EventDetailsPage/TicketTypeList';

// we must store additional information (like price and presetname of seat) with the selected tickets
// these are necessary information to show to the user
// but these are not needed in ReserveTicketsRequest, and not to be send
export function convertExtendedItemsToItemToReserve(
  item: ExtendedTicketType[],
): ItemToReserve.AsObject[] {
  return item.map(({ tickettoken, quantity, seatcodesList }) => ({
    tickettoken,
    quantity,
    seatcodesList: seatcodesList.map((seat) => seat.seatid),
  }));
}

export const calculateTotalPriceOfTickets = (
  numberOfTickets: number,
  price: string | undefined,
): number => {
  const parsedPrice = parseFloat(price || '0');

  if (isNaN(parsedPrice)) {
    // eslint-disable-next-line no-console
    console.warn(`Invalid price: ${price}`);
    return 0;
  }

  return Number((numberOfTickets * parsedPrice).toFixed(2));
};

export const getEventIdFromPath = (path: string): string | null => {
  const parts: string[] = path.split('/');

  if (parts[1] === 'event-details') {
    return parts[2];
  } else {
    return null;
  }
};

export const getTicketPrice = (
  ticketType: TicketType.AsObject,
  priceBandCode: string,
): string | undefined => {
  const priceBand = ticketType.pricebandsList.find(
    (band) => band.pricebandcode?.value === priceBandCode,
  );

  if (priceBand?.ticketprice) {
    return priceBand.ticketprice.value;
  }

  return undefined;
};

export const getTicketIndexInSelectedSeatsList = (
  selectedSeats: ExtendedTicketType[],
  ticketToken: string,
) => {
  return selectedSeats.findIndex((ticket) => ticket.tickettoken === ticketToken);
};

export const getTicketIndexInSelectedTickets = (
  selectedTickets: ExtendedTicketCategories,
  ticketToken: string,
) => {
  return Object.keys(selectedTickets).findIndex(
    (ticket) => selectedTickets[ticket].tickettoken === ticketToken,
  );
};

export function areAllErrorsCleared(errors: SelectedTicketsError): boolean {
  if (Object.keys(errors).length === 0) {
    return true;
  } else {
    return Object.values(errors).every((value) => value === undefined);
  }
}

export function getTicketTypeWithPriceband(
  ticketToken: string,
  tickettypesList: TicketType.AsObject[] | undefined,
) {
  return tickettypesList?.find((tt) =>
    tt.pricebandsList.some((priceband) => priceband.tickettoken === ticketToken),
  );
}
