import { GuestType } from '@BookingPlatform/grpc/v1/Shared/GuestType_pb';
import { useTranslation } from 'react-i18next';

const useGetGuestTypeName = () => {
  const { t } = useTranslation();

  const getGuestTypeName = (guestType: GuestType) => {
    switch (guestType) {
      case GuestType.ADULT:
        return t('bookingConfirmationPage.guestType.adult');
      case GuestType.CHILD:
        return t('bookingConfirmationPage.guestType.child');
      case GuestType.INFANT:
        return t('bookingConfirmationPage.guestType.infant');
      case GuestType.SENIOR:
        return t('bookingConfirmationPage.guestType.senior');
      case GuestType.TRAVELLER:
        return t('bookingConfirmationPage.guestType.traveller');
      case GuestType.YOUTH:
        return t('bookingConfirmationPage.guestType.youth');
      default:
        return t('bookingConfirmationPage.guestType.traveller');
    }
  };

  return { getGuestTypeName: getGuestTypeName };
};

export default useGetGuestTypeName;
