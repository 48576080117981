import { SeatData, SeatMapConfig } from './SeatingMap.interface';

class SeatingMap {
  private config: SeatMapConfig;
  private seatIdToTickenTokenMap: Map<string, SeatData>;

  constructor(config: SeatMapConfig) {
    this.config = config;
    this.seatIdToTickenTokenMap = new Map();
  }

  public initChart() {
    const seatPlanDataObject = JSON.parse(this.config.seatPlanData);
    if (seatPlanDataObject.SupplierName === 'nuticket') {
      this.initNuticketChart(
        seatPlanDataObject.SupplierSeatPlanData,
        seatPlanDataObject.PriceBandToTicketTokenMap,
      );
    } else if (seatPlanDataObject.SupplierName === 'ingresso') {
      this.initIngressoChart(
        seatPlanDataObject.SupplierSeatPlanData,
        seatPlanDataObject.TicketTypePriceBandTokenMap,
      );
    }
  }

  private initNuticketChart(supplierSeatMapData: string, pricebandMap: Map<string, string>) {
    const container = document.querySelector('#seatMap');

    const existingIframe = document.getElementById('nuticketIframe');

    if (!existingIframe) {
      const seatPlanDataObject = JSON.parse(supplierSeatMapData);
      const eventId = seatPlanDataObject.EventId;
      const rootUrl = seatPlanDataObject.RootUrl;

      window.addEventListener('message', (event) =>
        this.handleNuticketMessages(event, this.config, pricebandMap),
      );
      const iframe = document.createElement('iframe');

      iframe.style.width = '100%';
      iframe.style.height = '100%';
      iframe.setAttribute('id', 'nuticketIframe');

      iframe.setAttribute('src', `${rootUrl}/events/${eventId}/seating-plan`);
      container?.appendChild(iframe);
    }
  }

  private initIngressoChart(
    supplierSeatMapData: string,
    pricebandMap: Map<string, Map<string, string>>,
  ) {
    const featherScript = document.getElementById('ingressoFeatherScript');

    if (!featherScript) {
      const seatPlanDataObject = JSON.parse(supplierSeatMapData);
      const script = document.createElement('script');
      script.setAttribute(
        'src',
        'https://storage.googleapis.com/ticketswitch/feather/latest/feather.min.js',
      );
      script.id = 'ingressoFeatherScript';
      document.head?.appendChild(script);

      script.onload = () => {
        // Script has loaded successfully, you can now access its references

        const chartConfig = {
          eventID: seatPlanDataObject.EventId,
          perfID: seatPlanDataObject.PerformanceId,
          selector: '#seatMap',
          token: seatPlanDataObject.Token,
          silenceWarning: true
        };

        // @ts-ignore
        const chart = new IngressoSeatingPlan();
        chart.onAddSeat = (e: any) => {
          this.handleIngressoSeatSelect(e, this.config, pricebandMap);
        };
        chart.onRemoveSeat = (e: any) => {
          this.handleIngressoSeatUnselect(e, this.config);
        };
        chart.init(chartConfig);
      };
    }
  }

  private handleIngressoSeatSelect(event: any, config: SeatMapConfig, pricebandMap: any) {
    const seatuuid = event.seatData.uuid;
    // @ts-ignore
    const seatBundle = event.basket.seatBundles.find((sb) =>
    // @ts-ignore
      sb.seats.some((seat) => (seat.uuid === seatuuid)),
    );
    const priceBandCode = seatBundle.price_band_code;
    const ticketTypeCode = seatBundle.ticket_type_code;

    const seatId = event.seatData.seat_id;
    const pbTokenMap = pricebandMap[ticketTypeCode];
    const ticketToken = pbTokenMap[priceBandCode];
    const seatData = {
      presetName: seatId,
      priceBandCode: priceBandCode,
      seatId: seatId,
      ticketToken: ticketToken,
    };
    this.seatIdToTickenTokenMap.set(seatuuid, seatData);

    config.handleSelectSeat(seatData);
  }

  private handleIngressoSeatUnselect(event: any, config: SeatMapConfig) {
    const seatuuid = event.seatData.uuid;

    const seatData = this.seatIdToTickenTokenMap.get(seatuuid);
    config.handleUnselectSeat(seatData!);
  }

  private handleNuticketMessages(event: MessageEvent, config: SeatMapConfig, pricebandMap: any) {
    if (event.data.seatSelected) {
      const seatData = {
        presetName: event.data.seatSelected.presetName,
        priceBandCode: event.data.seatSelected.space.price_band_id.toString(),
        seatId: event.data.seatSelected.space.id.toString(),
        ticketToken: 'TOKEN',
      };
      seatData.ticketToken = pricebandMap[seatData.priceBandCode];

      config.handleSelectSeat(seatData);
    }
    if (event.data.seatUnselected) {
      const seatData = {
        presetName: event.data.seatUnselected.presetName,
        priceBandCode: event.data.seatUnselected.space.price_band_id.toString(),
        seatId: event.data.seatUnselected.space.id.toString(),
        ticketToken: 'TOKEN',
      };
      seatData.ticketToken = pricebandMap[seatData.priceBandCode];

      config.handleUnselectSeat(seatData);
    }
  }
}

export default SeatingMap;
