import { BookingStatus } from '@BookingPlatform/grpc/v1/Shared/BookingStatus_pb';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import './BookingComponentStatusChip.scss';

const BookingComponentStatusChip: FC<{ status: BookingStatus }> = ({ status }) => {
  const className =
    status === BookingStatus.CANCELLED ? 'booking-status-cancelled' : 'booking-status-confirmed';

  const { t } = useTranslation();

  const mapStatus = () => {
    switch (status) {
      case BookingStatus.CONFIRMED:
        return t('reservation.statusInput.confirmed');
      case BookingStatus.FAILED:
        return t('reservation.statusInput.failed');
      case BookingStatus.INPROGRESS:
        return t('reservation.statusInput.inprogress');
      case BookingStatus.PENDING:
        return t('reservation.statusInput.pending');
      case BookingStatus.REJECTED:
        return t('reservation.statusInput.rejected');
      case BookingStatus.CANCELLED:
        return t('reservation.statusInput.cancelled');
    }
  };

  return <div className={`${className} booking-status-chip`}>{mapStatus().toUpperCase()}</div>;
};

export default BookingComponentStatusChip;
