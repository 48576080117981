import { Box } from '@mui/material';
import { IResultItemListProps } from 'pages/things-to-do/results/Results.interface';
import ResultsItem from 'pages/things-to-do/results/results-item/ResultsItem';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import 'shared/components/autocomplete-result-item/ResultsItemList.scss';
import ErrorBox from 'shared/components/error-box/ErrorBox';

const ResultsItemList: FC<IResultItemListProps> = ({
  listViewType,
  items,
  filterLoading,
  showError,
}) => {
  const { t } = useTranslation();

  return (
    <div className={`results-item-list ${listViewType}`}>
      {showError && (
        <ErrorBox
          title={t('common.search.noResults')}
          description={t('common.search.noResultsSubtitle')}
        />
      )}

      {filterLoading ? (
        <div className="results-loader">
          <div className="list">
            <div className="list-item loader-anim"></div>
            <div className="list-item loader-anim"></div>
            <div className="list-item loader-anim"></div>
          </div>
        </div>
      ) : (
        <>
          {listViewType !== 'normal' && (
            <Box className="header-container" sx={{ marginBottom: '20px' }}>
              <div className="name-header">
                {t('productDetails.thingsToDo.xResults.header.name')}
              </div>
              <div className="min-price-header">
                {t('productDetails.thingsToDo.xResults.header.minPrice')}
              </div>
            </Box>
          )}

          {items
            .filter((item) => item.productname)
            .map((item, index) => (
              <ResultsItem key={index} result={item} viewType={listViewType} />
            ))}
        </>
      )}
    </div>
  );
};

export default ResultsItemList;
