import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import requestStoreService from 'shared/store/request.reducer';

const useCanDispatch = () => {
  const { brandId, salesChannelId } = useSelector(requestStoreService.selector('userSettings'));

  const canDispatch = useMemo(
    () => brandId !== -1 && salesChannelId !== -1,
    [brandId, salesChannelId],
  );

  return canDispatch;
};

export default useCanDispatch;
