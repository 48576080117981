import FmdGoodIcon from '@mui/icons-material/FmdGood';
import PersonIcon from '@mui/icons-material/Person';
import StarIcon from '@mui/icons-material/Star';
import WineBarIcon from '@mui/icons-material/WineBar';
import { Box } from '@mui/material';
import { DiningSearchFieldValue } from 'pages/dining/Dining.interface';
import { EntertainmentSearchFieldValue } from 'pages/entertainment/Entertainment.interface';
import { AttractionSearchFieldValue } from 'pages/things-to-do/ThingsToDo.interface';
import { FC } from 'react';
import 'shared/components/autocomplete-result-item/ResultsItemList.scss';

const mapTypesToValues = {
  attraction0: <StarIcon />, // attraction
  attraction1: <FmdGoodIcon />, // attraction location
  dining0: <FmdGoodIcon />, // dining location
  dining1: <WineBarIcon />, // dining venue
  dining2: <FmdGoodIcon />, // dining attribute
  entertainment0: <StarIcon />, // entertainment event
  entertainment1: <FmdGoodIcon />, // entertainment location
  entertainment2: <FmdGoodIcon />, // entertainment venue
  entertainment3: <PersonIcon />, // entertainment performer
};

export type MapTypeKeys = keyof typeof mapTypesToValues;

type AutocompleteProps<T> = {
  item: T;
  type?: MapTypeKeys;
  selectListItem: (item: T) => void;
  additionalInformation?: string | undefined;
};

const AdditionalInformation: FC<{ additionalInformation: string }> = ({
  additionalInformation,
}) => <span className="additional-information">{additionalInformation}</span>;

const AutocompleteResultItem: FC<AutocompleteProps<any>> = ({
  item,
  type,
  selectListItem,
  additionalInformation,
}) => {
  return (
    <div
      tabIndex={0}
      className="list-item"
      onFocus={(e) => {
        e.target.classList.add('selected');
      }}
      onBlur={(e) => {
        e.target.classList.remove('selected');
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          e.preventDefault();
          selectListItem(item);
        }
      }}
      onClick={() => {
        selectListItem(item);
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '3px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: '10px',
            minHeight: '20px',
          }}
        >
          {type && mapTypesToValues[type]}
          <span>{item.name}</span>
        </Box>
        {additionalInformation && (
          <Box
            sx={{
              display: 'flex',
              minHeight: '20px',
              marginLeft: '30px',
            }}
          >
            <AdditionalInformation additionalInformation={additionalInformation} />
          </Box>
        )}
      </Box>
    </div>
  );
};

export const DiningAutocompleteResultItem: FC<AutocompleteProps<DiningSearchFieldValue>> = ({
  item,
  type,
  selectListItem,
}) => (
  <AutocompleteResultItem
    item={item}
    type={type}
    selectListItem={selectListItem}
    additionalInformation={item.additionaldata?.value}
  />
);

export const AttractionAutocompleteResultItem: FC<
  AutocompleteProps<AttractionSearchFieldValue>
> = ({ item, type, selectListItem }) => (
  <AutocompleteResultItem item={item} type={type} selectListItem={selectListItem} />
);

export const EntertainmentAutocompleteResultItem: FC<
  AutocompleteProps<EntertainmentSearchFieldValue>
> = ({ item, type, selectListItem }) => (
  <AutocompleteResultItem
    item={item}
    type={type}
    selectListItem={selectListItem}
    additionalInformation={item.additionaldata?.value}
  />
);
