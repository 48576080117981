import { EntertainmentPromiseClient } from '@BookingPlatform/grpc/v1/Entertainment/Entertainment_grpc_web_pb';
import {
  SearchRequest as EntertainmentSearchRequest,
  EventDetailsRequest,
  PerformanceResult,
  SearchCategory,
  SearchPaginationRequest,
  SearchResponse,
} from '@BookingPlatform/grpc/v1/Entertainment/Entertainment_pb';
import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { silentTokenRequest } from 'authConfig';
import { Int32Value, StringValue } from 'google-protobuf/google/protobuf/wrappers_pb';
import { toast } from 'react-toastify';
import utils from 'shared/services/utilities.service';
import { RequestState, RequestStatus } from 'shared/store/request.reducer';
import { apiUrl } from 'shared/store/request.thunk';
import {
  EntertainmentSearchFieldValue,
  EntertainmentSearchFormTypes,
} from '../Entertainment.interface';

type EntertainmentSearchFormState = {
  location: EntertainmentSearchFieldValue | null;
  date: {
    startDate: string | null;
    endDate: string | null;
  };
  product: EntertainmentSearchFieldValue | undefined;
  selectedProduct: EntertainmentSearchFieldValue | undefined;
  selectedCategories: Set<SearchCategory>;
};

type ExtendedEntertainmentSearchState = EntertainmentSearchFormState &
  RequestState<SearchResponse.AsObject> & {
    pageLoaded: number;
  };

const initialState: ExtendedEntertainmentSearchState = {
  location: null,
  date: {
    startDate: null,
    endDate: null,
  },
  product: undefined,
  selectedProduct: undefined,
  value: undefined,
  error: undefined,
  status: RequestStatus.Null,
  pageLoaded: 0,
  selectedCategories: new Set<SearchCategory>(),
};

export const getEntertainmentSearchResult = createAsyncThunk<
  SearchResponse.AsObject | null,
  {
    instance: IPublicClientApplication;
    account: AccountInfo;
    brandId: number;
    salesChannelId: number;
    formValues: EntertainmentSearchFormTypes;
    categoriesList?: Set<SearchCategory>;
  }
>(
  'getEntertainmentSearchResult',
  async ({
    instance,
    account,
    brandId,
    salesChannelId,
    formValues,
    categoriesList = new Set<SearchCategory>(),
  }): Promise<SearchResponse.AsObject | null> => {
    const request = new EntertainmentSearchRequest();

    request.setLocationid(formValues.location.id);
    request.setSaleschannelid(salesChannelId);
    request.setBrandid(brandId);
    request.setTenantid(account.tenantId);
    request.setLanguagecode(new StringValue().setValue(utils.getCurrentLanguage()));
    if (formValues.from) {
      request.setStartdate(new StringValue().setValue(formValues.from));
    }
    if (formValues.to) {
      request.setEnddate(new StringValue().setValue(formValues.to));
    }

    request.setSearchcategoriesList(Array.from(categoriesList));
    request.setPagesize(10);

    if (formValues.event) {
      request.setEventid(new Int32Value().setValue(formValues.event.id));
    } else if (formValues.performer) {
      request.setPerformerid(new Int32Value().setValue(formValues.performer.id));
    } else if (formValues.venue) {
      request.setVenueid(new Int32Value().setValue(formValues.venue.id));
    }

    let token;
    try {
      const { accessToken } = await instance.acquireTokenSilent(silentTokenRequest(account));
      token = accessToken;
    } catch (e) {
      instance.loginRedirect();
    }

    return (
      await new EntertainmentPromiseClient(apiUrl).search(request, {
        Authorization: `Bearer ${token}`,
      })
    ).toObject();
  },
);

export type GetEventDetailsArgs = EventDetailsRequest.AsObject & {
  instance: IPublicClientApplication;
  account: AccountInfo;
};

export const getNextEntertainmentPage = createAsyncThunk<
  PerformanceResult.AsObject[] | null,
  {
    instance: IPublicClientApplication;
    account: AccountInfo;
    brandId: number;
    salesChannelId: number;
    searchToken: string;
    pageIndex: number;
  }
>(
  'getNextEntertainmentPage',
  async ({
    instance,
    account,
    brandId,
    salesChannelId,
    searchToken,
    pageIndex,
  }): Promise<PerformanceResult.AsObject[] | null> => {
    const request = new SearchPaginationRequest();

    request.setTenantid(account.tenantId);
    request.setBrandid(brandId);
    request.setSaleschannelid(salesChannelId);
    request.setSearchtoken(searchToken);
    request.setPageindex(pageIndex);

    let token;
    try {
      const { accessToken } = await instance.acquireTokenSilent(silentTokenRequest(account));
      token = accessToken;
    } catch (e) {
      instance.loginRedirect();
    }

    return (
      await new EntertainmentPromiseClient(apiUrl).searchPagination(request, {
        Authorization: `Bearer ${token}`,
      })
    ).toObject().resultsList;
  },
);

export const searchSlice = createSlice({
  name: 'entertainment-search-form',
  initialState,
  reducers: {
    setSearch: (state, action: PayloadAction<Partial<EntertainmentSearchFormState>>) => {
      state.location = action.payload.location ?? null;
      state.date.startDate = action.payload.date?.startDate ?? null;
      state.date.endDate = action.payload.date?.endDate ?? null;
      state.product = action.payload.product;
    },
    setSelectedProduct: (
      state,
      action: PayloadAction<EntertainmentSearchFieldValue | undefined>,
    ) => {
      state.selectedProduct = action.payload;
    },
    setSelectedCategories: (state, action: PayloadAction<Set<SearchCategory>>) => {
      state.selectedCategories = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // submitting ent search form or refreshing page
      .addCase(getEntertainmentSearchResult.pending, (state: ExtendedEntertainmentSearchState) => {
        state.status = RequestStatus.Loading;
        state.value = undefined;
        state.error = undefined;
      })
      .addCase(
        getEntertainmentSearchResult.fulfilled,
        (state: ExtendedEntertainmentSearchState, action) => {
          state.status = RequestStatus.Idle;
          state.value = action.payload || undefined;
          state.pageLoaded = 1;
        },
      )
      .addCase(
        getEntertainmentSearchResult.rejected,
        (state: ExtendedEntertainmentSearchState, action) => {
          state.status = RequestStatus.Failed;
          state.value = undefined;
          state.error = action.error;
          state.pageLoaded = 0;
          if (action.error) {
            toast.error(action.error.message || 'Unknown error occurred!');
            toast.clearWaitingQueue();
          }
        },
      )
      // for infinite scroll
      .addCase(getNextEntertainmentPage.pending, (state: ExtendedEntertainmentSearchState) => {
        state.status = RequestStatus.Loading;
        state.error = undefined;
      })
      .addCase(
        getNextEntertainmentPage.fulfilled,
        (state: ExtendedEntertainmentSearchState, action) => {
          state.status = RequestStatus.Idle;
          if (state.value && action.payload) {
            state.value.resultsList = [...(state.value.resultsList || []), ...action.payload];
            state.pageLoaded = state.pageLoaded + 1;
          }
        },
      )
      .addCase(
        getNextEntertainmentPage.rejected,
        (state: ExtendedEntertainmentSearchState, action) => {
          state.status = RequestStatus.Failed;
          state.value = undefined;
          state.error = action.error;
          if (action.error) {
            toast.error(action.error.message || 'Unknown error occurred!');
            toast.clearWaitingQueue();
          }
        },
      );
  },
});

export const { setSearch, setSelectedProduct, setSelectedCategories } = searchSlice.actions;
export default searchSlice.reducer;
