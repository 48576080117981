import { CardElementProps } from '@adyen/adyen-web/dist/types/components/Card/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Currency } from 'shared/interfaces/config.interface';

export interface Amount {
  value: number;
  currency: Currency;
}

export interface PaymentSlice {
  error: string;
  session: null | { id: string; sessionData: string };
  orderRef: null | string;
  paymentServiceAvailable: boolean;
  paymentDataStoreRes: null;
  config: {
    storePaymentMethod: boolean;
    showPaymentMethods: boolean;
    paymentMethodsConfiguration: {
      ideal: {
        showImage: boolean;
      };
      amex: CardElementProps;
    };
    locale: string;
    showPayButton: boolean;
    clientKey?: string;
    environment?: string;
  };
}

export const initialPaymentState: PaymentSlice = {
  error: '',
  session: null,
  orderRef: null,
  paymentDataStoreRes: null,
  paymentServiceAvailable: true,
  config: {
    showPaymentMethods: false,
    storePaymentMethod: true,
    paymentMethodsConfiguration: {
      ideal: {
        showImage: true,
      },
      amex: {
        useClickToPay: false,
        hasHolderName: true,
        holderNameRequired: true,
        name: 'American Express',
      },
    },
    locale: 'en_US',
    showPayButton: true,
  },
};

export const slice = createSlice({
  name: 'payment',
  initialState: initialPaymentState,
  reducers: {
    createPaymentSession: (state, action) => {
      state.session = {
        id: action.payload.id,
        sessionData: action.payload.sessionData,
      };

      state.config.clientKey = action.payload.clientKey;

      if (action.payload.clientKey.startsWith('test')) {
        state.config.environment = 'test';
      } else {
        state.config.environment = 'live';
      }
    },
    clearPaymentSession: (state) => {
      state.error = '';
      state.session = null;
      state.orderRef = null;
    },
    paymentNotAvailable: (state) => {
      state.paymentServiceAvailable = false;
      state.error = '';
      state.session = null;
      state.orderRef = null;
    },
    setPayableAmount: (state, action: PayloadAction<Amount>) => {
      state.config.paymentMethodsConfiguration.amex.amount = action.payload;
    },
  },
});

export const { createPaymentSession, clearPaymentSession, paymentNotAvailable, setPayableAmount } =
  slice.actions;

export default slice.reducer;
