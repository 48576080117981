import { StringValue } from 'google-protobuf/google/protobuf/wrappers_pb';
import { IAnswer } from 'pages/checkout/contact-info/ContactInfo.interface';

// this function serves as a type guard, telling TypeScript that if the function returns true, then value is of type IAnswer
// if return type is boolean, it only provides a boolean result indicating the existence of the property in the provided value object. Could cause type issues
export function isIAnswer(value: any): value is IAnswer {
  return 'answer' in value;
}

export function isStringValue(value: any): value is StringValue {
  return typeof value === 'object' && 'value' in value;
}
