import 'dayjs/locale/da';
import 'dayjs/locale/de';
import 'dayjs/locale/en';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/es-us';
import 'dayjs/locale/fr';
import 'dayjs/locale/it';
import 'dayjs/locale/ja';
import 'dayjs/locale/ko';
import 'dayjs/locale/nb';
import 'dayjs/locale/nl';
import 'dayjs/locale/pt';
import 'dayjs/locale/sv';
import 'dayjs/locale/zh-cn';

import {
  da as daDateFns,
  de as deDateFns,
  enGB as enGBDateFns,
  enUS as enUSDateFns,
  es as esDateFns,
  fr as frDateFns,
  it as itDateFns,
  ja as jaDateFns,
  ko as koDateFns,
  nb as nbDateFns,
  nl as nlDateFns,
  pt as ptDateFns,
  sv as svDateFns,
  zhCN as zhCNDateFns,
} from 'date-fns/locale';

export function getLocaleCode(languageCode: string): string {
  switch (languageCode) {
    case 'en-US': {
      return 'en-US';
    }
    case 'en-GB': {
      return 'en-GB';
    }
    case 'es-ES': {
      return 'es';
    }
    case 'ja-JP': {
      return 'ja';
    }
    case 'pt-BR': {
      return 'pt';
    }
    case 'zh-CN': {
      return 'zh-cn';
    }
    case 'da-DK': {
      return 'da';
    }
    case 'de-DE': {
      return 'de';
    }
    case 'fr-FR': {
      return 'fr';
    }
    case 'ko-KR': {
      return 'ko';
    }
    case 'nl-NL': {
      return 'nl';
    }
    case 'no-NO': {
      return 'nb';
    }
    case 'sv-SE': {
      return 'sv';
    }
    case 'it-IT': {
      return 'it';
    }
    default: {
      return 'en-US';
    }
  }
}

export function getDateFnsLocalizationProvider(languageCode: string) {
  switch (languageCode) {
    case 'en-US': {
      return enUSDateFns;
    }
    case 'en-GB': {
      return enGBDateFns;
    }
    case 'es-ES': {
      return esDateFns;
    }
    case 'ja-JP': {
      return jaDateFns;
    }
    case 'pt-BR': {
      return ptDateFns;
    }
    case 'zh-CN': {
      return zhCNDateFns;
    }
    case 'da-DK': {
      return daDateFns;
    }
    case 'de-DE': {
      return deDateFns;
    }
    case 'fr-FR': {
      return frDateFns;
    }
    case 'ko-KR': {
      return koDateFns;
    }
    case 'nl-NL': {
      return nlDateFns;
    }
    case 'no-NO': {
      return nbDateFns;
    }
    case 'sv-SE': {
      return svDateFns;
    }
    case 'it-IT': {
      return itDateFns;
    }
    default: {
      return enUSDateFns;
    }
  }
}
