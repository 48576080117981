export type Currency =
  | 'EUR'
  | 'GBP'
  | 'USD'
  | 'AED'
  | 'ARS'
  | 'AUD'
  | 'BRL'
  | 'CAD'
  | 'CHF'
  | 'CLP'
  | 'CNY'
  | 'COP'
  | 'CRC'
  | 'DKK'
  | 'DOP'
  | 'EGP'
  | 'GTQ'
  | 'HKD'
  | 'HNL'
  | 'IDR'
  | 'INR'
  | 'ISK'
  | 'JPY'
  | 'KES'
  | 'KRW'
  | 'KWD'
  | 'MXN'
  | 'MYR'
  | 'NIO'
  | 'NOK'
  | 'NZD'
  | 'PAB'
  | 'PEN'
  | 'PHP'
  | 'QAR'
  | 'RUB'
  | 'SAR'
  | 'SEK'
  | 'SGD'
  | 'THB'
  | 'TWD'
  | 'VND'
  | 'ZAR';

export interface IConfig {
  defaultCurrency: Currency;
  defaultLanguage: string;
  defaultTimezone: string;
  languageList: IconedListItem<string>[];
  currencyList: IconedListItem<Currency>[];
}

export interface AsyncThunkOptions {
  extra: { config: Promise<IConfig> };
}

export interface IconedListItem<T> {
  value: T;
  label: string;
  iconString?: string;
  iconUrl?: string;
}

export enum PageRoute {
  ThingsToDo = '/things-to-do',
  Entertainment = '/entertainment',
  Dining = '/dining',
  SearchResults = '/search-results',
  ProductDetails = '/product-details',
  DiningDetails = '/dining-details',
  EventDetails = '/event-details',
  Checkout = '/checkout',
  BookingDetails = '/booking-details',
  Account = '/account',
  PaymentResult = '/payment/result',
  BookingCancellation = '/booking-cancellation',
  BookingCancellationConfirmation = '/booking-cancellation-confirmation',
  Home = '/',
}
