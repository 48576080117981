import { AwsRum, AwsRumConfig } from 'aws-rum-web';
import React, { createContext, useEffect, useState } from 'react';

export const RumContext = createContext({} as AwsRum);

const RumProvider = ({ children }: { children: React.ReactNode }) => {
  const [awsRum, setAwsRum] = useState<AwsRum | null>(null);

  useEffect(() => {
    const isProduction = process.env.REACT_APP_ENV === 'production';
    if (!awsRum && isProduction) {
      try {
        const config: AwsRumConfig = {
          sessionSampleRate: 1,
          guestRoleArn: process.env.REACT_APP_AWS_RUM_ROLE_ARN,
          identityPoolId: process.env.REACT_APP_AWS_RUM_POOL_ID,
          endpoint: 'https://dataplane.rum.eu-west-1.amazonaws.com',
          telemetries: ['performance', 'errors', 'http'],
          allowCookies: true,
          enableXRay: true,
          pagesToInclude: [/\.*/],
          // below should be true, if we manually call recordPageView in PageMetrics
          // https://github.com/aws-observability/aws-rum-web/blob/main/docs/cdn_commands.md#commands
          disableAutoPageView: true,
          sessionEventLimit: 1000,
        };

        const APPLICATION_ID: string = process.env.REACT_APP_AWS_RUM_APP_ID ?? '';
        const APPLICATION_VERSION = '1.0.0';
        const APPLICATION_REGION = 'eu-west-1';

        const awsRum = new AwsRum(APPLICATION_ID, APPLICATION_VERSION, APPLICATION_REGION, config);
        setAwsRum(awsRum);
      } catch (error) {
        // Ignore errors thrown during CloudWatch RUM web client initialization
      }
    }
  }, [awsRum]);

  return awsRum ? (
    <RumContext.Provider value={awsRum}>{children}</RumContext.Provider>
  ) : (
    <>{children}</>
  );
};

export default RumProvider;
