import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BookingComponentStatusChip from 'shared/components/booking-component-status-chip/BookingComponentStatusChip';
import { PageRoute } from 'shared/interfaces/config.interface';
import { RootState } from 'shared/store/root.store';
import useGetGuestTypeName from 'utils/getTravellerType';
import '../../style/themes/platform.scss';
import './BookingConfirmation.scss';

const BookingConfirmation: FC<{ eventName: string; children?: React.ReactNode }> = ({
  eventName,
  children,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const primaryDarkColor = 'var(--primary-dark)';

  const { bookingSession, bookingDetails } = useSelector(
    (state: RootState) => state.bookingSession,
  );

  const getTravelYear = () => {
    if (bookingDetails?.value?.bookingcomponentdetailsList[0]) {
      const startDate = new Date(bookingDetails.value.bookingcomponentdetailsList[0].startdate);

      return startDate.getFullYear();
    }
  };

  const getTravelMonth = () => {
    if (bookingDetails?.value?.bookingcomponentdetailsList[0]) {
      const startDate = new Date(bookingDetails.value.bookingcomponentdetailsList[0].startdate);
      const month = startDate.toLocaleString('default', { month: 'short' });

      return month;
    }
  };

  const getTravelDay = () => {
    if (bookingDetails?.value?.bookingcomponentdetailsList[0]) {
      const startDate = new Date(bookingDetails.value.bookingcomponentdetailsList[0].startdate);

      return startDate.getDate();
    }
  };

  const { getGuestTypeName } = useGetGuestTypeName();

  const navigateToBookingDetails = () => {
    navigate(`${PageRoute.BookingDetails}/${bookingSession?.value?.bookingreference?.value}`);
  };

  return (
    <>
      {bookingDetails?.value && (
        <div className="booking-confirmation">
          <Typography
            variant="button"
            display="block"
            onClick={navigateToBookingDetails}
            className="title"
            sx={{
              fontSize: '1.4em',
              textTransform: 'uppercase',
              marginBottom: '1.4em',
              fontStyle: 'italic',
              cursor: 'pointer',
              color: primaryDarkColor,
            }}
          >
            {t('bookingConfirmationPage.title')}!
          </Typography>
          <div className="container">
            <div className="date-container">
              <div className="date">
                <div className="month">{getTravelMonth()}</div>
                <div className="day">{getTravelDay()}</div>
                <div className="year">{getTravelYear()}</div>
              </div>
            </div>
            <div className="main-container">
              <div className="attraction-name">{eventName}</div>
              {bookingDetails.value.bookingcomponentdetailsList[0].productoptionsList.length >
                0 && (
                <div className="group">
                  {
                    bookingDetails.value.bookingcomponentdetailsList[0].productoptionsList[0]
                      .optionname?.value
                  }
                </div>
              )}
              <div className="total">{`${t('bookingConfirmationPage.total')}: ${bookingDetails.value
                .bookingcomponentdetailsList[0].totalprice?.value} ${
                bookingDetails.value.currencycode
              }`}</div>
              {bookingDetails.value.bookingcomponentdetailsList[0].productoptionsList.length > 0 &&
                bookingDetails.value.bookingcomponentdetailsList[0].productoptionsList[0].guestconfigurationsList.map(
                  (guestConfig) => (
                    <div key={guestConfig.guesttypenumber} className="breakdown">{`(${
                      guestConfig.guesttypenumber
                    } x ${getGuestTypeName(guestConfig.guesttype)})`}</div>
                  ),
                )}
            </div>
            <div className="booking-reference-container">
              <BookingComponentStatusChip
                status={bookingDetails?.value.bookingcomponentdetailsList[0].componentstatus}
              />
              <Box sx={{ '>p': { margin: '.5em 0' } }}>
                <Typography>{t('bookingConfirmationPage.bookingReference')}: </Typography>
                <Typography sx={{ fontWeight: 'bold' }}>
                  {bookingSession?.value?.bookingreference?.value}
                </Typography>
              </Box>
              {children && <>{children}</>}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BookingConfirmation;
