import { useMsal } from '@azure/msal-react';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { DateTime } from 'luxon';
import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { DateRange, Range } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux/es/hooks/useDispatch';
import { useParams } from 'react-router-dom';
import useUserLocale from 'shared/hooks/useUserLocale';
import requestStoreService from 'shared/store/request.reducer';
import { getAvailabilityRangesResults } from 'shared/store/request.thunk';
import { AppDispatch, RootState } from 'shared/store/root.store';
import { getLocaleFormattedDateFromDate } from 'utils/dateConverter';
import { getDateFnsLocalizationProvider } from 'utils/localizationResolver';
import './DateRangeSelector.scss';

interface IDateRangeSelector {
  id: string;
  label: string;
  canClear?: boolean;
  size?: 'small' | 'medium';
  defaultValue?: Partial<Range>;
  minDate?: Date;
  maxDate?: Date;
  rangeLimit?: number;
  onChange: (data: ChangeEvent<any>) => void;
  hasRanges?: boolean;
  isDisabled?: boolean;
}

const getDefaultRange = (defaultValue: Partial<Range> | undefined) => ({
  startDate: defaultValue?.startDate ? defaultValue.startDate : new Date(),
  endDate: defaultValue?.endDate ? defaultValue.endDate : new Date(),
  key: 'selection',
});

const DateRangeSelector: FC<IDateRangeSelector> = ({
  id,
  label,
  canClear = false,
  defaultValue,
  size = 'medium',
  minDate = new Date(2022, 5, 1),
  maxDate,
  rangeLimit,
  onChange,
  hasRanges = false,
  isDisabled = false,
}) => {
  const { t } = useTranslation();
  const userLocale = useUserLocale();

  const [dateRangeInput, setDateRangeInput] = useState<Partial<Range>>(
    getDefaultRange(defaultValue),
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const dispatch = useDispatch<AppDispatch>();
  const { accounts, instance } = useMsal();
  const { language, brandId, salesChannelId } = useSelector(
    (state: RootState) => state.userSettings,
  );
  const { productId } = useParams();
  const availabilityRanges = useSelector(requestStoreService.selector('availabilityRanges'));
  dayjs.extend(isBetween);
  const disabledGreyColor = 'var(--grey-dark)';

  useEffect(() => {
    if (salesChannelId !== -1 && productId) {
      dispatch(
        getAvailabilityRangesResults({
          account: accounts[0],
          instance,
          productId: Number(productId),
          brandId,
          salesChannelId,
        }),
      );
    }
  }, [productId, salesChannelId, accounts, brandId, dispatch, instance]);

  const checkDisabled = (date: Date) => {
    if (!hasRanges) {
      return false;
    }
    let isEnabled = false;
    if (availabilityRanges?.value?.availablerangesList?.length) {
      availabilityRanges.value.availablerangesList.forEach((range) => {
        if (range.enddate?.value) {
          const result = dayjs(date).isBetween(
            dayjs(range.startdate),
            dayjs(range.enddate?.value),
            'day',
            '[]',
          );
          if (result) {
            isEnabled = true;
          }
        }
      });
    }
    return !isEnabled;
  };

  const [dateRange, setDateRange] = useState<Partial<Range>>({
    startDate: defaultValue?.startDate,
    endDate: defaultValue?.endDate,
  });

  const [open, setOpen] = useState(false);
  const [focused, setFocused] = useState(false);
  const [hasWarning, setHasWarning] = useState(false);

  const clearFields = () => {
    if (canClear) {
      setDateRange({ startDate: undefined, endDate: undefined });
      setDateRangeInput(getDefaultRange(defaultValue));

      onChange({
        target: { value: { startDate: undefined, endDate: undefined }, name: id },
      } as any as ChangeEvent<Range>);
    }
  };

  const setActiveNavigation = useCallback(
    (date: Date = minDate) => {
      const nextButton = document.getElementsByClassName('rdrNextButton')[0];
      const prevButton = document.getElementsByClassName('rdrPprevButton')[0];

      const maxCheck =
        maxDate?.getMonth() === date.getMonth() && maxDate?.getFullYear() === date.getFullYear();
      const minCheck =
        minDate?.getMonth() === date.getMonth() && minDate?.getFullYear() === date.getFullYear();

      maxCheck ? nextButton.classList.add('disabled') : nextButton.classList.remove('disabled');
      minCheck ? prevButton.classList.add('disabled') : prevButton.classList.remove('disabled');
    },
    [minDate, maxDate],
  );

  const setFieldValue = (date: Date | undefined) => {
    return date ? getLocaleFormattedDateFromDate(date, userLocale) : '';
  };

  const inputOnClick = () => {
    if (!isDisabled) {
      setOpen(true);
    }

    if (open && !isDisabled) {
      setActiveNavigation(dateRangeInput?.startDate);
    }
  };

  useEffect(() => {
    if (open && !isDisabled) {
      setActiveNavigation(dateRangeInput?.startDate);
    }
  }, [open, isDisabled, setActiveNavigation, dateRangeInput?.startDate]);

  useEffect(() => {
    if (defaultValue) {
      setDateRange(getDefaultRange(defaultValue));
      setDateRangeInput(getDefaultRange(defaultValue));
    }
  }, [defaultValue]);

  const onDateRangeChange = (range: Range) => {
    setDateRange(range);
    setDateRangeInput(range);
    setHasWarning(false);

    let diffInDays = 0;
    let endDate = range.endDate ? new Date(range.endDate) : undefined;

    if (range.startDate && range.endDate) {
      const diff = DateTime.fromJSDate(range.endDate)
        .diff(DateTime.fromJSDate(range.startDate), 'days')
        .toObject().days;

      diffInDays = diff ? diff + 1 : 0;
    }

    if (rangeLimit && diffInDays > rangeLimit) {
      setHasWarning(true);

      if (range.endDate) {
        endDate = DateTime.fromJSDate(range.endDate)
          .plus({ days: rangeLimit - diffInDays })
          .toJSDate();
      }
    }

    setDateRange({ ...range, startDate: range.startDate, endDate: endDate });
    setDateRangeInput({ ...range, startDate: range.startDate, endDate: endDate });

    onChange({
      target: { value: { startDate: range.startDate, endDate: endDate }, name: id },
    } as any as ChangeEvent<Range>);
  };

  return (
    <ClickAwayListener
      onClickAway={() => {
        setHasWarning(false);
        setOpen(false);
      }}
    >
      <Box
        className="date-range-selector"
        sx={{
          input: {
            color: isDisabled ? disabledGreyColor : 'initial',
          },
        }}
      >
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={() => {
            setHasWarning(false);
            setOpen(false);
          }}
          open={open && !isDisabled}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={
            <>
              <DateRange
                editableDateInputs={true}
                disabledDay={checkDisabled}
                onShownDateChange={setActiveNavigation}
                onChange={(item) => {
                  onDateRangeChange(item.selection);
                }}
                moveRangeOnFirstSelection={false}
                showMonthAndYearPickers={false}
                preventSnapRefocus={true}
                ranges={[dateRangeInput]}
                minDate={minDate}
                maxDate={maxDate}
                direction={'horizontal'}
                months={2}
                locale={getDateFnsLocalizationProvider(language)}
              />

              {hasWarning && (
                <div className="calendar-warning">
                  <WarningAmberIcon />
                  <Trans
                    i18nKey="dateRange.rangeLimitWarning"
                    values={{ 0: rangeLimit }}
                    components={{ 1: <b /> }}
                  />
                </div>
              )}
              <div className="calendar-footer">
                {canClear && (
                  <Button
                    className="clear-button"
                    color="error"
                    variant="outlined"
                    size="small"
                    onClick={() => {
                      clearFields();
                      setHasWarning(false);
                      setOpen(false);
                    }}
                  >
                    {t('generic.clear')}
                  </Button>
                )}

                <Button
                  className="done-button"
                  color="primary"
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    setHasWarning(false);
                    setOpen(false);
                  }}
                >
                  {t('generic.done')}
                </Button>
              </div>
            </>
          }
        >
          <span className="input-wrap">
            <TextField
              id={'from-' + id}
              label={label}
              className="range-selector-from"
              variant="outlined"
              autoComplete="off"
              focused={focused}
              placeholder={t('generic.from')}
              size={size}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              onClick={inputOnClick}
              onKeyDown={(event) => event.key === 'Backspace' && clearFields()}
              value={setFieldValue(dateRange.startDate)}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                readOnly: true,
                endAdornment: <InputAdornment position="end">-</InputAdornment>,
              }}
            />
            <TextField
              id={'to-' + id}
              className="range-selector-to"
              variant="outlined"
              InputLabelProps={{ focused: true }}
              autoComplete="off"
              placeholder={t('generic.to')}
              size={size}
              focused={focused}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              onClick={inputOnClick}
              onKeyDown={(event) => event.key === 'Backspace' && clearFields()}
              value={setFieldValue(dateRange.endDate)}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarMonthIcon />
                  </InputAdornment>
                ),
              }}
            />
          </span>
        </Tooltip>
      </Box>
    </ClickAwayListener>
  );
};

export default DateRangeSelector;
