import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import App from 'App';
import { msalConfig } from 'authConfig';
import Loader from 'pages/things-to-do/results/Loader';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import LoginInterceptor from 'shared/components/login-interceptor/LoginInterceptor';
import HighSecurityProvider from 'shared/contexts/HighSecurityContext';
import { ConfigProvider } from 'shared/contexts/config.context';
import RumProvider from 'shared/contexts/rum.context';
import StyleProvider from 'shared/contexts/theme.context';
import i18nConfig from 'shared/services/language.service';
import { persistor, store } from 'shared/store/root.store';
import './style/style.scss';

const APP_SITE = process.env.REACT_APP_SITE || 'platfrom';
import(`./style/themes/${APP_SITE}.scss`);

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

export const instance = new PublicClientApplication(msalConfig);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={<Loader />} persistor={persistor}>
        <HighSecurityProvider>
          <ConfigProvider>
            <I18nextProvider i18n={i18nConfig}>
              <RumProvider>
                <MsalProvider instance={instance}>
                  <LoginInterceptor>
                    <StyleProvider>
                      <App />
                    </StyleProvider>
                  </LoginInterceptor>
                </MsalProvider>
              </RumProvider>
            </I18nextProvider>
          </ConfigProvider>
        </HighSecurityProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
