import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { FC, useEffect, useRef, useState } from 'react';
import utils from 'shared/services/utilities.service';
import './Slider.scss';

interface SliderProps<T> {
  items: T[];
  renderItem: (item: T, active: boolean, onClick: () => void) => JSX.Element;
  selectedItemIds: Set<number>;
  onItemClick: (item: T) => void;
}

const Slider: FC<SliderProps<any>> = ({ items, renderItem, selectedItemIds, onItemClick }) => {
  const sliderList = useRef<HTMLDivElement>(null);

  const [sliderWidth, setSliderWidth] = useState(0);
  const [sliderScrollWidth, setSliderScrollWidth] = useState(0);
  const [sliderScrollPosition, setSliderScrollPosition] = useState(0);

  const scrollLeft = () => {
    if (!sliderList.current) return;
    sliderList.current.scrollLeft -= sliderList.current.offsetWidth;
  };
  const scrollRight = () => {
    if (!sliderList.current) return;
    sliderList.current.scrollLeft += sliderList.current.offsetWidth;
  };

  useEffect(() => {
    const sliderListCurrent = sliderList.current;

    if (!sliderListCurrent) return;

    const scrollHandler = utils.debounce((event: any) => {
      setSliderScrollPosition(event.target?.scrollLeft);
    }, 250);
    sliderListCurrent?.addEventListener('scroll', scrollHandler, { passive: true });

    const resizeObserver = new ResizeObserver(() => {
      if (!sliderListCurrent) return;
      setSliderWidth(sliderListCurrent.offsetWidth);
      setSliderScrollWidth(sliderListCurrent.scrollWidth);
    });

    resizeObserver.observe(sliderListCurrent);

    return () => {
      sliderListCurrent?.removeEventListener('scroll', scrollHandler);
      resizeObserver.disconnect();
    };
  }, []);

  const showPrevButton = sliderScrollPosition > 0;
  const showNextButton = sliderScrollPosition < sliderScrollWidth - sliderWidth;

  return (
    <div className="tag-slider">
      <div
        role="button"
        className={'prev nav' + (showPrevButton ? ' active' : '')}
        onClick={scrollLeft}
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            scrollLeft();
          }
        }}
      >
        <div className="nav-icon">
          <NavigateBeforeIcon />
        </div>
      </div>
      <div className="tag-slider-list" ref={sliderList}>
        {items?.map((item) =>
          renderItem(item, selectedItemIds.has(item.attributeid), () => onItemClick(item)),
        )}
      </div>
      <div
        role="button"
        className={'next nav' + (showNextButton ? ' active' : '')}
        onClick={scrollRight}
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            scrollRight();
          }
        }}
      >
        <div className="nav-icon">
          <NavigateNextIcon />
        </div>
      </div>
    </div>
  );
};

export default Slider;
