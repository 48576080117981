import { useMsal } from '@azure/msal-react';
import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import DotDivider from 'shared/components/divider/DotDivider';
import 'shared/components/search-container/SearchContainer.scss';
import useCanDispatch from 'shared/hooks/useCanDispatch';
import utils from 'shared/services/utilities.service';
import { RequestStatus } from 'shared/store/request.reducer';
import { AppDispatch, RootState } from 'shared/store/root.store';
import { GetEventDetailsArgs, getEventDetails } from '../../slices/eventDetails.slice';
import { mainImageStyle } from '../EntertainmentSearchResultsHeader';
import { DEFAULT_IMAGE } from '../useGetEntertainmentProductDetails';
import EventDetails from './EventDetails';
import './EventDetails.css';

const EventDetailsPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { eventId } = useParams();
  const { instance, accounts } = useMsal();
  const { brandId, salesChannelId } = useSelector((state: RootState) => state.userSettings);
  const { eventDetails, status } = useSelector((state: RootState) => state.eventDetailsSlice);

  const [isExpanded, setIsExpanded] = useState(false);
  const [shouldShow, setShouldShow] = useState(false);

  const toggleParagraph = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (eventDetails?.description?.value) {
      // do not show button for short texts
      const shouldShowButton = eventDetails?.description?.value.length > 200;
      setShouldShow(shouldShowButton);
    }
  }, [eventDetails?.description?.value]);

  const canDispatch = useCanDispatch();

  useEffect(() => {
    if (eventId && Number(eventId) && canDispatch) {
      const params: GetEventDetailsArgs = {
        tenantid: accounts[0].tenantId,
        brandid: brandId,
        saleschannelid: salesChannelId,
        languagecode: utils.getCurrentLanguage(),
        eventid: Number(eventId),
        instance,
        account: accounts[0],
      };
      dispatch(getEventDetails(params));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId, canDispatch, accounts, instance, brandId, salesChannelId, eventId]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '50px',
      }}
    >
      {eventDetails && (
        <>
          <Box
            className="container box"
            sx={{
              marginTop: '50px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              padding: '15px 30px',
              height: 'auto',
              width: '90%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                gap: '30px',
                flex: 1,
                '.title': {
                  fontSize: '25px',
                  fontWeight: 'bold',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '100%',
                  lineHeight: '30px',
                },
                '.additional-info, span': {
                  fontSize: '14px',
                  color: '#888888',
                },
                img: {
                  ...mainImageStyle,
                  width: '160px',
                  height: '120px',
                  alignSelf: 'flex-start',
                },
              }}
            >
              {!eventDetails &&
                (status === RequestStatus.Idle || status === RequestStatus.Failed) && (
                  <h1>Not found</h1>
                )}
              <img
                src={
                  eventDetails.mainimageurl?.value ? eventDetails.mainimageurl.value : DEFAULT_IMAGE
                }
                alt="event"
              />
              {eventDetails.eventname && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                    gap: '10px',
                    padding: '15px 0',
                    flex: 1,
                  }}
                >
                  <Typography component="p" className="title">
                    {eventDetails.eventname}
                  </Typography>

                  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Typography component="p" className="additional-info">
                      {eventDetails.venue?.venuelocation && (
                        <Typography component="span">
                          {eventDetails.venue?.venuelocation}
                        </Typography>
                      )}

                      {eventDetails.venue?.venueaddress?.value && (
                        <>
                          <DotDivider />
                          <Typography component="span">
                            {eventDetails.venue?.venueaddress?.value}
                          </Typography>
                        </>
                      )}
                    </Typography>
                  </Box>
                  {eventDetails?.description && eventDetails?.description.value && (
                    <Box
                      sx={{
                        marginRight: '30px', // same as gap
                        fontSize: '14px',
                        lineHeight: '21px',
                        display: 'flex',
                        flexDirection: isExpanded ? 'column' : 'row',
                        width: '100%',
                        'p > p': {
                          margin: 0,
                        },
                      }}
                    >
                      <p
                        className={`event-description ${isExpanded ? 'expanded' : ''}`}
                        dangerouslySetInnerHTML={{ __html: eventDetails?.description.value }}
                      ></p>
                      {!isExpanded && shouldShow && (
                        <button onClick={toggleParagraph} className="toggleButton">
                          Read more
                        </button>
                      )}
                      {isExpanded && shouldShow && (
                        <button onClick={toggleParagraph} className="toggleButton expanded">
                          close
                        </button>
                      )}
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Box>

          <Box className="container-box">
            <EventDetails />
          </Box>
        </>
      )}
    </Box>
  );
};

export default EventDetailsPage;
