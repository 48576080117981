import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { Box } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Slider from '@mui/material/Slider';
import { deepmerge } from '@mui/utils';
import { ChangeEvent, ChangeEventHandler, FC, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import utils from 'shared/services/utilities.service';
import {
  CheckboxInputs,
  DurationNames,
  IFilterForm,
  IResultsFilters,
  TimeNames,
  specialAttributes,
} from '../Results.interface';
import CheckboxGroup from './CheckboxGroup';
import './ResultsFilters.scss';

const PriceInput = ({
  value,
  currencyCode,
  onChange,
}: {
  value: number;
  currencyCode: string;
  onChange: ChangeEventHandler<HTMLInputElement> | undefined;
}) => {
  const [focused, setFocused] = useState(false);

  return (
    <input
      type="text"
      onChange={onChange}
      value={focused ? value : utils.formatCurrency(value, currencyCode)}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
    />
  );
};

const ResultsFilters: FC<IResultsFilters> = ({
  onFilterChange,
  priceInfo,
  disabledSpecials,
  currencyCode,
}) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const isSpecialDisabled = useCallback(
    (attributeName: string) => disabledSpecials.some((sa) => sa.name === attributeName),
    [disabledSpecials],
  );

  const { setValue, getValues, watch } = useForm<IFilterForm>({
    defaultValues: {
      price: [priceInfo.minPrice, priceInfo.maxPrice],
      duration: [
        {
          label: t('searchResultsPage.resultFilters.lessThanOneHour'),
          name: DurationNames.UnderOneHour,
          value: false,
        },
        {
          label: t('searchResultsPage.resultFilters.oneToFourHours'),
          name: DurationNames.UpToFourHours,
          value: false,
        },
        {
          label: t('searchResultsPage.resultFilters.fourHoursToOneDay'),
          name: DurationNames.UpToOneDay,
          value: false,
        },
        {
          label: t('searchResultsPage.resultFilters.moreThanOneDay'),
          name: DurationNames.OverOneDay,
          value: false,
        },
      ],
      time: [
        {
          label: t('searchResultsPage.resultFilters.6amTo12pm'),
          name: TimeNames.SixToTwelve,
          value: false,
        },
        {
          label: t('searchResultsPage.resultFilters.12pmTo5pm'),
          name: TimeNames.TwelveToFive,
          value: false,
        },
        {
          label: t('searchResultsPage.resultFilters.5pmTo12am'),
          name: TimeNames.FiveToTwelve,
          value: false,
        },
      ],
      rating: 1,
      specials: specialAttributes.map((specialAttribute) => ({
        name: specialAttribute.name,
        label: t(specialAttribute.displayNameKey),
        value: false,
        disabled: isSpecialDisabled(specialAttribute.name),
      })),
    },
  });

  watch(['specials', 'price']);

  const starRatingLanguageTags: Record<number, string> = {
    1: 'thingToDoFilter.starsOne',
    2: 'thingToDoFilter.starsTwo',
    3: 'thingToDoFilter.starsThree',
    4: 'thingToDoFilter.starsFour',
    5: 'thingToDoFilter.starsFive',
  };

  useEffect(() => {
    setValue(
      'specials',
      getValues().specials.map((item) => ({
        ...item,
        disabled: isSpecialDisabled(item.name) && !item.value,
      })),
    );
  }, [disabledSpecials, getValues, isSpecialDisabled, setValue]);

  useEffect(() => {
    setValue('price', [priceInfo.minPrice, priceInfo.maxPrice]);
  }, [priceInfo, setValue]);

  const radioChange = (event: ChangeEvent<HTMLInputElement>) => {
    const rating = Number(event.target.value);
    onFilterChange({ ...getValues(), rating });
    setValue('rating', rating);
  };

  const checkboxChange = (
    event: ChangeEvent<HTMLInputElement>,
    index: number,
    type: CheckboxInputs,
  ) => {
    const changedCheckboxes = getValues()[type].map((checkbox, i) => ({
      ...checkbox,
      value: i === index ? event.target.checked : checkbox.value,
    }));
    onFilterChange({ ...getValues(), [type]: changedCheckboxes });
    setValue(type, changedCheckboxes);
  };

  const priceChange = useCallback(
    (_event: Event, value: number[] | number) => {
      const price = Array.isArray(value) ? value : [value];
      onFilterChange({ ...getValues(), price });
      setValue('price', price);
    },
    [setValue, getValues, onFilterChange],
  );

  const manualPriceChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, isMaxPrice: boolean) => {
      const parsedNumber = parseFloat(event.target.value);
      if (isNaN(parsedNumber)) {
        return;
      }

      const originalPrice = getValues('price');

      let price: number[] = [];

      if (isMaxPrice) {
        price = [
          originalPrice[0],
          parsedNumber > priceInfo.maxPrice ? priceInfo.maxPrice : parsedNumber,
        ];
      } else {
        if (parsedNumber > originalPrice[1]) {
          price = [originalPrice[1], originalPrice[1]];
        } else {
          price = [
            parsedNumber < priceInfo.minPrice ? priceInfo.minPrice : parsedNumber,
            originalPrice[1],
          ];
        }
      }

      onFilterChange({ ...getValues(), price });
      setValue('price', price);
    },
    [setValue, getValues, onFilterChange, priceInfo.maxPrice, priceInfo.minPrice],
  );

  useEffect(() => {
    const filterParams = searchParams.get('filters');

    if (filterParams) {
      const filterForm = deepmerge(getValues(), JSON.parse(filterParams));

      setValue('time', filterForm.time);
      setValue('duration', filterForm.duration);
      setValue('price', filterForm.price);
      setValue('rating', filterForm.rating);
      setValue('specials', filterForm.specials);

      onFilterChange(filterForm);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const StarRadioLabel: FC<{ value: number }> = ({ value }) => (
    <FormControlLabel
      value={value}
      control={<Radio checkedIcon={<StarIcon color="secondary" />} icon={<StarBorderIcon />} />}
      label={t(starRatingLanguageTags[value])}
    />
  );

  return (
    <Box className="results-filters" sx={{ backgroundColor: 'white' }}>
      <div className="filter-group mt-3 mb-5 pb-5">
        <div className="filter-title">{t('searchResultsPage.resultFilters.price')}</div>

        <Slider
          getAriaLabel={() => 'Price range'}
          step={Math.ceil(priceInfo.maxPrice / 20)}
          marks
          value={getValues().price}
          min={priceInfo.minPrice}
          max={priceInfo.maxPrice}
          onChange={priceChange}
          valueLabelDisplay="auto"
        />
        <div className="price-wrap">
          <div className="min-price" data-price={getValues().price[0]}>
            <PriceInput
              value={getValues().price[0]}
              currencyCode={currencyCode}
              onChange={(event) => manualPriceChange(event, false)}
            />
          </div>
          <div className="max-price" data-price={getValues().price[1]}>
            <PriceInput
              value={getValues().price[1]}
              currencyCode={currencyCode}
              onChange={(event) => manualPriceChange(event, true)}
            />
          </div>
        </div>
      </div>

      <div className="filter-group mb-5 pb-5">
        <div className="filter-title mb-2">{t('searchResultsPage.resultFilters.duration')}</div>
        <CheckboxGroup list={getValues().duration} onChange={checkboxChange} type="duration" />
      </div>

      <div className="filter-group mb-5 pb-5">
        <div className="filter-title mb-2">{t('searchResultsPage.resultFilters.timeOfDay')}</div>
        <CheckboxGroup list={getValues().time} onChange={checkboxChange} type="time" />
      </div>

      <div className="filter-group mb-5 pb-5">
        <div className="filter-title mb-2">{t('searchResultsPage.resultFilters.rating')}</div>
        <FormControl>
          <RadioGroup defaultValue={1} name="star-radio" onChange={radioChange}>
            <StarRadioLabel value={5} />
            <StarRadioLabel value={4} />
            <StarRadioLabel value={3} />
            <StarRadioLabel value={2} />
            <StarRadioLabel value={1} />
          </RadioGroup>
        </FormControl>
      </div>

      <div className="filter-group">
        <div className="filter-title mb-2">{t('searchResultsPage.resultFilters.specials')}</div>
        <CheckboxGroup list={getValues().specials} onChange={checkboxChange} type="specials" />
      </div>
    </Box>
  );
};

export default ResultsFilters;
