import { useCallback, useEffect, useRef, useState } from 'react';
import { useInView } from 'react-cool-inview';

const useThingsToDoInfiniteScroll = (props: { data: any[] }) => {
  const { data } = props;
  const [items, setItems] = useState<any[]>([]);
  const initialPageLoaded = useRef(false);
  const [hasMore, setHasMore] = useState(false);
  const [pageSize] = useState(10);
  const initialPage = useRef(0);
  const highestPageLoaded = useRef(initialPage.current);

  const loadItems = useCallback(() => {
    const start = highestPageLoaded.current * pageSize;
    const end = start + pageSize;

    const pagedData = data.slice(start, end);

    setHasMore(data.length > end);
    setItems((prevItems) => [...prevItems, ...pagedData]);
  }, [data, pageSize]);

  const loadNext = () => {
    highestPageLoaded.current++;
    loadItems();
  };

  const reset = useCallback(() => {
    highestPageLoaded.current = 0;
    setHasMore(data.length > pageSize);
    setItems(data.slice(0, pageSize));
  }, [data, pageSize]);

  useEffect(() => {
    if (initialPageLoaded.current) {
      return;
    }

    loadItems();
    initialPageLoaded.current = true;
  }, [loadItems]);

  useEffect(() => {
    reset();
  }, [data, reset]);

  const { observe } = useInView({
    onEnter: () => {
      loadNext();
    },
  });

  return {
    items,
    hasMore,
    loadItems,
    loadMoreRef: observe,
  };
};

export default useThingsToDoInfiniteScroll;
