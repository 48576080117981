import { createListenerMiddleware } from '@reduxjs/toolkit';
import { getI18n } from 'react-i18next';
import {
  setBrandId,
  setCurrency,
  setLanguage,
  setSalesChannelId,
  setTimezone,
} from 'shared/slices/user-settings.slice';
import { getBrands } from './request.thunk';

const listenerMiddleware = createListenerMiddleware();

listenerMiddleware.startListening({
  actionCreator: setCurrency,
  effect: async (action) => {
    localStorage.setItem('currencyCode', action.payload);
  },
});

listenerMiddleware.startListening({
  actionCreator: setLanguage,
  effect: async (action) => {
    localStorage.setItem('languageCode', action.payload);
    getI18n().changeLanguage(action.payload);
  },
});

listenerMiddleware.startListening({
  actionCreator: setTimezone,
  effect: async (action) => {
    localStorage.setItem('timeZone', action.payload);
  },
});

listenerMiddleware.startListening({
  actionCreator: setBrandId,
  effect: async (action) => {
    localStorage.setItem('selectedBrandId', action.payload.toString());
  },
});

listenerMiddleware.startListening({
  actionCreator: getBrands.fulfilled,
  effect: async (action, listenerApi) => {
    const selectedBrandIdAsString = localStorage.getItem('selectedBrandId');

    const selectedBrandId = selectedBrandIdAsString ? Number(selectedBrandIdAsString) : undefined;

    if (action.payload.brandidsList.length > 0 && (!selectedBrandId || selectedBrandId === -1)) {
      listenerApi.dispatch(setBrandId(action.payload.brandidsList[0].id));
    }

    const webSalesChannel = action.payload.saleschannelidsList.find((scid) => scid.name === 'Web');

    if (webSalesChannel) {
      listenerApi.dispatch(setSalesChannelId(webSalesChannel.id));
    }
  },
});

export default listenerMiddleware;
