import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Rating } from '@mui/material';
import { IResultsItemProps, specialAttributes } from 'pages/things-to-do/results/Results.interface';
import { setSelectedProduct } from 'pages/things-to-do/slices/search.slice';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { PageRoute } from 'shared/interfaces/config.interface';
import utils from 'shared/services/utilities.service';
import { AppDispatch } from 'shared/store/root.store';
import { convertMinutesToDuration } from 'utils/dateConverter';
import './ResultsItem.scss';

const ResultsItem: FC<IResultsItemProps> = ({ result, viewType }) => {
  const { t } = useTranslation();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const dispatch = useDispatch<AppDispatch>();

  return (
    <Link
      className={`results-item ${viewType}`}
      tabIndex={0}
      to={`${PageRoute.ProductDetails}/${result.productid}`}
      onClick={() => dispatch(setSelectedProduct(result.productid))}
      data-review-rating={result.reviewrating?.value}
      data-ranking={result.ranking?.value}
      data-from-price={result.fromprice?.value}
      data-duration-from={result.durationfromminutes?.value}
    >
      <div className="image">
        <img
          src={result.mainimageurl}
          alt=""
          onError={(event) => {
            event.currentTarget.style.display = 'none';
          }}
        />

        <div className="hover">
          <ArrowForwardIcon />
        </div>
      </div>

      <div className="product-container">
        <div className="w-100">
          <h4 className="h4">{result.productname}</h4>

          {viewType !== 'dense' && (
            <>
              <div className="content-information-container">
                {result.reviewrating?.value && (
                  <div className="rating">
                    <Rating
                      name="read-only"
                      value={Number(result.reviewrating?.value)}
                      precision={0.5}
                      readOnly
                    />
                    <div className="rating-number">{'(' + result.totalreviews + ')'}</div>
                  </div>
                )}

                {viewType === 'normal' && (
                  <div className="description mb-1">{result.shortdescription}</div>
                )}

                {result.durationfromminutes?.value && (
                  <div className="duration">
                    <AccessTimeIcon />
                    {convertMinutesToDuration(result.durationfromminutes.value)}
                  </div>
                )}
              </div>

              {specialAttributes
                .filter((specialAttribute) =>
                  result.attributeidlistList.includes(specialAttribute.tagId),
                )
                .map((specialAttribute) => (
                  <div key={specialAttribute.tagId} className="feature-tag">
                    {t(specialAttribute.displayNameKey)}
                  </div>
                ))}
            </>
          )}
        </div>

        {result.fromprice?.value && (
          <div className="price-container">
            {viewType === 'normal' && <div className="from">{t('generic.from')}</div>}
            <strong className="price-amount">
              {utils.formatCurrency(result.fromprice.value as any as number, result.currencycode)}
            </strong>
          </div>
        )}
      </div>
    </Link>
  );
};

export default ResultsItem;
