import {
  AsyncThunk,
  AsyncThunkOptions,
  createReducer,
  Dispatch,
  Draft,
  SerializedError,
} from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { RootState } from 'shared/store/root.store';

export enum RequestStatus {
  Null = 'null', // no request have been sent yet
  Idle = 'idle',
  Loading = 'loading',
  Failed = 'failed',
}

export interface RequestState<T> {
  value?: T;
  status: RequestStatus;
  error: SerializedError | undefined;
}

// same as in createAsycThunk.d.ts but it is not exported
export type AsyncThunkConfig = {
  state?: unknown;
  dispatch?: Dispatch;
  extra?: unknown;
  rejectValue?: unknown;
  serializedErrorType?: unknown;
  pendingMeta?: unknown;
  fulfilledMeta?: unknown;
  rejectedMeta?: unknown;
};

class RequestReducer {
  public createRequestReducer<T>(thunk: AsyncThunk<T, any, AsyncThunkOptions & AsyncThunkConfig>) {
    const requestInitialState: RequestState<T> = {
      value: undefined,
      status: RequestStatus.Null,
      error: undefined,
    };

    return createReducer(requestInitialState, (builder) => {
      builder
        .addCase(thunk.pending, (state) => {
          state.status = RequestStatus.Loading;
          state.value = undefined;
          state.error = undefined;
        })
        .addCase(thunk.fulfilled, (state, action) => {
          state.status = RequestStatus.Idle;
          state.value = action.payload as Draft<T>;
        })
        .addCase(thunk.rejected, (state, action) => {
          state.status = RequestStatus.Failed;
          state.value = undefined;
          state.error = action.error;
          if (action.error) {
            toast.error(action.error.message || 'Unknown error occurred!');
            toast.clearWaitingQueue();
          }
        });
    });
  }

  public selector<T extends keyof RootState>(storeName: T) {
    return (state: RootState): RootState[T] => state[storeName];
  }
}

const requestReducer = new RequestReducer();

export default requestReducer;
