import { useMsal } from '@azure/msal-react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { Int32Value, StringValue } from 'google-protobuf/google/protobuf/wrappers_pb';
import TicketSupplierContainer from 'pages/entertainment/results/EventDetailsPage/TicketSupplierContainer';
import {
  getDaysWithPerformance,
  getPerformancesToDisplay,
  setSelectedDay,
} from 'pages/entertainment/slices/eventDetails.slice';
import { getPerformanceAvailabilities } from 'pages/entertainment/slices/performanceAvailability.slice';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import 'shared/components/search-container/SearchContainer.scss';
import useCanDispatch from 'shared/hooks/useCanDispatch';
import { RequestStatus } from 'shared/store/request.reducer';
import { getPerformances } from 'shared/store/request.thunk';
import { AppDispatch, RootState } from 'shared/store/root.store';
import {
  formatDateToYYYYMM,
  getCurrentYearDateRange,
  getStartAndEndOfMonth,
} from 'utils/dateConverter';
import CalendarComponent from './Calendar';
import './EventDetails.css';
import Performances from './Performances';

const CalendarTextStyle = {
  fontFamily: 'Inter, sans-serif !important',
  fontSize: '.8em',
};

const EventDetails = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const { eventId } = useParams();
  const { instance, accounts } = useMsal();

  const [searchParams] = useSearchParams();
  const yearAndMonth = searchParams.get('month');
  const performanceId = searchParams.get('performance');

  const canDispatch = useCanDispatch();

  const { brandId, salesChannelId, currency } = useSelector(
    (state: RootState) => state.userSettings,
  );
  const {
    value: performances,
    status: getPerformancesStatus,
    error: getPerformancesError,
  } = useSelector((state: RootState) => state.getPerformances);
  const { selectedDay, performancesToDisplay, selectedPerformance } = useSelector(
    (state: RootState) => state.eventDetailsSlice,
  );
  const {
    value: performanceAvailability,
    status: performanceAvailabilityStatus,
    error: performanceAvailabilityError,
  } = useSelector((state: RootState) => state.performanceAvailability);

  useEffect(() => {
    if (performances?.[0] && getPerformancesStatus !== RequestStatus.Null) {
      const selectedPerformanceItem = performances?.filter(
        (performance) => performance.performanceid === Number(performanceId),
      );
      if (selectedPerformanceItem?.[0]) {
        dispatch(setSelectedDay(new Date(selectedPerformanceItem?.[0].date)));
      } else {
        dispatch(setSelectedDay(new Date(performances?.[0].date)));
      }
    }
  }, [performances, getPerformancesStatus, performanceId, dispatch]);

  // yearandmonth: YYYY-MM
  const getPerformanceList = (yearandmonth?: string, performanceid?: string) => {
    let startDate = yearandmonth;
    let endDate = yearandmonth;
    if (yearandmonth) {
      startDate = getStartAndEndOfMonth(yearandmonth).startDate;
      endDate = getStartAndEndOfMonth(yearandmonth).endDate;
    } else {
      startDate = getCurrentYearDateRange().startdate;
      endDate = getCurrentYearDateRange().enddate;
    }

    const params = {
      tenantid: accounts[0].tenantId,
      brandid: brandId,
      saleschannelid: salesChannelId,
      eventid: Number(eventId),
      instance,
      account: accounts[0],
      performanceid:
        performanceid && getPerformancesStatus === RequestStatus.Null
          ? new Int32Value().setValue(Number(performanceId)).toObject()
          : undefined,
      // if there is performance id, do not send start and end dates, but only on first render
      startdate:
        startDate && (!performanceId || getPerformancesStatus !== RequestStatus.Null)
          ? new StringValue().setValue(startDate).toObject()
          : undefined,
      enddate:
        endDate && (!performanceId || getPerformancesStatus !== RequestStatus.Null)
          ? new StringValue().setValue(endDate).toObject()
          : undefined,
    };
    dispatch(getPerformances(params));
  };

  // on first render, get all performances in month
  useEffect(() => {
    if (eventId && Number(eventId) && canDispatch) {
      // if performanceid is in url, only send performanceid
      if (performanceId) {
        getPerformanceList(undefined, performanceId);
      }
      // if performanceid is not url, send month from url
      else if (yearAndMonth) {
        getPerformanceList(yearAndMonth);
      }
      // if neither is in url, do not pass anything
      // get performances from current year, getPerformanceList handles it
      else {
        getPerformanceList();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    eventId,
    canDispatch,
    accounts,
    instance,
    brandId,
    salesChannelId,
    performanceId,
    yearAndMonth,
  ]);

  const handleMonthChange = (newDate: Date) => {
    const formattedDate = formatDateToYYYYMM(newDate);
    getPerformanceList(formattedDate);
  };

  useEffect(() => {
    if (selectedDay && performances) {
      dispatch(getPerformancesToDisplay({ date: selectedDay, performancelist: performances }));
    }
  }, [dispatch, performances, selectedDay]);

  useEffect(() => {
    if (performances) {
      dispatch(getDaysWithPerformance(performances));
    }
  }, [dispatch, performances]);

  useEffect(() => {
    if (canDispatch && currency) {
      dispatch(
        getPerformanceAvailabilities({
          instance: instance,
          account: accounts[0],
          brandId,
          salesChannelId,
          performanceIdList: [Number(performanceId)],
          dispatch,
          currency,
        }),
      );
    }
  }, [canDispatch, accounts, instance, brandId, salesChannelId, performanceId, dispatch, currency]);

  useEffect(() => {
    // this runs when selectedPerformance is changing, so the link for the external site is updated
    if (canDispatch && currency && selectedPerformance) {
      dispatch(
        getPerformanceAvailabilities({
          instance: instance,
          account: accounts[0],
          brandId,
          salesChannelId,
          performanceIdList: [selectedPerformance.performanceid],
          dispatch,
          currency,
        }),
      );
    }
  }, [
    canDispatch,
    accounts,
    instance,
    brandId,
    salesChannelId,
    performanceId,
    dispatch,
    currency,
    selectedPerformance,
  ]);

  return (
    <Box
      sx={{
        gap: '50px',
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
      }}
    >
      <Box
        className="container simple-box"
        sx={{
          width: '313px',
          height: 'fit-content',
        }}
      >
        {getPerformancesStatus !== RequestStatus.Null && selectedDay && (
          <CalendarComponent handleMonthChange={handleMonthChange} />
        )}

        {selectedPerformance && (
          <Box sx={{ margin: '20px 10px' }}>
            <Typography sx={{ ...CalendarTextStyle, display: 'block' }}>
              {t('generic.selected')}:
            </Typography>
            <Typography sx={{ ...CalendarTextStyle, display: 'inline-block', marginRight: '.5em' }}>
              {selectedPerformance?.date ? selectedPerformance?.date.replaceAll('-', '.') : ''}
            </Typography>
            <Typography sx={{ ...CalendarTextStyle, display: 'inline-block' }}>
              {selectedPerformance?.time}
            </Typography>
          </Box>
        )}

        {performances && performancesToDisplay.length > 0 && <Performances />}

        {(!performances || performances?.length === 0) &&
          getPerformancesStatus !== RequestStatus.Null &&
          getPerformancesStatus !== RequestStatus.Loading && (
            <Typography sx={{ margin: '20px 10px', ...CalendarTextStyle }}>
              {t('eventDetails.noResultInMonth')}
            </Typography>
          )}

        {performances &&
          performances.length > 0 &&
          performancesToDisplay.length === 0 &&
          getPerformancesStatus !== RequestStatus.Null && (
            <Typography sx={{ margin: '20px 10px', ...CalendarTextStyle }}>
              {t('eventDetails.noResultOnThisDay')}
            </Typography>
          )}
      </Box>
      <Box className="container simple-box" sx={{ display: 'flex', flex: 1 }}>
        {performanceAvailabilityStatus === RequestStatus.Loading && (
          <Box sx={{ margin: 'auto' }}>
            <CircularProgress />
          </Box>
        )}

        {performanceAvailabilityStatus === RequestStatus.Idle &&
          performanceAvailability &&
          performanceAvailability.length > 0 && <TicketSupplierContainer />}

        {performanceAvailabilityStatus === RequestStatus.Failed && performanceAvailabilityError && (
          <Typography sx={{ margin: 'auto' }}>{performanceAvailabilityError.message}</Typography>
        )}

        {performanceAvailabilityStatus === RequestStatus.Idle &&
          (!performanceAvailability || performanceAvailability?.length === 0) && (
            <Typography sx={{ margin: 'auto' }}>
              {t('productDetails.entertainment.noAvailableTicketsforPerformance')}
            </Typography>
          )}

        {getPerformancesStatus === RequestStatus.Failed && getPerformancesError && (
          <Typography sx={{ margin: 'auto' }}>{getPerformancesError.message}</Typography>
        )}
      </Box>
    </Box>
  );
};

export default EventDetails;
