import { useMsal } from '@azure/msal-react';
import { loginRequest } from 'authConfig';
import { setSelectedTags } from 'pages/things-to-do/slices/search.slice';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { HighSecurityContext } from 'shared/contexts/HighSecurityContext';
import { setBrandId, setSalesChannelId } from 'shared/slices/user-settings.slice';
import { attractionProductSearch, getMockHighSecurity } from 'shared/store/request.thunk';
import { AppDispatch } from 'shared/store/root.store';

const useLoginLogout = () => {
  const { instance } = useMsal();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const dispatch = useDispatch<AppDispatch>();

  const { setIsTokenTooOld } = useContext(HighSecurityContext);

  return {
    login: async (isQuickLoginForHighSecurity: boolean) => {
      if (isQuickLoginForHighSecurity) {
        await instance.loginPopup({ ...loginRequest, prompt: 'login' });

        // all high security calls should be cleared here, if there are more think about a better pattern
        dispatch(getMockHighSecurity({ isClear: true }));
        dispatch(attractionProductSearch({ isClear: true }));
        dispatch(setSelectedTags(new Set<number>()));
        setIsTokenTooOld(false);
      } else {
        instance.loginRedirect(loginRequest);
      }
    },
    logout: () => {
      // Setting brand id to default in store here, which will trigger a local storage update as well
      dispatch(setBrandId(-1));
      dispatch(setSalesChannelId(-1));

      instance.logoutRedirect();
    },
  };
};

export default useLoginLogout;
