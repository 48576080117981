import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box } from '@mui/material';
import { setSelectedDay } from 'pages/entertainment/slices/eventDetails.slice';
import Calendar from 'react-calendar';
import { useDispatch, useSelector } from 'react-redux';
import 'shared/components/search-container/SearchContainer.scss';
import { AppDispatch, RootState } from 'shared/store/root.store';
import { formatDateStringToISO8601DateFormat } from 'utils/dateConverter';
import './Calendar.scss';

// react-calendar specific types
export type ValuePiece = Date | null;
export type Value = ValuePiece | [ValuePiece, ValuePiece];

const CalendarComponent = (props: { handleMonthChange: (month: Date) => void }) => {
  const { handleMonthChange } = props;
  const dispatch = useDispatch<AppDispatch>();

  const { selectedDay, daysWithPerformanceInMonth } = useSelector(
    (state: RootState) => state.eventDetailsSlice,
  );

  return (
    <Box
      sx={{
        minHeight: '260.8px', // maximum, when month is 6 weeks, to avoid jumping of component below
        '.MuiSvgIcon-root': {
          fontSize: '1rem',
        },
      }}
    >
      <Calendar
        onChange={(clickedDay, event) => {
          if (clickedDay instanceof Date) {
            dispatch(setSelectedDay(clickedDay));
          }
        }}
        onActiveStartDateChange={(event) => {
          if (event.activeStartDate instanceof Date) {
            handleMonthChange(event.activeStartDate);
          }
        }}
        value={selectedDay}
        className="calendar"
        calendarType="gregory"
        showNeighboringMonth={false}
        formatMonthYear={(locale, date) =>
          date.toLocaleString(locale, { month: 'short', year: 'numeric' })
        }
        nextLabel={<KeyboardArrowRightIcon />}
        prevLabel={<KeyboardArrowLeftIcon />}
        tileClassName={({ date }) => {
          return daysWithPerformanceInMonth.includes(formatDateStringToISO8601DateFormat(date))
            ? 'performance-day'
            : null;
        }}
      />
    </Box>
  );
};

export default CalendarComponent;
