import { ReserveTicketsResponse } from '@BookingPlatform/grpc/v1/Entertainment/Entertainment_pb';
import { Box, List, ListItem, Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useConfigContext } from 'shared/contexts/config.context';
import { RootState } from 'shared/store/root.store';
import Countdown from '../countdown/Countdown';
import { VerticalDivider } from '../divider/Divider';
import { getDefaultItem } from '../header/Header';

export const subtitleStyling = {
  display: 'flex',
  flexDirection: 'row',
  gap: '10px',
};

const TicketList: FC<{ entertainmentItem: ReserveTicketsResponse.AsObject }> = ({
  entertainmentItem,
}) => {
  const config = useConfigContext();
  const userSettings = useSelector((state: RootState) => state.userSettings);
  const { t } = useTranslation();

  const isThereAnySeatTextPresent: boolean = useMemo(() => {
    return entertainmentItem.reserveditemsList.some((seat) =>
      seat.seatsList.some((seat) => {
        return seat.seattext?.value;
      }),
    );
  }, [entertainmentItem]);

  return (
    <>
      {entertainmentItem.reserveditemsList.map((item, index) => (
        <List key={index} sx={{ '*': { fontSize: '1rem' }, padding: 0 }}>
          <>
            <ListItem
              sx={{
                justifyContent: 'space-between',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                paddingRight: 0,
                paddingLeft: 0,
              }}
            >
              <Typography sx={{ display: 'flex', flex: 1, fontWeight: 'bold' }}>
                {item.tickettypecode} {t('productDetails.entertainment.tickets')}
              </Typography>

              <Box>
                <Box sx={{ ...subtitleStyling }}>
                  <Typography>{t('productDetails.entertainment.numberOfTickets')}:</Typography>
                  <Typography> {item.quantity}</Typography>
                </Box>

                {item.seatsList.length > 0 && isThereAnySeatTextPresent && (
                  <Box sx={{ ...subtitleStyling }}>
                    <Typography>{t('productDetails.entertainment.seats')}:</Typography>
                    <Typography>
                      {item.seatsList.map((seat, index) => (
                        <span key={index}>
                          {seat.seattext?.value}
                          {index !== item.seatsList.length - 1 && ','}{' '}
                        </span>
                      ))}
                    </Typography>
                  </Box>
                )}

                <Box sx={{ ...subtitleStyling }}>
                  <Typography>{t('generic.price')}:</Typography>
                  <Typography>
                    {item.ticketprice?.value &&
                    item.quantity &&
                    userSettings.currency &&
                    config.currencyList
                      ? `${getDefaultItem(userSettings.currency, config.currencyList)
                          ?.iconString} ${(
                          Number(item?.ticketprice?.value) * item.quantity
                        ).toFixed(2)}`
                      : 'unknown price'}
                  </Typography>
                </Box>
              </Box>
            </ListItem>
            <Countdown initialTime={entertainmentItem.tokenexpiry} />
            <VerticalDivider />
          </>
        </List>
      ))}
    </>
  );
};

export default TicketList;
