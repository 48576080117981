import { createTheme } from '@mui/material/styles';

class ThemeService {
  public getCssVariable(name: string) {
    const styles = getComputedStyle(document.documentElement);
    return styles.getPropertyValue(name).trim();
  }

  public getSpacings() {
    const spacings: Array<number> = [0];

    for (let i = 1; i < 5; i++) {
      spacings.push(Number(this.getCssVariable(`--spacing-${i}`)));
    }

    return spacings;
  }

  public themeAvailable() {
    return this.getCssVariable('--primary-light');
  }

  public getTheme() {
    return createTheme(
      this.themeAvailable()
        ? {
            palette: {
              primary: {
                light: this.getCssVariable('--primary-light'),
                main: this.getCssVariable('--primary-main'),
                dark: this.getCssVariable('--primary-dark'),
                contrastText: '#fff',
              },
              secondary: {
                light: this.getCssVariable('--secondary-light'),
                main: this.getCssVariable('--secondary-main'),
                dark: this.getCssVariable('--secondary-dark'),
                contrastText: '#222',
              },
              error: {
                main: this.getCssVariable('--error-main'),
              },
            },
            // spacing: this.getSpacings(),
            breakpoints: {
              values: {
                xs: 0,
                sm: Number(this.getCssVariable('--break-sm').slice(0, -2)),
                md: Number(this.getCssVariable('--break-md').slice(0, -2)),
                lg: Number(this.getCssVariable('--break-lg').slice(0, -2)),
                xl: Number(this.getCssVariable('--break-xl').slice(0, -2)),
              },
            },
            typography: {
              fontFamily: '"Arimo", sans-serif',
            },
            shape: {
              borderRadius: 10,
            },
            components: {
              MuiSelect: {
                styleOverrides: {
                  outlined: {
                    fontSize: '15px',
                  },
                },
              },
              MuiInputBase: {
                styleOverrides: {
                  root: {
                    fontSize: '15px',
                  },
                },
              },
              MuiRating: {
                styleOverrides: {
                  root: {
                    '& .MuiRating-icon': {
                      color: this.getCssVariable('--secondary-main'),
                    },
                    '& .MuiRating-iconFilled': {
                      color: this.getCssVariable('--secondary-main'),
                    },
                  },
                },
              },
              MuiTextField: {
                styleOverrides: {
                  root: {
                    'fieldset span': {
                      fontSize: '0.74rem',
                      height: '11px',
                      display: 'block',
                    },
                  },
                },
              },
              MuiButton: {
                styleOverrides: {
                  root: {
                    paddingTop: '9px',
                    paddingBottom: '9px',
                    fontSize: '14px',
                  },
                },
              },
              MuiFormHelperText: {
                styleOverrides: {
                  root: {
                    position: 'absolute',
                    top: 'calc(100% - 12px)',
                    padding: '3px 6px',
                    borderRadius: '4px',
                    color: '#fff !important',
                    lineHeight: '1',
                    background: this.getCssVariable('--error-main'),
                  },
                },
              },
              MuiTooltip: {
                styleOverrides: {
                  tooltip: {
                    borderRadius: '10px',
                    background: '#fff',
                    color: '#000',
                    padding: '20px',
                    boxShadow: '5px 5px 25px rgb(36 120 114 / 24%)',
                  },
                  arrow: {
                    color: '#fff',
                  },
                },
              },
            },
          }
        : {},
    );
  }
}

const themeService = new ThemeService();

export default themeService;
