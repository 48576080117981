// import { AutocompleteCategory as EntertainmentAutocompleteCategory } from '@BookingPlatform/grpc/Entertainment/EntertainmentAutocomplete_pb';
import { Category as AttractionCategory } from '@BookingPlatform/grpc/v1/Attraction/Attraction_pb';
import { AutocompleteCategory as DiningAutocompleteCategory } from '@BookingPlatform/grpc/v1/Dining/Dining_pb';
import {
  AutocompleteCategory as EntertainmentAutocompleteCategory,
  SearchCategory,
} from '@BookingPlatform/grpc/v1/Entertainment/Entertainment_pb';
import { AutoCompleteResultItem } from '@BookingPlatform/grpc/v1/Shared/AutoCompleteResultItem_pb';
import { DiningSearchFieldValue } from 'pages/dining/Dining.interface';
import { EntertainmentSearchFieldValue } from 'pages/entertainment/Entertainment.interface';
import { AttractionSearchFieldValue } from 'pages/things-to-do/ThingsToDo.interface';

export const mapSearchFieldToAttractionType = (
  list: AutoCompleteResultItem.AsObject[],
  type: AttractionCategory,
): Array<AttractionSearchFieldValue> => {
  return list.map((item) => ({
    id: item.id,
    name: item.name,
    type,
  }));
};

export const mapSearchFieldToDiningType = (
  resultsList: AutoCompleteResultItem.AsObject[],
  type: DiningAutocompleteCategory,
): Array<DiningSearchFieldValue> => {
  return resultsList.map((resultsListItem) => ({
    ...resultsListItem,
    type,
  }));
};

export const mapSearchFieldToEntertainmentType = (
  resultsList: AutoCompleteResultItem.AsObject[],
  type: EntertainmentAutocompleteCategory,
): Array<EntertainmentSearchFieldValue> => {
  return resultsList.map((resultsListItem) => ({
    ...resultsListItem,
    type,
  }));
};

export const sliceList = (list: Array<any>, amountOfItems = 5): Array<any> => {
  return list.slice(0, amountOfItems);
};

export function getSearchCategoryById(id: number): SearchCategory | null {
  const category = Object.values(SearchCategory).find(
    (value) => typeof value === 'number' && value === id,
  );

  return category !== undefined ? (category as SearchCategory) : null;
}
