import {
  Action,
  combineReducers,
  configureStore,
  PreloadedState,
  ThunkAction,
} from '@reduxjs/toolkit';
import entertainmentSearchReducer from 'pages/entertainment/slices/entertainmentSearch.slice';
import eventDeatailsReducer from 'pages/entertainment/slices/eventDetails.slice';
import performanceAvailabilityReducer from 'pages/entertainment/slices/performanceAvailability.slice';
import searchReducer from 'pages/things-to-do/slices/search.slice';
import { useDispatch } from 'react-redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import configService from 'shared/services/config.service';
import bookingBasketReducer from 'shared/slices/booking-basket.slice';
import bookingSessionReducer from 'shared/slices/booking-session.slice';
import checkoutReducer from 'shared/slices/checkout.slice';
import paymentReducer from 'shared/slices/payment.slice';
import userSettingsReducer from 'shared/slices/user-settings.slice';
import requestReducer from 'shared/store/request.reducer';
import {
  attractionProductSearch,
  book,
  cancel,
  checkUser,
  downloadMockReservations,
  getAvailabilityRangesResults,
  getAvailabilityResults,
  getBookingDetails,
  getBrands,
  getDiningLocations,
  getDiningVenueDetails,
  getEntertainmentLocations,
  getEntertainmentProductsOptions,
  getEventInfo,
  getLocations,
  getMockAccount,
  getMockHighSecurity,
  getMockPaymentStatus,
  getPerformances,
  getPerformerInfo,
  getProduct,
  getReservations,
  getTags,
  getVenueInfo,
  preCancel,
  reserveEntertainmentTicket,
  validate,
} from './request.thunk';
import listenerMiddleware from './root.middleware';

const reducers = {
  reservations: requestReducer.createRequestReducer(getReservations),
  bookingDetails: requestReducer.createRequestReducer(getBookingDetails),
  reservationsExport: requestReducer.createRequestReducer(downloadMockReservations),
  product: requestReducer.createRequestReducer(getProduct),
  locations: requestReducer.createRequestReducer(getLocations),
  thingsToDoSearchResults: requestReducer.createRequestReducer(attractionProductSearch),
  searchTags: requestReducer.createRequestReducer(getTags),
  availability: requestReducer.createRequestReducer(getAvailabilityResults),
  availabilityRanges: requestReducer.createRequestReducer(getAvailabilityRangesResults),
  searchForm: searchReducer,
  account: requestReducer.createRequestReducer(getMockAccount),
  highSecurity: requestReducer.createRequestReducer(getMockHighSecurity),
  userSettings: userSettingsReducer,
  payment: paymentReducer,
  paymentStatus: requestReducer.createRequestReducer(getMockPaymentStatus),
  checkout: checkoutReducer,
  validate: requestReducer.createRequestReducer(validate),
  preCancel: requestReducer.createRequestReducer(preCancel),
  cancel: requestReducer.createRequestReducer(cancel),
  book: requestReducer.createRequestReducer(book),
  brands: requestReducer.createRequestReducer(getBrands),
  checkUser: requestReducer.createRequestReducer(checkUser),
  bookingSession: bookingSessionReducer,
  bookingBasket: bookingBasketReducer,
  diningLocations: requestReducer.createRequestReducer(getDiningLocations),
  getDiningVenueDetails: requestReducer.createRequestReducer(getDiningVenueDetails),
  entertainmentLocations: requestReducer.createRequestReducer(getEntertainmentLocations),
  entertainmentProductsOptions: requestReducer.createRequestReducer(
    getEntertainmentProductsOptions,
  ),
  entertainmentSearch: entertainmentSearchReducer,
  entertainmentProductSearch: requestReducer.createRequestReducer(getEntertainmentProductsOptions),
  getVenueInfo: requestReducer.createRequestReducer(getVenueInfo),
  getEventInfo: requestReducer.createRequestReducer(getEventInfo),
  getPerformerInfo: requestReducer.createRequestReducer(getPerformerInfo),
  performanceAvailability: performanceAvailabilityReducer,
  getPerformances: requestReducer.createRequestReducer(getPerformances),
  eventDetailsSlice: eventDeatailsReducer,
  reserveEntertainmentTicket: requestReducer.createRequestReducer(reserveEntertainmentTicket),
};

const rootReducer = combineReducers(reducers);

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['bookingBasket', 'bookingDetails'],
  // bookingDetails: when user clicks on cancel booking, on new page app should persist details instead of sending another request
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: { config: configService.getConfig() },
      },
      serializableCheck: false,
    }).prepend(listenerMiddleware.middleware),
});

export function setupStore(preloadedState?: PreloadedState<RootState>) {
  return configureStore({
    reducer: persistedReducer,
    preloadedState,
  });
}

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export type AppStore = ReturnType<typeof setupStore>;

export const useAppDispatch: () => AppDispatch = useDispatch;
