import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AttractionSearchFieldValue } from 'pages/things-to-do/ThingsToDo.interface';

export interface SearchState {
  date: { startDate?: string; endDate?: string };
  location?: AttractionSearchFieldValue;
  selectedProductId?: number;
  selectedTagIds: Set<number>;
}

export const initialState: SearchState = {
  date: { startDate: undefined, endDate: undefined },
  location: undefined,
  selectedProductId: undefined,
  selectedTagIds: new Set(),
};

export const searchSlice = createSlice({
  name: 'search-form',
  initialState,
  reducers: {
    setSearch: (state, action: PayloadAction<Partial<SearchState>>) => {
      state.date = {
        startDate: action.payload.date?.startDate,
        endDate: action.payload.date?.endDate,
      };
      state.location = action.payload.location;
    },
    setSelectedProduct: (state, action: PayloadAction<number>) => {
      state.selectedProductId = action.payload;
    },
    setSelectedTags: (state, action: PayloadAction<Set<number>>) => {
      state.selectedTagIds = action.payload;
    },
  },
});
export const { setSearch, setSelectedProduct, setSelectedTags } = searchSlice.actions;
export default searchSlice.reducer;
