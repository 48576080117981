import {
  AvailabilityDetails,
  ItemToReserve,
  PriceBand,
  TicketType,
} from '@BookingPlatform/grpc/v1/Entertainment/Entertainment_pb';
import { useMsal } from '@azure/msal-react';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import AddToBasketButton from 'shared/components/buttons/AddToBasketButton';
import { VerticalDivider } from 'shared/components/divider/Divider';
import useCanDispatch from 'shared/hooks/useCanDispatch';
import { PageRoute } from 'shared/interfaces/config.interface';
import utils from 'shared/services/utilities.service';
import { RequestStatus } from 'shared/store/request.reducer';
import { reserveEntertainmentTicket } from 'shared/store/request.thunk';
import { AppDispatch, RootState } from 'shared/store/root.store';
import 'style/themes/platform.scss';
import { formatStringYYYYMMDDToUIFormat } from 'utils/dateConverter';
import { areAllErrorsCleared, getEventIdFromPath } from 'utils/seatmapUtils';
import NumberInput from './NumberInput';

type TicketTypeListProps = {
  supplierName: string;
  availabilityDetails: AvailabilityDetails.AsObject;
};

export type SelectedTickets = {
  [key: string]: number | null;
};

export type SelectedTicketsError = {
  [key: string]: string | undefined;
};

export type ExtendedTicketCategories = {
  [key: string]: ItemToReserve.AsObject;
};

const TicketTypeList: FC<TicketTypeListProps> = ({ supplierName, availabilityDetails }) => {
  const { tickettypesList, currencycode } = availabilityDetails;
  const { accounts, instance } = useMsal();
  const { brandId, salesChannelId } = useSelector((state: RootState) => state.userSettings);
  const {
    value: reserveEntertainmentTicketvalue,
    status: reserveEntertainmentTicketstatus,
    error: reserveEntertainmentTicketerror,
  } = useSelector((state: RootState) => state.reserveEntertainmentTicket);

  const { eventDetails } = useSelector((state: RootState) => state.eventDetailsSlice);
  const { selectedDay } = useSelector((state: RootState) => state.eventDetailsSlice);

  const [selectedTickets, setSelectedTickets] = useState<ExtendedTicketCategories>({});
  const [errors, setErrors] = useState<SelectedTicketsError>({});
  const { t } = useTranslation();
  const canDispatch = useCanDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const performanceId = searchParams.get('performance');

  const location = useLocation();
  const eventId = getEventIdFromPath(location.pathname);

  const dispatch = useDispatch<AppDispatch>();

  function updateErrors(quantity: number, ticketType: TicketType.AsObject) {
    setErrors((prevErrors) => {
      if (ticketType.validquantitiesList.includes(quantity) || quantity === 0) {
        if (prevErrors[ticketType.tickettypecode]) {
          delete prevErrors[ticketType.tickettypecode];
        }
        return prevErrors;
      } else {
        return {
          ...prevErrors,
          [ticketType.tickettypecode]: `${t(
            'general.selectCorrectQuantity',
          )}: ${ticketType.validquantitiesList.join(', ')}.`,
        };
      }
    });
  }

  const handleSelectTicket = (
    newValue: number,
    ticketType: TicketType.AsObject,
    priceBand: PriceBand.AsObject,
  ) => {
    setSelectedTickets((prevSelectedTickets) => {
      if (selectedTickets[priceBand.tickettoken]) {
        const updatedSeats = { ...prevSelectedTickets };
        const ticketItem = updatedSeats[priceBand.tickettoken];

        if (ticketItem) {
          updateErrors(newValue, ticketType);
          if (newValue === 0) {
            delete updatedSeats[priceBand.tickettoken];
          } else {
            ticketItem.quantity = newValue;
          }
        }

        return updatedSeats;
      } else {
        const ticketToBeAdded = {
          tickettoken: priceBand.tickettoken,
          quantity: 1,
          seatcodesList: [],
          tickettype: ticketType,
        };
        updateErrors(1, ticketType);

        return {
          ...prevSelectedTickets,
          [priceBand.tickettoken]: ticketToBeAdded,
        };
      }
    });
  };

  const addToBasket = () => {
    if (canDispatch && performanceId && selectedTickets) {
      dispatch(
        reserveEntertainmentTicket({
          instance,
          account: accounts[0],
          tenantid: accounts[0].tenantId,
          brandid: brandId,
          saleschannelid: salesChannelId,
          performanceid: Number(performanceId),
          ticketsList: Object.values(selectedTickets),
          currencycode: utils.getCurrentCurrency(),
          eventId,
          eventname: eventDetails?.eventname || null,
          mainimageurl: eventDetails?.mainimageurl?.value || null,
          selectedDay,
        }),
      );
    }
  };

  useEffect(() => {
    if (
      reserveEntertainmentTicketvalue &&
      reserveEntertainmentTicketstatus === RequestStatus.Idle &&
      !reserveEntertainmentTicketerror
    ) {
      setSelectedTickets({});
      toast.success(
        <Box sx={{ display: 'flex', '*': { textWrap: 'nowrap' } }}>
          <Typography sx={{ alignSelf: 'center' }}>{t('general.addedToBasket')}</Typography>
          <Button
            onClick={() => navigate(`${PageRoute.Checkout}${PageRoute.Entertainment}`)}
            sx={{ fontStyle: 'italic', mx: '4px' }}
          >
            {t('productDetails.entertainment.goToBasket')}
          </Button>
        </Box>,
        {
          toastId: 'success',
        },
      );
    }
  }, [
    reserveEntertainmentTicketvalue,
    reserveEntertainmentTicketstatus,
    reserveEntertainmentTicketerror,
    t,
    navigate,
  ]);

  useEffect(() => {
    if (
      reserveEntertainmentTicketstatus === RequestStatus.Failed &&
      reserveEntertainmentTicketerror
    )
      toast.error(
        <Box sx={{ display: 'flex', '*': { textWrap: 'nowrap' } }}>
          <Typography sx={{ alignSelf: 'center' }}>
            {reserveEntertainmentTicketerror.message}
          </Typography>
        </Box>,
      );
  }, [reserveEntertainmentTicketstatus, reserveEntertainmentTicketerror, t, navigate]);

  const isAnyPriceBandAvailable = (ticketType: TicketType.AsObject): boolean => {
    return ticketType.pricebandsList.some(isPriceBandAvailable);
  };

  const isPriceBandAvailable = (priceBand: PriceBand.AsObject): boolean => {
    return (
      priceBand.ticketsavailable === undefined ||
      (priceBand.ticketsavailable?.value !== undefined && priceBand.ticketsavailable?.value > 0)
    );
  };

  return (
    <Box
      sx={{
        '.MuiAccordionSummary-content.Mui-expanded': {
          margin: '12px 0',
        },
        '.Mui-expanded': {
          minHeight: 'unset',
        },
      }}
    >
      {tickettypesList.map((ticketType) => (
        <>
          {isAnyPriceBandAvailable(ticketType) && (
            <Accordion
              key={`${supplierName}${ticketType.tickettypecode}`}
              sx={{
                '&:first-of-type .MuiAccordionSummary-root': {
                  borderTopLeftRadius: '10px',
                  borderTopRightRadius: '10px',
                },
                '&:last-of-type .MuiAccordionSummary-root': {
                  borderBottomLeftRadius: '10px',
                  borderBottomRightRadius: '10px',
                  '&.Mui-expanded': {
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                  },
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${supplierName}${ticketType.tickettypename}`}
                id={`${supplierName}-${ticketType.tickettypecode}-header`}
                sx={{
                  backgroundColor: '#E0EFE9',
                }}
              >
                {ticketType.tickettypename}
              </AccordionSummary>
              <AccordionDetails
                sx={{ display: 'flex', flexDirection: 'column', gap: '20px', padding: '16px' }}
              >
                {ticketType.pricebandsList.map((priceBand) => (
                  <Box
                    key={priceBand.tickettoken}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '1em',
                    }}
                  >
                    {!!priceBand.isonsale && (
                      <Typography
                        sx={{
                          textTransform: 'uppercase',
                          color: 'var(--error-main)',
                          fontWeight: 'bold',
                        }}
                      >
                        {t('general.onSale')}!
                      </Typography>
                    )}
                    {(!!priceBand.onsalestart?.value || !!priceBand.onsaleend?.value) && (
                      <Box sx={{ display: 'flex', gap: '1.5em', alignItems: 'center' }}>
                        {!!priceBand.onsalestart?.value && (
                          <Box sx={{ display: 'flex', gap: '2em' }}>
                            <CalendarMonthIcon
                              sx={{ color: 'var(--primary-main)', alignSelf: 'center' }}
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                              <Typography
                                sx={{
                                  color: 'var(--grey-dark)',
                                  fontSize: '14px',
                                  fontStyle: 'italic',
                                }}
                              >
                                {t('general.saleStarts')}:{' '}
                              </Typography>
                              <Typography>
                                {formatStringYYYYMMDDToUIFormat(priceBand.onsalestart.value)}
                              </Typography>
                            </Box>
                          </Box>
                        )}
                        {!!priceBand.onsaleend?.value && (
                          <>
                            <VerticalDivider />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                              <Typography
                                sx={{
                                  color: 'var(--grey-dark)',
                                  fontSize: '14px',
                                  fontStyle: 'italic',
                                }}
                              >
                                {t('general.saleEnds')}:{' '}
                              </Typography>
                              <Typography>
                                {formatStringYYYYMMDDToUIFormat(priceBand.onsaleend.value)}
                              </Typography>
                            </Box>
                          </>
                        )}
                      </Box>
                    )}
                    {!!priceBand.ticketsavailable?.value && (
                      <Box sx={{ display: 'flex', gap: '6px' }}>
                        <Typography sx={{ fontWeight: 'bold' }}>
                          {t('general.numberOfAvailableTickets')}:{' '}
                        </Typography>
                        <Typography>{priceBand.ticketsavailable?.value}</Typography>
                      </Box>
                    )}
                    {!!priceBand.ticketprice?.value && (
                      <Box sx={{ display: 'flex', gap: '6px' }}>
                        <Typography sx={{ fontWeight: 'bold' }}>
                          {t('general.priceOfTicket')}:{' '}
                        </Typography>
                        <Typography>
                          {priceBand.ticketprice.value} {currencycode}
                        </Typography>
                      </Box>
                    )}
                    {isPriceBandAvailable(priceBand) && (
                      <Box
                        sx={{
                          '.MuiNumberInput-input': {
                            border: errors[ticketType.tickettypecode] ? '1px solid red' : '',
                          },
                          '.MuiNumberInput-root': {
                            justifyContent: 'flex-start',
                            margin: '1em 0',
                          },
                        }}
                      >
                        <Box sx={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
                          <NumberInput
                            min={0}
                            max={
                              priceBand.ticketsavailable?.value !== undefined
                                ? priceBand.ticketsavailable.value
                                : undefined
                            }
                            onChange={(_, newValue: any): void => {
                              handleSelectTicket(newValue, ticketType, priceBand);
                            }}
                            value={selectedTickets[priceBand.tickettoken]?.quantity || 0}
                          />
                        </Box>
                        {!!errors[ticketType.tickettypecode] &&
                          selectedTickets &&
                          selectedTickets[priceBand.tickettoken] &&
                          selectedTickets[priceBand.tickettoken]?.quantity > 0 && (
                            <Typography
                              sx={{
                                margin: '1em 0',
                                color: 'red',
                                fontStyle: 'italic',
                                fontSize: '.9rem',
                              }}
                            >
                              {errors[ticketType.tickettypecode]}
                            </Typography>
                          )}
                      </Box>
                    )}
                  </Box>
                ))}
              </AccordionDetails>
            </Accordion>
          )}
        </>
      ))}

      {Object.values(selectedTickets).length !== 0 && (
        <Box
          sx={{
            marginTop: '2em',
            marginLeft: 'auto',
            width: 'fit-content',
          }}
        >
          <AddToBasketButton
            disabled={!areAllErrorsCleared(errors)}
            handleClick={() => addToBasket()}
            classname="button-basic button-basic-filled"
            buttonStyle={{
              display: 'flex',
              gap: '10px',
              height: '47px',
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default TicketTypeList;
