import {
  SupplierPerformanceAvailability,
  TicketType,
} from '@BookingPlatform/grpc/v1/Entertainment/Entertainment_pb';
import { useMsal } from '@azure/msal-react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import AddToBasketButton from 'shared/components/buttons/AddToBasketButton';
import useCanDispatch from 'shared/hooks/useCanDispatch';
import { PageRoute } from 'shared/interfaces/config.interface';
import utils from 'shared/services/utilities.service';
import { RequestStatus } from 'shared/store/request.reducer';
import { reserveEntertainmentTicket } from 'shared/store/request.thunk';
import { AppDispatch, RootState } from 'shared/store/root.store';
import {
  areAllErrorsCleared,
  convertExtendedItemsToItemToReserve,
  getEventIdFromPath,
  getTicketIndexInSelectedSeatsList,
  getTicketPrice,
  getTicketTypeWithPriceband,
} from 'utils/seatmapUtils';
import SeatingMap from './SeatingMap';
import {
  ExtendedTicketType,
  SeatData,
  SeatMapConfig,
  SelectedTicketsError,
} from './SeatingMap.interface';
import SelectedSeats from './SelectedSeats';

const SeatingMapContainer: FC<{
  supplier: SupplierPerformanceAvailability.AsObject;
}> = ({ supplier }) => {
  const { t } = useTranslation();
  const { accounts, instance } = useMsal();
  const { brandId, salesChannelId } = useSelector((state: RootState) => state.userSettings);
  const {
    value: reserveEntertainmentTicketvalue,
    status: reserveEntertainmentTicketstatus,
    error: reserveEntertainmentTicketerror,
  } = useSelector((state: RootState) => state.reserveEntertainmentTicket);

  const { eventDetails, selectedDay } = useSelector((state: RootState) => state.eventDetailsSlice);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const performanceId = searchParams.get('performance');
  const dispatch = useDispatch<AppDispatch>();

  const location = useLocation();
  const eventId = getEventIdFromPath(location.pathname);

  const canDispatch = useCanDispatch();

  const [selectedSeats, setSelectedSeats] = useState<ExtendedTicketType[]>([]);
  const [errors, setErrors] = useState<SelectedTicketsError>({});

  const updateErrors = (quantity: number, ticketType: TicketType.AsObject) => {
    setErrors((prevErrors) => {
      if (ticketType.validquantitiesList.includes(quantity)) {
        return {
          ...prevErrors,
          [ticketType.tickettypecode]: undefined,
        };
      } else {
        return {
          ...prevErrors,
          [ticketType.tickettypecode]: `${t(
            'general.selectCorrectQuantity',
          )}: ${ticketType.validquantitiesList.join(', ')}.`,
        };
      }
    });
  };

  const handleSeatSelect = (seatData: SeatData) => {
    const ticketTypeWithPriceband = getTicketTypeWithPriceband(
      seatData.ticketToken,
      supplier.availabilitydetails?.tickettypesList,
    );

    if (ticketTypeWithPriceband) {
      setSelectedSeats((prevSelectedSeats) => {
        const ticketIndex = getTicketIndexInSelectedSeatsList(
          prevSelectedSeats,
          seatData.ticketToken,
        );

        // existing ticket category
        if (ticketIndex !== -1) {
          const updatedSeats = [...prevSelectedSeats];
          const ticketItem = updatedSeats[ticketIndex];

          const seatIndex = ticketItem.seatcodesList.findIndex(
            (seat) => seat.seatid === seatData.seatId && seat.presetname === seatData.presetName,
          );

          if (ticketItem && seatIndex === -1) {
            ticketItem.quantity += 1;
            ticketItem.seatcodesList.push({
              seatid: seatData.seatId,
              presetname: seatData.presetName,
            });
            updateErrors(ticketItem.quantity, ticketTypeWithPriceband);
          }

          return updatedSeats;
        } else {
          // new ticket category
          const ticketprice = getTicketPrice(ticketTypeWithPriceband, seatData.priceBandCode);
          const ticketToBeAdded = {
            tickettoken: seatData.ticketToken,
            quantity: 1,
            seatcodesList: [{ seatid: seatData.seatId, presetname: seatData.presetName }],
            price: ticketprice || '',
            tickettype: ticketTypeWithPriceband,
          };
          updateErrors(1, ticketTypeWithPriceband);

          return [...prevSelectedSeats, ticketToBeAdded];
        }
      });
    }
  };

  const handleSeatUnselect = (seatData: SeatData) => {
    const ticketTypeWithPriceband = getTicketTypeWithPriceband(
      seatData.ticketToken,
      supplier.availabilitydetails?.tickettypesList,
    );

    if (ticketTypeWithPriceband) {
      setSelectedSeats((prevSelectedSeats) => {
        const ticketTypeIndex = getTicketIndexInSelectedSeatsList(
          prevSelectedSeats,
          seatData.ticketToken,
        );

        if (ticketTypeIndex !== -1) {
          const updatedSeats = [...prevSelectedSeats];
          const ticketItem = updatedSeats[ticketTypeIndex];

          const seatIndex = ticketItem.seatcodesList.findIndex(
            (seat) => seat.seatid === seatData.seatId && seat.presetname === seatData.presetName,
          );

          if (seatIndex !== -1) {
            if (ticketItem.quantity > 1) {
              if (seatIndex !== -1) {
                ticketItem.seatcodesList.splice(seatIndex, 1);
                ticketItem.quantity -= 1;
              }
              updateErrors(ticketItem.quantity, ticketTypeWithPriceband);

              return updatedSeats;
            } else if (ticketItem.seatcodesList.length === 1) {
              updatedSeats.splice(ticketTypeIndex, 1);
              updateErrors(ticketItem.quantity, ticketTypeWithPriceband);
              return updatedSeats;
            }
            return updatedSeats;
          }
        }

        return prevSelectedSeats;
      });
    }
  };

  useEffect(() => {
    const config: SeatMapConfig = {
      seatPlanData: supplier.availabilitydetails!.seatplandata!.value,
      handleSelectSeat: handleSeatSelect,
      handleUnselectSeat: handleSeatUnselect,
    };
    const chart = new SeatingMap(config);
    chart.initChart();
  });

  const addToBasket = () => {
    if (canDispatch && performanceId) {
      const ticketsToSend = convertExtendedItemsToItemToReserve(selectedSeats);
      dispatch(
        reserveEntertainmentTicket({
          instance,
          account: accounts[0],
          tenantid: accounts[0].tenantId,
          brandid: brandId,
          saleschannelid: salesChannelId,
          performanceid: Number(performanceId),
          ticketsList: ticketsToSend,
          currencycode: utils.getCurrentCurrency(),
          eventId,
          eventname: eventDetails?.eventname || null,
          mainimageurl: eventDetails?.mainimageurl?.value || null,
          selectedDay,
        }),
      );
    }
  };

  useEffect(() => {
    if (
      reserveEntertainmentTicketvalue &&
      reserveEntertainmentTicketstatus === RequestStatus.Idle &&
      !reserveEntertainmentTicketerror
    ) {
      setSelectedSeats([]);
      toast.success(
        <Box sx={{ display: 'flex', '*': { textWrap: 'nowrap' } }}>
          <Typography sx={{ alignSelf: 'center' }}>{t('general.addedToBasket')}</Typography>
          <Button
            onClick={() => navigate(`${PageRoute.Checkout}${PageRoute.Entertainment}`)}
            sx={{ fontStyle: 'italic', mx: '4px' }}
          >
            {t('productDetails.entertainment.goToBasket')}
          </Button>
        </Box>,
        {
          toastId: 'success',
        },
      );
    }
  }, [
    reserveEntertainmentTicketvalue,
    reserveEntertainmentTicketstatus,
    reserveEntertainmentTicketerror,
    t,
    navigate,
  ]);

  useEffect(() => {
    if (
      reserveEntertainmentTicketstatus === RequestStatus.Failed &&
      reserveEntertainmentTicketerror
    )
      toast.error(
        <Box sx={{ display: 'flex', '*': { textWrap: 'nowrap' } }}>
          <Typography sx={{ alignSelf: 'center' }}>
            {reserveEntertainmentTicketerror.message}
          </Typography>
        </Box>,
      );
  }, [reserveEntertainmentTicketstatus, reserveEntertainmentTicketerror, t, navigate]);

  return (
    <Box
      sx={{
        '.MuiAccordionSummary-content.Mui-expanded': {
          margin: '12px 0',
        },
        '.Mui-expanded': {
          minHeight: 'unset',
        },
      }}
    >
      <Accordion
        sx={{
          '&:first-of-type .MuiAccordionSummary-root': {
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
          },
          '&:last-of-type .MuiAccordionSummary-root': {
            borderBottomLeftRadius: '10px',
            borderBottomRightRadius: '10px',
            '&.Mui-expanded': {
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
            },
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            backgroundColor: '#E0EFE9',
          }}
        >
          {t('productDetails.entertainment.seatMapTitle')}
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            padding: '16px',
            '.MuiBox-root+.MuiDivider-root': { marginLeft: 0, marginRight: 0 },
          }}
        >
          <div id="seatMap" style={{ width: '-webkit-fill-available', height: 500 }}></div>

          {selectedSeats.length > 0 && (
            <>
              <SelectedSeats selectedSeats={selectedSeats} errors={errors} />
              <AddToBasketButton
                disabled={!areAllErrorsCleared(errors) || selectedSeats.length <= 0}
                handleClick={addToBasket}
                classname="button-basic button-basic-filled"
                buttonStyle={{ marginLeft: 'auto' }}
              />
            </>
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default SeatingMapContainer;
