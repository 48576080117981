import { ProductDetail } from '@BookingPlatform/grpc/v1/Attraction/Common/ProductDetail_pb';

const sortByField = (array: ProductDetail.AsObject[] | [], sortBy: string) => {
  const field = sortBy.split('-').pop() as string;
  const isDesc = sortBy.includes('-');

  return [...array].sort((a: any, b: any) => {
    const aValue = Number(a[field]?.value || a[field] || 0);
    const bValue = Number(b[field]?.value || b[field] || 0);

    const aRanking = Number(a.ranking?.value || 0);
    const bRanking = Number(b.ranking?.value || 0);

    const aReviewRating = Number(a.reviewrating?.value || 0);
    const bReviewRating = Number(b.reviewrating?.value || 0);

    if (field === 'reviewrating') {
      return (isDesc ? -1 : 1) * (aValue - bValue) || bRanking - aRanking || a.productid - b.productid;
    }
    return (
      (isDesc ? -1 : 1) * (aValue - bValue) ||
      bReviewRating - aReviewRating ||
      b.totalreviews - a.totalreviews ||
      a.productid - b.productidid
    );
  });
};

const sortResults = (value: string, list: ProductDetail.AsObject[] | []) => {
  switch (value) {
    case '1':
      return sortByField(list, '-reviewrating');
    case '2':
      return sortByField(list, 'fromprice');
    case '3':
      return sortByField(list, '-fromprice');
    case '4':
      return sortByField(list, 'durationfromminutes');
    case '5':
      return sortByField(list, '-durationfromminutes');
    default:
      return sortByField(list, '-ranking');
  }
};

export default sortResults;
