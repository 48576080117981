import React from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import ErrorTitle from '../error-title/ErrorTitle';

interface Props {
  children: React.ReactNode;
}

const ErrorFallback = () => {
  const { t } = useTranslation();
  return (
    <div className="container">
      <ErrorTitle title={t('errorBoundary.title')} subtitle={t('errorBoundary.subtitle')} />
    </div>
  );
};

const ErrorBoundary: React.FC<Props> = ({ children }) => {
  return <ReactErrorBoundary FallbackComponent={ErrorFallback}>{children}</ReactErrorBoundary>;
};

export default ErrorBoundary;
