import { useMsal } from '@azure/msal-react';
import { ReactNode, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { preBook } from 'shared/store/request.thunk';
import { AppDispatch, RootState } from 'shared/store/root.store';
import { getAttractionItemInBasket } from 'utils/bookingUtils';

interface PreBookKeepAliveProviderProps {
  children: ReactNode;
}

const PreBookKeepAliveProvider = ({ children }: PreBookKeepAliveProviderProps) => {
  const { accounts, instance } = useMsal();
  const { value: basketItems } = useSelector((state: RootState) => state.bookingBasket);
  const { brandId, salesChannelId } = useSelector((state: RootState) => state.userSettings);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const dispatch = useDispatch<AppDispatch>();

  const timerRefCollection = useRef<Array<NodeJS.Timer | undefined>>([]);

  const attractionBasketItem = useMemo(() => getAttractionItemInBasket(basketItems), [basketItems]);

  const [searchParams] = useSearchParams();
  const optionToken = searchParams.get('optionToken');

  useEffect(() => {
    if (
      basketItems &&
      basketItems.length > 0 &&
      basketItems.length > timerRefCollection.current.length
    ) {
      timerRefCollection.current.push(
        setInterval(() => {
          // TODO: implement in case of itemType === 'entertainmentItem' and 'itemType === 'diningItem'
          if (attractionBasketItem) {
            dispatch(
              preBook({
                instance,
                account: accounts[0],
                optionToken: optionToken as string,
                brandId,
                salesChannelId,
                productId: attractionBasketItem.productId || '',
              }),
            );
          }
        }, Number(process.env.REACT_APP_PREBOOKKEEPALIVE_INTERVAL)),
      );
    }

    if (basketItems?.length === 0) {
      timerRefCollection.current.forEach((timer) => clearInterval(timer));
      timerRefCollection.current = [];
    }
  }, [
    basketItems,
    accounts,
    brandId,
    instance,
    salesChannelId,
    dispatch,
    attractionBasketItem,
    optionToken,
  ]);

  return <>{children}</>;
};

export default PreBookKeepAliveProvider;
