import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'shared/store/root.store';
import BookingConfirmation from './BookingConfirmation';

const EntertainmentBookingConfirmation = () => {
  const { value: basketItems } = useSelector((state: RootState) => state.bookingBasket);

  const eventName = useMemo(
    () => basketItems?.[0]?.entertainmentItem?.eventname ?? '',
    [basketItems],
  );

  return <BookingConfirmation eventName={eventName} />;
};

export default EntertainmentBookingConfirmation;
