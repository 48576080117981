import { ProductDetail } from '@BookingPlatform/grpc/v1/Attraction/Common/ProductDetail_pb';
import { FormControl, InputLabel, MenuItem } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Cookies from 'js-cookie';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ResultListContainer from 'shared/components/search-results/ResultListContainer';
import useThingsToDoInfiniteScroll from 'shared/hooks/useThingsToDoInfiniteScroll';
import utils from 'shared/services/utilities.service';
import requestStoreService from 'shared/store/request.reducer';
import setInitialResults from 'utils/setInitialResults';
import sortResults from 'utils/sortResults';
import ThingsToDoTagSlider from '../search/tag-slider/ThingsToDoTagSlider';
import {
  IFilterForm,
  ListViewType,
  SpecialAttribute,
  specialAttributes,
} from './Results.interface';
import './Results.scss';
import ResultsFilters from './results-filters/ResultsFilters';
import ResultsItemList from './results-item-list/ResultsItemList';
import filterService from './services/filter.service';

const Results = () => {
  const { t } = useTranslation();

  const thingsToDoSearchResults = useSelector(
    requestStoreService.selector('thingsToDoSearchResults'),
  );
  const { selectedTagIds } = useSelector(requestStoreService.selector('searchForm'));

  const orderValue = useRef('0');
  const [listViewType, setListViewType] = useState<ListViewType>(
    (Cookies.get('listView') as ListViewType) || 'normal',
  );
  const [filterLoading, setFilterLoading] = useState(false);
  const [results, setResults] = useState<any[]>([]);
  const [filterForm, setFilterForm] = useState<IFilterForm>();

  const orderChange = (event: SelectChangeEvent) => {
    orderValue.current = event.target.value;
    setResults(sortResults(orderValue.current, results));
  };

  const listViewChange = (event: SelectChangeEvent) => {
    const selectedListViewType: ListViewType = event.target.value as ListViewType;
    Cookies.set('listView', selectedListViewType);
    setListViewType(selectedListViewType);
  };

  const getExcludeSpecials = (resultList: ProductDetail.AsObject[]) => {
    const excludeSpecials: Array<SpecialAttribute> = [];

    specialAttributes.forEach((specialAttribute) => {
      if (resultList.every((item) => !item.attributeidlistList?.includes(specialAttribute.tagId))) {
        excludeSpecials.push(specialAttribute);
      }
    });

    return excludeSpecials;
  };

  const disabledSpecials = useMemo<Array<SpecialAttribute>>(
    () =>
      thingsToDoSearchResults.value?.length
        ? getExcludeSpecials(thingsToDoSearchResults.value)
        : [],
    [thingsToDoSearchResults.value],
  );

  const priceInfo = useMemo(() => {
    if (!thingsToDoSearchResults.value?.length) {
      return { minPrice: 0, maxPrice: 100 };
    }

    const fromPrice = thingsToDoSearchResults.value[0].fromprice?.value;
    const startingMinPrice = fromPrice ? Number(fromPrice) : 0;
    let [minPrice, maxPrice] = [startingMinPrice, 100];
    thingsToDoSearchResults.value.forEach((item: ProductDetail.AsObject) => {
      if (item.fromprice?.value) {
        const fromPrice = Number(item.fromprice?.value);
        minPrice = fromPrice < minPrice ? fromPrice : minPrice;
        maxPrice = fromPrice > maxPrice ? fromPrice : maxPrice;
      }
    });

    return { minPrice: Math.floor(minPrice), maxPrice: Math.ceil(maxPrice) };
  }, [thingsToDoSearchResults.value]);

  useEffect(() => {
    if (!thingsToDoSearchResults.value?.length || !filterForm) {
      setResults(
        sortResults(
          orderValue.current,
          thingsToDoSearchResults?.value ? setInitialResults(thingsToDoSearchResults.value) : [],
        ),
      );
    } else {
      utils
        .asyncFilter(
          [...thingsToDoSearchResults.value],
          (item: ProductDetail.AsObject) =>
            !!item.productname &&
            filterService.ratingFilter(filterForm, item) &&
            filterService.priceFilter(filterForm, item) &&
            filterService.durationFilter(filterForm, item) &&
            filterService.timeOfDayFilter(filterForm, item) &&
            filterService.specialsFilter(filterForm, item),
        )
        .then((products) => {
          setFilterLoading(false);
          setResults(sortResults(orderValue.current, products));
        });
    }
  }, [thingsToDoSearchResults.value, filterForm]);

  const filteredResults = useMemo(
    () =>
      selectedTagIds.size > 0
        ? results.filter((item) =>
            item.attributeidlistList.some((tagId: number) => selectedTagIds.has(tagId)),
          )
        : results,
    [selectedTagIds, results],
  );

  const { items, hasMore, loadMoreRef } = useThingsToDoInfiniteScroll({
    data: filteredResults,
  });

  const debouncedHandleFilterChange = utils.debounce((formValues: IFilterForm) => {
    setFilterLoading(true);
    setFilterForm(formValues);
  }, 200);

  return (
    <div className="search-results">
      <div className="tag-list">
        <ThingsToDoTagSlider />
      </div>
      <div className="filters">
        {/* {priceInfo && specialsInfo && ( */}
        <ResultsFilters
          disabledSpecials={disabledSpecials || []}
          priceInfo={priceInfo}
          currencyCode={results[0]?.currencycode}
          onFilterChange={debouncedHandleFilterChange}
        />
        {/* )} */}
      </div>
      <ResultListContainer>
        <div className="px-4 pb-4 pt-0">
          <div className="results-header px-4 py-3 mb-4">
            <div className="result-number-container">
              <span className="number-of-results">{filteredResults?.length || 0}</span>
              {t('productDetails.thingsToDo.xResults')}
            </div>

            <div className="dropdown-menu">
              <FormControl id="list-view-dropdown" size="small">
                <InputLabel id="list-view-label">
                  {t('productDetails.thingsToDo.listView')}
                </InputLabel>
                <Select
                  id="list-view-selector"
                  labelId="list-view-label"
                  label="List View"
                  color="secondary"
                  onChange={listViewChange}
                  value={listViewType}
                >
                  <MenuItem value="normal">
                    {t('productDetails.thingsToDo.listView.normal')}
                  </MenuItem>
                  <MenuItem value="compact">
                    {t('productDetails.thingsToDo.listView.compact')}
                  </MenuItem>
                  <MenuItem value="dense">{t('productDetails.thingsToDo.listView.dense')}</MenuItem>
                </Select>
              </FormControl>

              <FormControl id="ordering-dropdown" size="small">
                <InputLabel id="ordering-label">
                  {t('productDetails.thingsToDo.ordering')}
                </InputLabel>
                <Select
                  id="ordering-selector"
                  labelId="ordering-label"
                  label="Ordering"
                  color="secondary"
                  onChange={orderChange}
                  value={orderValue.current}
                >
                  <MenuItem value="0">
                    {t('productDetails.thingsToDo.ordering.recommended')}
                  </MenuItem>
                  <MenuItem value="1">{t('productDetails.thingsToDo.ordering.rating')}</MenuItem>
                  <MenuItem value="2">
                    {t('productDetails.thingsToDo.ordering.priceToHigh')}
                  </MenuItem>
                  <MenuItem value="3">
                    {t('productDetails.thingsToDo.ordering.priceToLow')}
                  </MenuItem>
                  <MenuItem value="4">
                    {t('productDetails.thingsToDo.ordering.durationToLong')}
                  </MenuItem>
                  <MenuItem value="5">
                    {t('productDetails.thingsToDo.ordering.durationToShort')}
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>

          <div>
            <ResultsItemList
              listViewType={listViewType}
              items={items}
              filterLoading={filterLoading}
              showError={!filteredResults.length && !filterLoading}
            />
            {hasMore && <div ref={loadMoreRef} />}
          </div>
        </div>
      </ResultListContainer>
    </div>
  );
};

export default Results;
