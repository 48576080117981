import { Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface CountdownProps {
  initialTime: string; // ISO 8601 string
}

const Countdown: React.FC<CountdownProps> = ({ initialTime }) => {
  const { t } = useTranslation();

  const calculateTimeLeft = useCallback(() => {
    const target = new Date(initialTime).getTime();
    const now = new Date().getTime();
    const difference = target - now;

    if (difference <= 0) {
      return null;
    }

    return {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }, [initialTime]);

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [expired, setExpired] = useState(timeLeft === null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newTimeLeft = calculateTimeLeft();
      if (newTimeLeft) {
        setTimeLeft(newTimeLeft);
      } else {
        setExpired(true);
        clearInterval(intervalId);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [calculateTimeLeft]);

  const formatTime = () => {
    if (!timeLeft) return '';

    const { days, hours, minutes, seconds } = timeLeft;
    const parts = [];

    if (days > 0) parts.push(`${days}d`);
    if (hours > 0) parts.push(`${hours}h`);
    if (minutes > 0) parts.push(`${minutes}m`);
    if (seconds > 0) parts.push(`${seconds}s`);

    return parts.join(' ');
  };

  return (
    <Typography sx={{ fontStyle: 'italic' }}>
      {expired
        ? `${t('general.expiredReservation')}`
        : `${t('general.reservationExpiryCountdownText')} ${formatTime()}`}
    </Typography>
  );
};

export default Countdown;
