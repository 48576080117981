import { AutocompleteCategory } from '@BookingPlatform/grpc/v1/Dining/Dining_pb';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { DateTime } from 'luxon';
import { DEFAULT_DINING_SEARCH_FIELDS, DiningSearchFormType } from 'pages/dining/Dining.interface';
import { ChangeEvent, SyntheticEvent, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import SearchButton from 'shared/components/buttons/SearchButton';
import useUserLocale from 'shared/hooks/useUserLocale';
import generateTimeOptions from 'utils/generateTimeOptions';
import { getLocaleCode } from 'utils/localizationResolver';
import DiningSearchField from './DiningSearchField';

export const DiningSearchForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userLocale = useUserLocale();

  const [flexibleCheckboxChecked, setFlexibleCheckboxChecked] = useState(false);

  const menuItems: JSX.Element[] = [];
  for (let i = 2; i <= 20; i++) {
    const menuItem: JSX.Element = (
      <MenuItem key={i} value={i} sx={{ py: '0' }}>
        {i} {t('productDetails.dining.guests')}
      </MenuItem>
    );
    menuItems.push(menuItem);
  }

  const timeOptions: string[] = useMemo(() => generateTimeOptions(), []);

  const {
    setValue,
    getValues,
    control,
    formState: { errors },
    clearErrors,
    setError,
  } = useForm<DiningSearchFormType>({
    defaultValues: DEFAULT_DINING_SEARCH_FIELDS,
  });

  const handleDateChange = (date: dayjs.Dayjs | null) => {
    if (!date) {
      return;
    }
    setValue('date', date);
  };

  const handleTimeChange = (event: SelectChangeEvent<string>) => {
    setValue('time', event.target.value);
  };

  const handleGuestsChange = (event: SelectChangeEvent<number>) => {
    setValue('guests', event.target.value as number);
  };

  const handleFlexibleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFlexibleCheckboxChecked(event.target.checked);
  };

  const submitForm = async (event: SyntheticEvent) => {
    event.preventDefault();
    const { location, venue, date, time, guests } = getValues();
    if (!location) {
      setError('location', {});
    } else if (Object.keys(errors).length === 0) {
      const searchParams = JSON.stringify({
        location: {
          ...location,
          name: encodeURIComponent(location.name),
        },
        venue: {
          ...venue,
          name: encodeURIComponent(venue?.name),
        },
        date: DateTime.fromJSDate(date.toDate()).toFormat('yyyyMMdd'),
        time,
        guests,
      });
      navigate(`/dining/search-results?search=${searchParams}`);
    }
  };

  return (
    <form className="box" onSubmit={submitForm}>
      <Grid
        container
        sx={{
          margin: 0,
          columnGap: '1em',
          rowGap: '0.2em',
          display: 'grid',
          gridTemplateColumns: 'repeat(13, 1fr)',
        }}
      >
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={getLocaleCode(userLocale).toLowerCase()}
        >
          <Grid item sx={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
            <DatePicker
              label={t('productDetails.dining.date')}
              minDate={getValues().date}
              defaultValue={getValues().date}
              value={getValues().date}
              disabled={flexibleCheckboxChecked}
              onChange={handleDateChange}
              sx={{ minWidth: 150 }}
            />
          </Grid>

          <Grid item sx={{ gridColumnStart: 3, gridColumnEnd: 4 }}>
            <FormControl>
              <InputLabel>{t('productDetails.dining.time')}</InputLabel>
              <Controller
                control={control}
                name="time"
                render={({ field: { value, ref } }) => (
                  <Select
                    inputRef={ref}
                    label={t('productDetails.dining.time')}
                    defaultValue={value}
                    value={value}
                    disabled={flexibleCheckboxChecked}
                    onChange={handleTimeChange}
                    MenuProps={{ PaperProps: { sx: { maxHeight: 500 } } }}
                  >
                    {timeOptions.map((time, index) => (
                      <MenuItem key={index} value={time} sx={{ py: '0' }}>
                        {time}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>

          <Grid item sx={{ gridColumnStart: 4, gridColumnEnd: 5 }}>
            <FormControl>
              <InputLabel>{t('productDetails.dining.guests')}</InputLabel>
              <Controller
                control={control}
                name="guests"
                render={({ field: { value, ref } }) => (
                  <Select
                    inputRef={ref}
                    label={t('productDetails.dining.guests')}
                    defaultValue={value}
                    value={value}
                    disabled={flexibleCheckboxChecked}
                    onChange={handleGuestsChange}
                  >
                    <MenuItem value={1} sx={{ py: '0' }}>
                      1 {t('productDetails.dining.guest')}
                    </MenuItem>
                    {menuItems}
                    <MenuItem value={21} sx={{ py: '0' }}>
                      21+ {t('productDetails.dining.guests')}
                    </MenuItem>
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
        </LocalizationProvider>

        <Grid item sx={{ gridColumnStart: 5, gridColumnEnd: 9 }}>
          <DiningSearchField
            id="location"
            label={t('productDetails.dining.searchLocationLabel')}
            placeholder={t('productDetails.dining.searchLocationPlaceholder') + '...'}
            defaultValue={getValues().location}
            error={!!errors.location}
            searchFieldType={AutocompleteCategory.DINING_LOCATION}
            onChange={(event: ChangeEvent<any> | SelectChangeEvent<any>) => {
              setValue('location', event.target.value);
              event.target.name ? clearErrors() : setError('location', {});
            }}
          />
        </Grid>

        <Grid item sx={{ gridColumnStart: 9, gridColumnEnd: 13 }}>
          <DiningSearchField
            id="venue"
            label={t('productDetails.dining.searchVenueLabel')}
            placeholder={t('productDetails.dining.searchVenuePlaceholder') + '...'}
            defaultValue={getValues().venue}
            error={!!errors.venue}
            searchFieldType={AutocompleteCategory.DINING_VENUE}
            onChange={(event: ChangeEvent<any> | SelectChangeEvent<any>) => {
              setValue('venue', event.target.value);
              event.target.name ? clearErrors() : setError('venue', {});
            }}
          />
        </Grid>

        <Grid item sx={{ gridColumnStart: 13 }}>
          <SearchButton sxProps={{ height: '100%' }} />
        </Grid>

        <Grid item sx={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={flexibleCheckboxChecked}
                  onChange={handleFlexibleCheckboxChange}
                />
              }
              label={t('productDetails.dining.flexibleCheckboxLabel')}
            />
          </FormGroup>
        </Grid>
      </Grid>
    </form>
  );
};

export default DiningSearchForm;
