import { ReserveTicketsResponse } from '@BookingPlatform/grpc/v1/Entertainment/Entertainment_pb';
import { BookingQuestionOption } from '@BookingPlatform/grpc/v1/Shared/BookingQuestionOption_pb';
import * as google_protobuf_wrappers_pb from 'google-protobuf/google/protobuf/wrappers_pb';
import { StringValue } from 'google-protobuf/google/protobuf/wrappers_pb';
import { IContactInfo } from 'pages/checkout/contact-info/ContactInfo.interface';
import { AttractionBasketItem, BookingBasketItem } from 'shared/slices/booking-basket.slice';
import { ReservedTicketAdditionalInfo } from 'shared/store/request.thunk';
import { isIAnswer } from './isTypeOf';

export const createBookingAnswer = (
  option: BookingQuestionOption.AsObject,
  resultDisplayName: string,
) => {
  return new BookingQuestionOption()
    .setOptionid(option.optionid)
    .setDisplaydescription(
      option.displaydescription?.value
        ? new google_protobuf_wrappers_pb.StringValue().setValue(option.displaydescription.value)
        : new google_protobuf_wrappers_pb.StringValue(),
    )
    .setDisplayname(resultDisplayName)
    .setFiltervalue(
      option.filtervalue?.value
        ? new google_protobuf_wrappers_pb.StringValue().setValue(option.filtervalue.value)
        : new google_protobuf_wrappers_pb.StringValue(),
    )
    .setAlwaysvisible(option.alwaysvisible)
    .toObject();
};

// Helper function to change from Answer.value format to id: answer format required for contacts structure
export const sortContact = (contact: IContactInfo): IContactInfo => {
  const contactObject: any = JSON.parse(JSON.stringify(contact));
  let sortedContact: any = {};

  for (const [key, value] of Object.entries(contactObject)) {
    let sortedValue: any;
    if (isIAnswer(value)) {
      sortedValue = { answer: value.answer };
    } else if (typeof value === 'object' && 'value' in (value as StringValue.AsObject)) {
      const stringValue = value as StringValue.AsObject;
      sortedValue = { value: stringValue.value };
    } else {
      sortedValue = value;
    }
    sortedContact = { ...sortedContact, [key]: sortedValue };
  }

  if (sortedContact.phone?.number?.length < 10) {
    sortedContact.phone.number = null;
    sortedContact.phone.countrycode = null;
  } else {
    sortedContact.phone.number = sortedContact.phone.number.substring(
      sortedContact.phone.countrycode?.length,
    );
  }

  return sortedContact;
};

// Helper function to change from Answer.value format to value format required for traveller and booking answers structure
export const sortBookingAnswers = (answers: any) => {
  const sortedBookingAnswers: any[] = [];
  answers.forEach((answer: any) => {
    sortedBookingAnswers.push({ questionid: answer.id, ...answer });
  });
  return sortedBookingAnswers;
};

export const getAttractionItemInBasket = (
  basketItems: BookingBasketItem[] | undefined,
): AttractionBasketItem | undefined => {
  return basketItems?.find((item) => item.attractionItem !== undefined)?.attractionItem;
};

export const getEntertainmentItemInBasket = (
  basketItems: BookingBasketItem[] | undefined,
): (ReserveTicketsResponse.AsObject & ReservedTicketAdditionalInfo) | undefined => {
  return basketItems?.find((item) => item.entertainmentItem !== undefined)?.entertainmentItem;
};
