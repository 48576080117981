import { useEffect, useState } from 'react';

function useUserLocale(defaultLocale = 'en-US'): string {
  const [userLocale, setUserLocale] = useState<string>(defaultLocale);

  useEffect(() => {
    setUserLocale(navigator.language || defaultLocale);

    const handleLocaleChange = () => {
      setUserLocale(navigator.language || defaultLocale);
    };

    window.addEventListener('languagechange', handleLocaleChange);

    return () => {
      window.removeEventListener('languagechange', handleLocaleChange);
    };
  }, [defaultLocale]);

  return userLocale;
}

export default useUserLocale;
