import { useMsal } from '@azure/msal-react';
import { Button } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getProduct } from 'shared/store/request.thunk';
import { RootState, useAppDispatch } from 'shared/store/root.store';
import BookingConfirmation from './BookingConfirmation';

const ThingsToDoBookingConfirmation = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { accounts, instance } = useMsal();

  const { bookingDetails } = useSelector((state: RootState) => state.bookingSession);
  const { brandId, salesChannelId, language } = useSelector(
    (state: RootState) => state.userSettings,
  );
  const productStore = useSelector((state: RootState) => state.product);

  const handleGetTicket = () => {
    if (bookingDetails?.value?.bookingcomponentdetailsList[0].voucherurl) {
      window.open(bookingDetails.value.bookingcomponentdetailsList[0].voucherurl.value);
    }
  };

  useEffect(() => {
    if (bookingDetails?.value) {
      dispatch(
        getProduct({
          productId: bookingDetails.value.bookingcomponentdetailsList[0].productid,
          instance,
          account: accounts[0],
          languagecode: language,
          brandId,
          salesChannelId,
        }),
      );
    }
  }, [accounts, dispatch, instance, bookingDetails?.value, brandId, salesChannelId, language]);

  return (
    <BookingConfirmation eventName={productStore?.value?.productname?.value || ''}>
      <Button onClick={handleGetTicket} variant="outlined">
        {t('bookingConfirmationPage.getTicket')}
      </Button>
    </BookingConfirmation>
  );
};

export default ThingsToDoBookingConfirmation;
