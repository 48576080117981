import { Box, Divider, Typography } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'shared/store/root.store';
import 'style/themes/platform.scss';
import TicketSupplier from './TicketSupplier';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const suppliernames = {
  ingresso: 'Ingresso',
  ticketmaster: 'TicketMaster',
  nuticket: 'NuTicket',
  tengroup: 'TenGroup',
};

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const TicketSupplierContainer = () => {
  const { value: supplierList } = useSelector((state: RootState) => state.performanceAvailability);

  const [activeTab, setActiveTab] = React.useState<number>(0);

  const handleChange = (event: React.SyntheticEvent, clickedTab: number) => {
    setActiveTab(clickedTab);
  };

  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <Tabs
        value={activeTab}
        onChange={handleChange}
        TabIndicatorProps={{
          style: {
            display: 'none',
          },
        }}
        sx={{
          '.MuiTabs-scroller ': {
            display: 'flex',
            justifyContent: 'center',
          },
          '& .MuiButtonBase-root': {
            maxHeight: '50px',
            minHeight: 'unset',
          },
        }}
      >
        {supplierList?.map((supplier, index) => (
          <Tab
            label={
              <Typography
                sx={{
                  fontFamily: 'Inter, sans-serif !important;',
                  fontWeight: 'bold',
                  textTransform: 'none',
                  color: index === activeTab ? '#000000 !important' : '#888888',
                  padding: '10px 14px',
                  borderBottom:
                    index === activeTab ? '2px solid var(--primary-main)' : '2px solid transparent',
                  margin: '0 20px',
                }}
              >
                {suppliernames[supplier.suppliername as keyof typeof suppliernames] ||
                  supplier.suppliername}
              </Typography>
            }
            {...a11yProps(index)}
            key={supplier.suppliername}
            sx={{
              padding: 0,
              '.MuiTab-iconWrapper': {
                marginLeft: 0,
              },
            }}
            icon={
              supplierList.length - 1 > index ? (
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ height: '16px', width: '1px', alignSelf: 'center' }}
                />
              ) : undefined
            }
            iconPosition="end"
          />
        ))}
      </Tabs>
      {supplierList?.map((supplier, index) => (
        <CustomTabPanel value={activeTab} index={index} key={supplier.suppliername}>
          <TicketSupplier supplier={supplier} />
        </CustomTabPanel>
      ))}
    </Box>
  );
};

export default TicketSupplierContainer;
