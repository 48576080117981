import { AutocompleteCategory } from '@BookingPlatform/grpc/v1/Dining/Dining_pb';
import { AutoCompleteResultItem } from '@BookingPlatform/grpc/v1/Shared/AutoCompleteResultItem_pb';
import { useMsal } from '@azure/msal-react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CircularProgress from '@mui/material/CircularProgress';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Popper from '@mui/material/Popper';
import TextField from '@mui/material/TextField';
import { ChangeEvent, FC, FocusEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  DiningAutocompleteResultItem,
  MapTypeKeys,
} from 'shared/components/autocomplete-result-item/AutocompleteResultItem';
import { PageRoute } from 'shared/interfaces/config.interface';
import utils from 'shared/services/utilities.service';
import requestStoreService, { RequestStatus } from 'shared/store/request.reducer';
import { getDiningLocations } from 'shared/store/request.thunk';
import { AppDispatch, RootState } from 'shared/store/root.store';
import { mapSearchFieldToDiningType, sliceList } from 'utils/mapToValue';
import { DiningSearchFieldProps, DiningSearchFieldValue } from '../Dining.interface';

const DiningSearchField: FC<DiningSearchFieldProps> = ({
  id,
  label,
  defaultValue,
  placeholder,
  error,
  onChange,
  searchFieldType,
}) => {
  const { t } = useTranslation();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [listLoading, setListLoading] = useState<Record<number, boolean>>({
    [AutocompleteCategory.DINING_LOCATION]: false,
    [AutocompleteCategory.DINING_VENUE]: false,
  });
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [resultsLength, setResultsLength] = useState(1);

  const [inputValue, setInputValue] = useState(defaultValue?.name ?? '');
  const [selectedValue, setSelectedValue] = useState<AutoCompleteResultItem.AsObject | undefined>(
    defaultValue,
  );

  const [resultList, setResultList] = useState<Array<DiningSearchFieldValue>>([]);
  const searchList = useSelector(requestStoreService.selector('diningLocations'));

  const { instance, accounts } = useMsal();
  const { brandId, salesChannelId } = useSelector((state: RootState) => state.userSettings);

  const getList = useCallback(
    (searchString: string) => {
      if (searchString) {
        dispatch(
          getDiningLocations({
            searchString,
            instance,
            account: accounts[0],
            brandId,
            salesChannelId,
          }),
        );
      }
    },
    [dispatch, accounts, instance, brandId, salesChannelId],
  );

  const debouncedGetList = useMemo(() => utils.debounce(getList, 500), [getList]);

  const searchInputChange = (event: any) => {
    const value = event.target.value.trimStart();
    const lengthReached = value.trim().length > 0;

    setResultList([]);

    setInputValue(value);
    if (lengthReached) {
      setListLoading({
        [searchFieldType]: true,
      });
    }
    setDropdownOpen(lengthReached);

    if (lengthReached) {
      debouncedGetList(value.trim());
    }
  };

  useEffect(() => {
    if (searchList.status !== RequestStatus.Loading) {
      setListLoading({
        [searchFieldType]: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchList.status]);

  useEffect(() => {
    setInputValue(defaultValue?.name ?? '');
    setSelectedValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (searchList.value) {
      let diningResultList: DiningSearchFieldValue[] = [];

      const currentCategory = searchList?.value?.categoriesList.find(
        (category) => category.category === searchFieldType,
      );
      searchList?.value?.categoriesList.forEach((item) => {
        if (currentCategory?.category === item.category) {
          diningResultList = mapSearchFieldToDiningType(item.resultsList, searchFieldType);
        }
      });

      const slicedResult = sliceList(diningResultList);
      setResultList(slicedResult);
      setResultsLength(slicedResult.length);
    }
  }, [searchList.value, searchFieldType]);

  const onInputFocus = (event: FocusEvent<HTMLInputElement>) => {
    if (resultsLength <= 1 && selectedValue?.id) {
      getList(inputValue);
    }

    setDropdownOpen(inputValue.length > 2);
    setInitialLoad(false);
    event.target.select();
  };

  const selectListItem = (item: DiningSearchFieldValue) => {
    onChange({ target: { value: item, name: id } } as any as ChangeEvent<any>);
    setDropdownOpen(false);
    setInputValue(item.name);
    setSelectedValue(item);
    if (item.type === 1) {
      // Navigate to product details page
      navigate(`${PageRoute.DiningDetails}/${item.id}`);
    }
  };

  return (
    <ClickAwayListener
      onClickAway={() => {
        setDropdownOpen(false);
        setInputValue(selectedValue?.name ? selectedValue.name : '');
      }}
    >
      <div className="search-wrap">
        <TextField
          sx={{ width: '100%' }}
          id={id}
          label={label}
          placeholder={placeholder}
          error={error}
          autoFocus={!initialLoad}
          helperText={error && !dropdownOpen && t('productDetails.dining.searchError')}
          autoComplete="off"
          onFocus={onInputFocus}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
            }
          }}
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              setDropdownOpen(false);
              const target = e.target as EventTarget & HTMLInputElement;
              setInputValue(selectedValue?.name ?? defaultValue?.name ?? '');
              target.blur();
            }
          }}
          InputProps={{
            endAdornment: listLoading[searchFieldType] && (
              <CircularProgress color="inherit" size={20} />
            ),
          }}
          value={inputValue}
          onChange={searchInputChange}
        />

        <Popper
          className="search-popper"
          id="autocomplete-popper"
          placement="bottom-start"
          open={dropdownOpen}
          anchorEl={document.getElementById(id)}
          disablePortal
        >
          <div className="search-list">
            {!!resultsLength && !listLoading[searchFieldType] && (
              <div className="list">
                {resultList?.map((item) => (
                  <DiningAutocompleteResultItem
                    key={item.id}
                    item={item}
                    type={`dining${item.type}` as MapTypeKeys}
                    selectListItem={selectListItem}
                  />
                ))}
              </div>
            )}

            {listLoading[searchFieldType] && (
              <div className="list-info loading">
                {t('generic.loading')}
                ...
              </div>
            )}
            {!resultsLength && !listLoading[searchFieldType] && (
              <div className="list-info no-results">
                <ErrorOutlineIcon />
                {t('common.search.noResults')}
              </div>
            )}
          </div>
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

export default DiningSearchField;
