import { Button } from '@mui/material';
import { SxProps } from '@mui/system';
import SearchIcon from 'assets/svg-icons/SearchIcon';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const SearchButton: FC<{ sxProps?: SxProps; handleClick?: () => void }> = ({
  sxProps,
  handleClick,
}) => {
  const { t } = useTranslation();

  return (
    <Button
      color="primary"
      variant="contained"
      startIcon={<SearchIcon fontSize="small" />}
      type="submit"
      sx={{
        minWidth: 'fit-content',
        ...sxProps,
      }}
      onClick={handleClick}
    >
      {t('generic.search')}
    </Button>
  );
};

export default SearchButton;
