import { Box, Skeleton, Typography } from '@mui/material';
import { FC } from 'react';
import DotDivider from 'shared/components/divider/DotDivider';
import { ProductDetails } from '../Entertainment.interface';

export const mainImageStyle = {
  borderRadius: '15px',
  objectFit: 'cover',
};

const EntertainmentSearchResultsHeader: FC<ProductDetails> = ({
  imageUrl,
  title,
  additionalInfo,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '15px 30px',
        height: '120px',
        width: '100%',
        '&.MuiBox-root': {
          width: '100%',
          borderBottom: '1px #F2F2F2 solid',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          gap: '30px',
          '.title': {
            fontSize: '25px',
            fontWeight: 'bold',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '100%',
            lineHeight: '30px',
          },
          '.additional-info, span': {
            fontSize: '14px',
            color: '#888888',
          },
          img: { ...mainImageStyle, width: '120px', height: '90px' },
        }}
      >
        {!title && <Skeleton variant="rounded" width={120} height={90} />}
        {title && (
          <>
            {imageUrl && <img src={imageUrl} alt="event" />}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                flexDirection: 'column',
                gap: '10px',
              }}
            >
              <Typography component="p" className="title">
                {title}
              </Typography>
              {additionalInfo && (
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  {additionalInfo.map((info, index) => (
                    <Typography component="p" className="additional-info" key={info}>
                      {info}
                      {index !== additionalInfo.length - 1 && <DotDivider />}
                    </Typography>
                  ))}
                </Box>
              )}
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default EntertainmentSearchResultsHeader;
