import { AutocompleteCategory } from '@BookingPlatform/grpc/v1/Dining/Dining_pb';
import { AutoCompleteResultItem } from '@BookingPlatform/grpc/v1/Shared/AutoCompleteResultItem_pb';
import dayjs from 'dayjs';
import { ChangeEvent } from 'react';

export interface DiningSearchFieldValue extends AutoCompleteResultItem.AsObject {
  type: AutocompleteCategory;
}

export type DiningSearchFieldProps = {
  id: string;
  label: string;
  defaultValue: DiningSearchFieldValue | undefined;
  placeholder: string;
  error: boolean;
  onChange: (data: ChangeEvent<DiningSearchFieldValue>) => void;
  searchFieldType: AutocompleteCategory;
};

export type DiningSearchFormType = {
  location: DiningSearchFieldValue;
  venue: DiningSearchFieldValue;
  date: dayjs.Dayjs;
  time: string;
  guests: number;
};

export const DEFAULT_DINING_SEARCH_FIELDS = {
  location: undefined,
  venue: undefined,
  date: dayjs(new Date()),
  time: '07:00 PM',
  guests: 2,
};
