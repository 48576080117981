import { EntertainmentSearchFormTypes } from 'pages/entertainment/Entertainment.interface';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SearchQuery } from 'shared/components/search-container/Search.interface';

const useGetSearchQuery = () => {
  const [searchParams] = useSearchParams();

  const searchParam = searchParams.get('search');

  const searchQuery = useMemo(() => {
    if (searchParam) {
      const parsedParams = JSON.parse(searchParam);
      if (parsedParams.event || parsedParams.performer || parsedParams.venue) {
        return parsedParams as EntertainmentSearchFormTypes;
      } else {
        return JSON.parse(searchParam) as SearchQuery;
      }
    } else {
      return undefined;
    }
  }, [searchParam]);

  return searchQuery;
};

export default useGetSearchQuery;
