import 'pages/things-to-do/results/SearchResultsPage.scss';
import { FC } from 'react';
import { SearchPropsType } from './Search.interface';
import './SearchContainer.scss';

const SearchContainer: FC<SearchPropsType> = ({ title, subtitle, children }) => {
  return (
    <div className="container search-container pt-4 pb-5">
      <div className="heading mb-5">
        <h1 className="h1">{title}</h1>
        <h2 className="subtitle">{subtitle}</h2>
      </div>

      {children}
    </div>
  );
};

export default SearchContainer;
