import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Box } from '@mui/material';
import { FC } from 'react';
import 'style/themes/platform.scss';

const ErrorBox: FC<{ title: string; description: string }> = ({ title, description }) => {
  const scondaryLightColor = 'var(--secondary-light)';

  return (
    <Box
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        gap: '2em',
        backgroundColor: scondaryLightColor,
        borderRadius: '10px',
        p: '1.4em 2.4em',
        my: '1.4em',
      }}
    >
      <WarningAmberIcon />
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '.4em' }}>
        <h4 className="h4">{title}</h4>
        <p className="m-0">{description}</p>
      </Box>
    </Box>
  );
};

export default ErrorBox;
