import { Typography } from '@mui/material';

const DotDivider = () => {
  return (
    <Typography variant="body1" component="span" style={{ margin: '0 5px' }}>
      &middot;
    </Typography>
  );
};

export default DotDivider;
