import { KeyboardArrowLeft } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useLoginLogout from 'shared/hooks/useLoginLogout';
import './ConfirmLogin.scss';

const ConfirmLogin = () => {
  const { login } = useLoginLogout();

  const navigate = useNavigate();
  return (
    <div className="confirm-login">
      <div className="confirm-login-box">
        <Typography variant="h4">For this page, please renew your login.</Typography>
        <Typography>Would you like to log in again?</Typography>
        <div className="buttons">
          <Button
            variant="outlined"
            onClick={() => navigate(`/`)}
            startIcon={<KeyboardArrowLeft />}
          >
            go back
          </Button>
          <Button variant="contained" onClick={() => login(true)}>
            Login
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmLogin;
