import { Box, Button, ListItem, Typography } from '@mui/material';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useConfigContext } from 'shared/contexts/config.context';
import { PageRoute } from 'shared/interfaces/config.interface';
import { resetBookingBasket } from 'shared/slices/booking-basket.slice';
import { AppDispatch, RootState } from 'shared/store/root.store';
import { getDefaultItem } from '../header/Header';
import EmptyBasket from './EmptyBasket';
import TicketList, { subtitleStyling } from './TicketList';

const BasketDialog: FC<{ setIsBasketVisible: (isVisible: boolean) => void }> = ({
  setIsBasketVisible,
}) => {
  const { value: basketItems } = useSelector((state: RootState) => state.bookingBasket);
  const config = useConfigContext();
  const userSettings = useSelector((state: RootState) => state.userSettings);
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();

  const totalPriceOfBasketItems = useMemo(() => {
    if (basketItems) {
      return basketItems
        .filter((item) => item.entertainmentItem)
        .reduce((total, item) => {
          return total + (Number(item.entertainmentItem?.totalprice?.value) || 0);
        }, 0);
    }
  }, [basketItems]);

  const handleClick = () => {
    navigate(`${PageRoute.Checkout}${PageRoute.Entertainment}`);
    setIsBasketVisible(false);
  };

  const handleEmptyBasket = () => {
    dispatch(resetBookingBasket());
  };

  return (
    <Box>
      {basketItems?.length === 0 && <EmptyBasket />}
      {basketItems && basketItems.length > 0 && (
        <>
          <Typography
            sx={{
              margin: '0',
              padding: '8px 16px 16px 16px',
              fontWeight: 'bold',
              fontSize: '1.2rem',
              textAlign: 'center',
            }}
          >
            {t('productDetails.entertainment.basketTitle')}
          </Typography>
          {basketItems?.map((ticket, index) => {
            if (ticket.entertainmentItem) {
              return <TicketList key={index} entertainmentItem={ticket.entertainmentItem} />;
            } else {
              // TODO: implement for other basketitems, like attraction
              return <React.Fragment key={index}></React.Fragment>;
            }
          })}
          <ListItem
            sx={{
              ...subtitleStyling,
              paddingRight: 0,
              paddingLeft: 0,
              '& .MuiTypography-root': { fontWeight: 'bold' },
            }}
          >
            <Typography>{t('generic.totalPrice')}: </Typography>
            <Typography>
              {userSettings.currency && config.currencyList
                ? `${getDefaultItem(userSettings.currency, config.currencyList)?.iconString} ${
                    totalPriceOfBasketItems ? totalPriceOfBasketItems.toFixed(2) : ''
                  }`
                : ''}
            </Typography>
          </ListItem>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '10px',
              button: { whiteSpace: 'nowrap', margin: 0 },
              marginY: '20px',
            }}
          >
            {!location.pathname.includes(`${PageRoute.Checkout}${PageRoute.Entertainment}`) && (
              <Button
                onClick={handleClick}
                className="button-basic button-basic-filled"
                sx={{ margin: '16px' }}
              >
                {t('productDetails.entertainment.goToBasket')}
              </Button>
            )}
            <Button onClick={handleEmptyBasket} className="button-basic button-basic-outline">
              {t('productDetails.entertainment.clearBasket')}
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default BasketDialog;
