import { EntertainmentPromiseClient } from '@BookingPlatform/grpc/v1/Entertainment/Entertainment_grpc_web_pb';
import {
  GetPerformanceAvailabilitiesRequest,
  GetPerformanceAvailabilitiesResponse,
  SupplierPerformanceAvailability,
} from '@BookingPlatform/grpc/v1/Entertainment/Entertainment_pb';
import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';
import { PayloadAction, SerializedError, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { silentTokenRequest } from 'authConfig';
import utils from 'shared/services/utilities.service';
import { RequestState, RequestStatus } from 'shared/store/request.reducer';
import { apiUrl } from 'shared/store/request.thunk';

type PerformanceAvailabilityState = RequestState<SupplierPerformanceAvailability.AsObject[]>;

const initialState: PerformanceAvailabilityState = {
  value: [],
  error: undefined,
  status: RequestStatus.Null,
};

export const getPerformanceAvailabilities = createAsyncThunk<
  SupplierPerformanceAvailability.AsObject[] | null,
  {
    instance: IPublicClientApplication;
    account: AccountInfo;
    brandId: number;
    salesChannelId: number;
    performanceIdList: number[];
    dispatch: any;
    currency: string;
  }
>(
  'getPerformanceAvailabilities',
  async ({
    instance,
    account,
    brandId,
    salesChannelId,
    performanceIdList,
    dispatch,
    currency,
  }): Promise<GetPerformanceAvailabilitiesResponse.AsObject | SerializedError | any> => {
    const request = new GetPerformanceAvailabilitiesRequest();

    request.setTenantid(account.tenantId);
    request.setBrandid(brandId);
    request.setSaleschannelid(salesChannelId);
    request.setPerformanceidsList(performanceIdList);
    request.setLanguagecode(utils.getCurrentLanguage());
    request.setCurrencycode(currency);

    let token = '';
    try {
      const { accessToken } = await instance.acquireTokenSilent(silentTokenRequest(account));
      token = accessToken;
    } catch (e) {
      dispatch(getPerformanceAvailabilityFailure({ message: 'Error with token' }));
      instance.loginRedirect();
    }

    dispatch(getPerformanceAvailabilityStart());

    const stream = new EntertainmentPromiseClient(apiUrl).getPerformanceAvailabilities(request, {
      Authorization: `Bearer ${token}`,
    });

    let res: GetPerformanceAvailabilitiesResponse.AsObject | SerializedError | null = null;

    stream.on('data', (response: GetPerformanceAvailabilitiesResponse) => {
      dispatch(
        getPerformanceAvailabilitySuccess(
          response.toObject().supplierperformanceavailabilitiesList,
        ),
      );
      res = response.toObject();
    });

    stream.on('end', () => {
      dispatch(getPerformanceAvailabilityFinished());
    });

    stream.on('error', (err) => {
      dispatch(getPerformanceAvailabilityFailure(err as unknown as SerializedError));
      res = err as unknown as SerializedError;
    });

    return new Promise((resolve, reject) => {
      if (res && 'supplierperformanceavailabilitiesList' in res) {
        resolve(res as GetPerformanceAvailabilitiesResponse.AsObject);
      } else {
        reject(res);
      }
    });
  },
);

export const performanceAvailability = createSlice({
  name: 'performance-availability',
  initialState,
  reducers: {
    getPerformanceAvailabilityStart: (state) => {
      state.value = [];
      state.status = RequestStatus.Loading;
      state.error = undefined;
    },
    getPerformanceAvailabilitySuccess: (
      state: PerformanceAvailabilityState,
      action: PayloadAction<SupplierPerformanceAvailability.AsObject[]>,
    ) => {
      if (state.status === RequestStatus.Loading) {
        // not adding supplier if it is already in the store
        const updatedValue = action.payload.reduce((acc, supplier) => {
          const isSupplierExisting = state.value?.some(
            (statesupplier) => statesupplier.suppliername === supplier.suppliername,
          );
          if (!isSupplierExisting) {
            acc.push(supplier);
          }
          return acc;
        }, state.value ?? []);

        state.value = updatedValue;
      }
      state.error = undefined;
    },
    getPerformanceAvailabilityFinished: (state) => {
      state.status = RequestStatus.Idle;
      state.error = undefined;
    },
    getPerformanceAvailabilityFailure: (state, action: PayloadAction<SerializedError>) => {
      state.status = RequestStatus.Failed;
      state.error = action.payload;
    },
  },
});

export const {
  getPerformanceAvailabilityStart,
  getPerformanceAvailabilitySuccess,
  getPerformanceAvailabilityFinished,
  getPerformanceAvailabilityFailure,
} = performanceAvailability.actions;
export default performanceAvailability.reducer;
