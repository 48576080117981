import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import 'shared/components/search-container/SearchContainer.scss';
import { RootState } from 'shared/store/root.store';
import { convertToMilitaryTime } from 'utils/timeUtils';
import './EventDetails.css';
import { SelectButton, SelectedButton } from './SelectButtons';

const Performances = () => {
  const { performancesToDisplay, selectedPerformance } = useSelector(
    (state: RootState) => state.eventDetailsSlice,
  );

  return (
    <Box
      sx={{
        '& .MuiTypography-root': {
          fontFamily: 'Inter, sans-serif !important',
        },
      }}
    >
      {performancesToDisplay.length > 0 &&
        performancesToDisplay.map((performance) => (
          <Box
            key={performance.time}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '10px',
              borderRadius: '5px',
              border:
                selectedPerformance?.performanceid === performance.performanceid
                  ? '1px solid #37726E'
                  : '1px solid transparent',
            }}
          >
            <Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>
              {convertToMilitaryTime(performance.time)}
            </Typography>
            {selectedPerformance?.performanceid === performance.performanceid ? (
              <SelectedButton />
            ) : (
              <SelectButton performance={performance} />
            )}
          </Box>
        ))}
    </Box>
  );
};

export default Performances;
