import { PreBookResponse } from '@BookingPlatform/grpc/v1/BookingFlow_pb';
import { ReserveTicketsResponse } from '@BookingPlatform/grpc/v1/Entertainment/Entertainment_pb';
import { createSlice, Draft, SerializedError } from '@reduxjs/toolkit';
import { RequestState, RequestStatus } from 'shared/store/request.reducer';
import {
  preBook,
  ReservedTicketAdditionalInfo,
  reserveEntertainmentTicket,
} from 'shared/store/request.thunk';

export type AttractionBasketItem = {
  optionToken: string;
  preBookResponse?: PreBookResponse.AsObject;
  error?: SerializedError;
  totalPriceChanged: boolean;
  availabilityChanged: boolean;
  productId: string;
};

export interface BookingBasketItem {
  attractionItem?: AttractionBasketItem | undefined;
  entertainmentItem?: (ReserveTicketsResponse.AsObject & ReservedTicketAdditionalInfo) | undefined;
  diningItem?: any | undefined;
}

type ExtendedBookingBasketState = RequestState<Array<BookingBasketItem>>;

export const initialBookingBasketState: ExtendedBookingBasketState = {
  value: [],
  status: RequestStatus.Null,
  error: undefined,
};

export const bookingBasketSlice = createSlice({
  name: 'booking-basket',
  initialState: initialBookingBasketState,
  reducers: {
    resetBookingBasket: (state) => {
      state.value = [];
      state.status = RequestStatus.Idle;
      state.error = undefined;
    },
    deleteAttractionItemFromBasket: (state) => {
      const index = state.value?.findIndex((item) => item.attractionItem !== undefined);

      if (state.value !== undefined && index !== undefined && index !== -1) {
        state.value[index].attractionItem = undefined;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      // add attraction item to basket
      .addCase(preBook.fulfilled, (state, action) => {
        state.status = RequestStatus.Idle;
        state.error = undefined;
        const preBookResponse = action.payload as Draft<PreBookResponse.AsObject>;

        if (state.value === undefined) {
          // If state.value is undefined, initialize it with an array containing the new item
          state.value = [
            {
              attractionItem: {
                productId: action.meta.arg.productId,
                optionToken: action.meta.arg.optionToken,
                preBookResponse,
                availabilityChanged: false,
                totalPriceChanged: false,
              },
            },
          ];
        } else {
          // If state.value is defined, find the existing attraction item index or -1 if not found
          const existingAttractionIndex: number = state.value.findIndex(
            (item) => item.attractionItem !== undefined,
          );

          if (existingAttractionIndex !== -1) {
            // If attractionItem already exists, overwrite it
            state.value[existingAttractionIndex] = {
              attractionItem: {
                productId: action.meta.arg.productId,
                optionToken: action.meta.arg.optionToken,
                preBookResponse,
                availabilityChanged: false,
                totalPriceChanged: false,
              },
            };
          } else {
            // If attractionItem doesn't exist, push the new item
            state.value.push({
              attractionItem: {
                productId: action.meta.arg.productId,
                optionToken: action.meta.arg.optionToken,
                preBookResponse,
                availabilityChanged: false,
                totalPriceChanged: false,
              },
            });
          }
        }
      })
      .addCase(preBook.pending, (state) => {
        state.status = RequestStatus.Loading;
        state.error = undefined;
      })
      .addCase(preBook.rejected, (state, action) => {
        state.status = RequestStatus.Failed;

        const existingBasketItemIndex = state.value?.findIndex(
          (item) => item.attractionItem?.optionToken === action.meta.arg.optionToken,
        );

        if (existingBasketItemIndex === -1) {
          // new basket item
          state.value?.push({
            attractionItem: {
              productId: action.meta.arg.productId,
              optionToken: action.meta.arg.optionToken,
              error: action.error,
              availabilityChanged: false,
              totalPriceChanged: false,
            },
          });
          // existing basket item
        } else if (state.value !== undefined && existingBasketItemIndex !== undefined) {
          state.value[existingBasketItemIndex] = {
            attractionItem: {
              productId: action.meta.arg.productId,
              optionToken: action.meta.arg.optionToken,
              error: action.error,
              availabilityChanged: true,
              totalPriceChanged: false,
            },
          };
        }
      })
      // add entertainment item to basket
      .addCase(reserveEntertainmentTicket.fulfilled, (state, action) => {
        state.status = RequestStatus.Idle;

        const existingBasketItemIndex = state.value?.findIndex(
          (item) => item.entertainmentItem?.performanceid === action.meta.arg.performanceid,
        );

        if (action.payload) {
          if (existingBasketItemIndex === -1) {
            // new basket item
            state.value?.push({
              entertainmentItem: {
                ...action.payload,
                selectedDay: undefined,
              },
            });
          } else if (state.value !== undefined && existingBasketItemIndex !== undefined) {
            // existing basket item
            state.value[existingBasketItemIndex] = {
              entertainmentItem: {
                ...action.payload,
                selectedDay: undefined,
              },
            };
          }
        }
      })
      .addCase(reserveEntertainmentTicket.pending, (state) => {
        state.status = RequestStatus.Loading;
        state.error = undefined;
      })
      .addCase(reserveEntertainmentTicket.rejected, (state, action) => {
        state.status = RequestStatus.Failed;

        const existingBasketItemIndex = state.value?.findIndex(
          (item) => item.entertainmentItem?.performanceid === action.meta.arg.performanceid,
        );

        if (action.payload && state.value !== undefined && existingBasketItemIndex !== undefined) {
          state.value.splice(existingBasketItemIndex, 1);
        }
      });
  },
});

export const { resetBookingBasket, deleteAttractionItemFromBasket } = bookingBasketSlice.actions;
export default bookingBasketSlice.reducer;
