import { ProductDetail } from '@BookingPlatform/grpc/v1/Attraction/Common/ProductDetail_pb';

export enum FilterFormKey {
  Price = 'price',
  Duration = 'duration',
  Time = 'time',
  Rating = 'rating',
  Specials = 'specials',
}

export enum DurationNames {
  UnderOneHour = 'under-one-hour',
  UpToFourHours = 'up-to-four-hours',
  UpToOneDay = 'up-to-one-day',
  OverOneDay = 'over-one-day',
}

export enum TimeNames {
  SixToTwelve = 'six-to-twelve',
  TwelveToFive = 'twelve-to-five',
  FiveToTwelve = 'five-to-twelve',
}

export type CheckboxInputs = 'duration' | 'time' | 'specials';

export type ListViewType = 'normal' | 'compact' | 'dense';

export interface SpecialAttribute {
  tagId: number;
  name: string;
  displayNameKey: string;
}

export interface CheckboxItem {
  label: string;
  name: string;
  value: boolean;
  disabled?: boolean;
}

export interface FilterPriceInfo {
  minPrice: number;
  maxPrice: number;
}

export interface IResultsFilters {
  onFilterChange: (data: any) => void;
  priceInfo: FilterPriceInfo;
  currencyCode: string;
  disabledSpecials: Array<SpecialAttribute>;
}

export interface IFilterForm {
  price: number[];
  duration: CheckboxItem[];
  time: CheckboxItem[];
  rating: number;
  specials: CheckboxItem[];
}

export interface IResultItemListProps {
  listViewType: ListViewType;
  items: ProductDetail.AsObject[];
  filterLoading: boolean;
  showError: boolean;
}

export interface IResultsItemProps {
  result: ProductDetail.AsObject;
  viewType: ListViewType;
}

export interface ICheckboxGroup {
  list: Array<CheckboxItem>;
  type: CheckboxInputs;
  onChange: (data: any, index: number, type: CheckboxInputs) => void;
}

export const goodForAvoidingCrowds: SpecialAttribute = {
  tagId: Number(process.env.REACT_APP_SPECIALFILTERS_GOODFORAVOIDINGCROWDS),
  name: 'GoodForAvoidingCrowds',
  displayNameKey: 'searchResultsPage.resultFilters.goodForAvoidingCrowds',
};

export const likelyToSellOut: SpecialAttribute = {
  tagId: Number(process.env.REACT_APP_SPECIALFILTERS_LIKELYTOSELLOUT),
  name: 'LikelyToSellOut',
  displayNameKey: 'searchResultsPage.resultFilters.likelyToSellOut',
};

export const freeCancellation: SpecialAttribute = {
  tagId: Number(process.env.REACT_APP_SPECIALFILTERS_FREECANCELLATION),
  name: 'FreeCancellation',
  displayNameKey: 'searchResultsPage.resultFilters.freeCancellation',
};

export const bestValue: SpecialAttribute = {
  tagId: Number(process.env.REACT_APP_SPECIALFILTERS_BESTVALUE),
  name: 'BestValue',
  displayNameKey: 'searchResultsPage.resultFilters.bestValue',
};

export const skipTheLineTickets: SpecialAttribute = {
  tagId: Number(process.env.REACT_APP_SPECIALFILTERS_SKIPTHELINETICKETS),
  name: 'SkipTheLineTickets',
  displayNameKey: 'searchResultsPage.resultFilters.skipTheLineTickets',
};

export const privateTours: SpecialAttribute = {
  tagId: Number(process.env.REACT_APP_SPECIALFILTERS_PRIVATETOURS),
  name: 'PrivateTours',
  displayNameKey: 'searchResultsPage.resultFilters.privateTours',
};

export const specialAttributes: Array<SpecialAttribute> = [
  goodForAvoidingCrowds,
  likelyToSellOut,
  freeCancellation,
  bestValue,
  skipTheLineTickets,
  privateTours,
];
