import { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Currency, IConfig } from 'shared/interfaces/config.interface';
import configService from 'shared/services/config.service';
import {
  setBrandId,
  setCurrency,
  setLanguage,
  setTimezone,
} from 'shared/slices/user-settings.slice';
import { AppDispatch } from 'shared/store/root.store';

const Config = createContext({} as IConfig);

export const ConfigProvider = ({ children }: { children: React.ReactNode }) => {
  const [config, setConfig] = useState({} as IConfig);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    configService.getConfig().then((data) => {
      setConfig(data);
      dispatch(
        setCurrency((localStorage.getItem('currencyCode') as Currency) || data.defaultCurrency),
      );
      dispatch(setLanguage(localStorage.getItem('languageCode') || data.defaultLanguage));
      // tbc
      dispatch(setTimezone(localStorage.getItem('timeZone') || ''));
      dispatch(setBrandId(Number(localStorage.getItem('selectedBrandId')) || -1));
    });
  }, [dispatch]);

  return <Config.Provider value={config}>{children}</Config.Provider>;
};

export const useConfigContext = () => useContext(Config);
