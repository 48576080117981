import { EntertainmentPromiseClient } from '@BookingPlatform/grpc/v1/Entertainment/Entertainment_grpc_web_pb';
import {
  CalendarPerformance,
  EventDetailsRequest,
  EventDetailsResponse,
} from '@BookingPlatform/grpc/v1/Entertainment/Entertainment_pb';
import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { silentTokenRequest } from 'authConfig';
import { toast } from 'react-toastify';
import { RequestState, RequestStatus } from 'shared/store/request.reducer';
import { apiUrl } from 'shared/store/request.thunk';
import { formatDateStringToISO8601DateFormat } from 'utils/dateConverter';

type EventDetailsState = RequestState<EventDetailsResponse.AsObject> & {
  eventDetails: EventDetailsResponse.AsObject | null;
  selectedPerformance: CalendarPerformance.AsObject | null;
  selectedDay: Date | undefined;
  daysWithPerformanceInMonth: string[]; // YYYY-MM-DD
  performancesToDisplay: CalendarPerformance.AsObject[];
};

const initialState: EventDetailsState = {
  value: undefined,
  error: undefined,
  status: RequestStatus.Null,
  eventDetails: null,
  selectedPerformance: null,
  selectedDay: undefined,
  daysWithPerformanceInMonth: [],
  performancesToDisplay: [],
};

export type GetEventDetailsArgs = EventDetailsRequest.AsObject & {
  instance: IPublicClientApplication;
  account: AccountInfo;
};

export const getEventDetails = createAsyncThunk<
  EventDetailsResponse.AsObject | null,
  GetEventDetailsArgs
>('getEventDetails', async (args): Promise<EventDetailsResponse.AsObject | null> => {
  const { tenantid, brandid, saleschannelid, eventid, languagecode, instance, account } =
    args as GetEventDetailsArgs;
  const request = new EventDetailsRequest();

  request.setTenantid(tenantid);
  request.setBrandid(brandid);
  request.setSaleschannelid(saleschannelid);
  request.setLanguagecode(languagecode);
  request.setEventid(eventid);

  let token;
  try {
    const { accessToken } = await instance.acquireTokenSilent(silentTokenRequest(account));
    token = accessToken;
  } catch (e) {
    instance.loginRedirect();
  }

  return (
    await new EntertainmentPromiseClient(apiUrl).eventDetails(request, {
      Authorization: `Bearer ${token}`,
    })
  ).toObject();
});

export const eventDetailsSlice = createSlice({
  name: 'entertainment-search-form',
  initialState,
  reducers: {
    clearSelectedEventDetails: (state) => {
      state.eventDetails = null;
    },
    setSelectedPerformance: (state, action: PayloadAction<CalendarPerformance.AsObject | null>) => {
      state.selectedPerformance = action.payload;
    },
    setSelectedDay: (state, action: PayloadAction<Date>) => {
      state.selectedDay = action.payload;
    },
    getDaysWithPerformance: (state, action: PayloadAction<CalendarPerformance.AsObject[]>) => {
      // payload is performance list
      if (action.payload.length > 0) {
        const set = new Set(
          action.payload.map((performance: CalendarPerformance.AsObject) => performance.date),
        );
        state.daysWithPerformanceInMonth = Array.from(set);
      } else {
        state.daysWithPerformanceInMonth = [];
      }
    },
    getPerformancesToDisplay: (
      state,
      action: PayloadAction<{ date: Date; performancelist: CalendarPerformance.AsObject[] }>,
    ) => {
      if (action.payload.date instanceof Date && action.payload.performancelist.length > 0) {
        const formattedDate = formatDateStringToISO8601DateFormat(action.payload.date);
        const performancesOnDate = action.payload.performancelist.filter(
          (performance) => performance.date === formattedDate,
        );
        state.performancesToDisplay = performancesOnDate;
      } else {
        state.performancesToDisplay = [];
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEventDetails.pending, (state: EventDetailsState) => {
        state.status = RequestStatus.Loading;
        state.error = undefined;
      })
      .addCase(getEventDetails.fulfilled, (state: EventDetailsState, action) => {
        state.status = RequestStatus.Idle;
        state.eventDetails = action.payload;
      })
      .addCase(getEventDetails.rejected, (state: EventDetailsState, action) => {
        state.status = RequestStatus.Failed;
        state.eventDetails = null;
        state.error = action.error;
        if (action.error) {
          toast.error(action.error.message || 'Unknown error occurred!');
          toast.clearWaitingQueue();
        }
      });
  },
});

export const {
  clearSelectedEventDetails,
  setSelectedPerformance,
  setSelectedDay,
  getPerformancesToDisplay,
  getDaysWithPerformance,
} = eventDetailsSlice.actions;
export default eventDetailsSlice.reducer;
