import { GetBrandsAndSalesChannelsForTenantResponse } from '@BookingPlatform/grpc/v1/BookingFlow_pb';
import { useMsal } from '@azure/msal-react';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import { Box } from '@mui/material';
import Badge from '@mui/material/Badge';
import logo from 'assets/images/logo.png';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { useConfigContext } from 'shared/contexts/config.context';
import useLoginLogout from 'shared/hooks/useLoginLogout';
import { IconedListItem, PageRoute } from 'shared/interfaces/config.interface';
import { setBrandId, setCurrency, setLanguage } from 'shared/slices/user-settings.slice';
import requestStoreService from 'shared/store/request.reducer';
import { getBrands } from 'shared/store/request.thunk';
import { AppDispatch, RootState } from 'shared/store/root.store';
import 'style/themes/platform.scss';
import BasketDialog from '../dialog/BasketDialog';
import { VerticalDivider } from '../divider/Divider';
import Flower from '../flower/Flower';
import NavSelector from '../nav-selector/NavSelector';
import './Header.scss';

const mapBrandsToNavItems = (
  brands: GetBrandsAndSalesChannelsForTenantResponse.AsObject,
): Array<IconedListItem<number>> => {
  return brands.brandidsList.map((brand) => ({ value: brand.id, label: brand.name }));
};

export const getDefaultItem = <T,>(defaultValue: T, list: IconedListItem<T>[]) => {
  return list.find((item) => item.value === defaultValue);
};

const Header: FC = () => {
  const { t } = useTranslation();
  const { accounts, instance } = useMsal();
  const { logout } = useLoginLogout();
  const [userName, setUserName] = useState('');
  const [isBasketVisible, setIsBasketVisible] = useState<boolean>(false);
  const { value: basketItems } = useSelector((state: RootState) => state.bookingBasket);

  const config = useConfigContext();
  const location = useLocation();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const dispatch = useDispatch<AppDispatch>();
  const { value: product } = useSelector(requestStoreService.selector('product'));
  const userSettings = useSelector((state: RootState) => state.userSettings);

  const brands = useSelector(requestStoreService.selector('brands'));

  const disabledPages = [
    PageRoute.Checkout,
    PageRoute.BookingDetails,
    PageRoute.BookingCancellation,
    PageRoute.PaymentResult,
  ];

  useEffect(() => {
    setUserName(instance.getAllAccounts()[0].name ?? '');
  }, [instance]);

  const checkSelectorDisabled = () => {
    return disabledPages.some((page) => location.pathname.includes(page));
  };

  const handleNav = (e: any) => {
    if (location.pathname.includes('/checkout')) {
      const answer = window.confirm(
        'Are you sure you wish to navigate away from the current page? All entered information will be lost.',
      );
      if (answer) {
        // TODO: implement this section
      } else {
        e.nativeEvent.stopImmediatePropagation();
        e.preventDefault();
        e.stopPropagation();
      }
    }
  };

  useEffect(() => {
    if (!brands.value) {
      dispatch(getBrands({ instance, account: accounts[0] }));
    }
  }, [instance, accounts, brands.value, dispatch]);

  const totalNumberOfTickets = useMemo(() => {
    return basketItems?.reduce((total, item) => {
      return (
        total +
        (item.entertainmentItem?.reserveditemsList.reduce((subtotal, seat) => {
          return subtotal + seat.quantity;
        }, 0) || 0)
      );
    }, 0);
  }, [basketItems]);

  useEffect(() => {
    setTimeout(() => {
      setIsBasketVisible(false);
    }, 1500);
  }, [totalNumberOfTickets]);

  return (
    <div className="main-header">
      <div className="container">
        <Link onClick={(e) => handleNav(e)} to="/" className="logo-link">
          <img src={logo} className="logo" alt="logo" />
        </Link>

        <div className="nav-menu">
          <NavLink to="/" onClick={(e) => handleNav(e)}>
            {t('header.menu.home')}
          </NavLink>
          <VerticalDivider />
          <NavLink to={PageRoute.ThingsToDo} onClick={(e) => handleNav(e)}>
            {t('header.menu.thingsToDo')}
          </NavLink>
          <VerticalDivider />
          <NavLink to={PageRoute.Entertainment} onClick={(e) => handleNav(e)}>
            {t('header.menu.entertainment')}
          </NavLink>
          <VerticalDivider />
          <NavLink to={PageRoute.Dining} onClick={(e) => handleNav(e)}>
            {t('header.menu.dining')}
          </NavLink>
        </div>

        <Box
          sx={{
            position: 'absolute',
            height: '100%',
            width: 200,
            right: location.pathname !== PageRoute.Home && brands.value ? '25%' : '18%',
          }}
        >
          <Flower />
        </Box>

        <div className="user-menu">
          {location.pathname !== PageRoute.Home && brands.value && (
            <span className="item">
              <NavSelector
                list={mapBrandsToNavItems(brands.value)}
                onChange={(id) => dispatch(setBrandId(id))}
                title={t('generic.brand')}
                disabled={{
                  isDisabled: location.pathname !== PageRoute.ThingsToDo,
                  message: 'header.brandSelectorDisabled',
                  linkText: 'header.menu.thingsToDo',
                  linkUrl: PageRoute.ThingsToDo,
                }}
                outerItemValue={userSettings.brandId}
                defaultItem={getDefaultItem(
                  userSettings.brandId,
                  mapBrandsToNavItems(brands.value),
                )}
              />
            </span>
          )}

          {config?.languageList && (
            <span className="item">
              <NavSelector
                list={config.languageList}
                onChange={(code) => dispatch(setLanguage(code))}
                title={t('generic.language')}
                disabled={{
                  isDisabled: checkSelectorDisabled(),
                  message: 'header.navigationDisabled',
                  linkText: 'header.availabilityCheck',
                  linkUrl: `${PageRoute.ProductDetails}/${product?.productid}`,
                }}
                outerItemValue={userSettings.language}
                defaultItem={getDefaultItem(userSettings.language, config.languageList)}
              />
            </span>
          )}

          {config?.currencyList && (
            <span className="item">
              <NavSelector
                list={config?.currencyList}
                title={t('generic.currency')}
                onChange={(code) => dispatch(setCurrency(code))}
                disabled={{
                  isDisabled: checkSelectorDisabled(),
                  message: 'header.navigationDisabled',
                  linkText: 'header.availabilityCheck',
                  linkUrl: `${PageRoute.ProductDetails}/${product?.productid}`,
                }}
                outerItemValue={userSettings.currency}
                defaultItem={getDefaultItem(userSettings.currency, config.currencyList)}
              />
            </span>
          )}

          <div
            className="item account-dropdown basket-icon"
            onMouseEnter={() => setIsBasketVisible(true)}
            onMouseLeave={() => setIsBasketVisible(false)}
          >
            <Badge badgeContent={totalNumberOfTickets} color="primary">
              <ShoppingBasketIcon
                sx={{
                  color: 'var(--primary-dark)',
                  cursor: 'pointer',
                }}
              />
            </Badge>

            {isBasketVisible && (
              <Box
                sx={{
                  position: 'absolute',
                  top: '100%',
                  right: 0,
                  zIndex: 1,
                  display: 'block',
                  padding: '1em 1.2em',
                  backgroundColor: 'white',
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                  minWidth: '250px',
                  minHeight: '20px',
                  borderRadius: '8px',
                }}
              >
                <BasketDialog setIsBasketVisible={setIsBasketVisible} />
              </Box>
            )}
          </div>

          <div className="item account-dropdown">
            <div className="icon">{userName.slice(0, 2)}</div>

            <div className="dropdown-wrap">
              <div className="dropdown">
                <div className="title">
                  {t('header.menu.hi')}, <span className="name">{userName}</span>!
                </div>
                {!!process.env.REACT_APP_FEAT_USERSETTINGS && (
                  <Link to={'/account'} className="account-item">
                    {t('header.menu.account')} <PersonIcon />
                  </Link>
                )}
                <div className="account-item" onClick={logout}>
                  {t('header.menu.logout')} <LogoutIcon />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
