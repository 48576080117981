import { AttractionPromiseClient } from '@BookingPlatform/grpc/v1/Attraction/Attraction_grpc_web_pb';
import {
  AttractionDetailsRequest,
  AttractionDetailsResponse,
  AutoCompleteSearchRequest,
  AutoCompleteSearchResponse,
  AvailabilityRequest,
  AvailabilityResponse,
  Category,
  GetTopCategoryByLocationRequest,
  ProductAvailabilityRangesRequest,
  ProductAvailabilityRangesResponse,
  SearchRequest,
  TopCategoryItem,
} from '@BookingPlatform/grpc/v1/Attraction/Attraction_pb';
import { ProductDetail } from '@BookingPlatform/grpc/v1/Attraction/Common/ProductDetail_pb';
import { BookingFlowPromiseClient } from '@BookingPlatform/grpc/v1/BookingFlow_grpc_web_pb';
import {
  BookRequest,
  BookResponse,
  BookingDetailsRequest,
  BookingDetailsResponseItem,
  BookingItem,
  BookingSearchRequest,
  BookingSearchResponse,
  BookingValidationRequest,
  BookingValidationResponse,
  CancelRequest,
  CancelResponse,
  CancellationItem,
  CheckBookingSessionStatusRequest,
  CheckBookingSessionStatusResponse,
  CheckoutRequest,
  CheckoutResponse,
  GetBrandsAndSalesChannelsForTenantRequest,
  GetBrandsAndSalesChannelsForTenantResponse,
  PreBookRequest,
  PreBookResponse,
  PreCancelRequest,
  PreCancelResponse,
} from '@BookingPlatform/grpc/v1/BookingFlow_pb';
import { DiningPromiseClient } from '@BookingPlatform/grpc/v1/Dining/Dining_grpc_web_pb';
import {
  AutocompleteCategory,
  AutocompleteRequest,
  AutocompleteResponse,
  VenueDetailsRequest,
  VenueDetailsResponse,
} from '@BookingPlatform/grpc/v1/Dining/Dining_pb';
import { EntertainmentPromiseClient } from '@BookingPlatform/grpc/v1/Entertainment/Entertainment_grpc_web_pb';
import {
  CalendarPerformance,
  AutoCompleteResponse as EntertainmentAutoCompleteResponse,
  AutocompleteCategory as EntertainmentAutocompleteCategory,
  AutocompleteRequest as EntertainmentAutocompleteRequest,
  EventInfoEntity,
  EventInfoRequest,
  GetPerformancesRequest,
  ItemToReserve,
  PerformerInfoEntity,
  PerformerInfoRequest,
  ReserveTicketsRequest,
  ReserveTicketsResponse,
  VenueInfoEntity,
  VenueInfoRequest,
} from '@BookingPlatform/grpc/v1/Entertainment/Entertainment_pb';
import { BookingQuestionAnswer } from '@BookingPlatform/grpc/v1/Shared/BookingQuestionAnswer_pb';
import { ContactInfo } from '@BookingPlatform/grpc/v1/Shared/ContactInfo_pb';
import { GuestConfiguration } from '@BookingPlatform/grpc/v1/Shared/GuestConfiguration_pb';
import { Phone } from '@BookingPlatform/grpc/v1/Shared/Phone_pb';
import { UserPromiseClient } from '@BookingPlatform/grpc/v1/User_grpc_web_pb';
import { CheckUserRequest, CheckUserResponse } from '@BookingPlatform/grpc/v1/User_pb';
import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';
import { Dispatch, createAsyncThunk } from '@reduxjs/toolkit';
import { loginRequest, silentTokenRequest } from 'authConfig';
import { format } from 'date-fns';
import { Int32Value, StringValue } from 'google-protobuf/google/protobuf/wrappers_pb';
import i18next from 'i18next';
import { IContactInfo } from 'pages/checkout/contact-info/ContactInfo.interface';
import { ReservationForm } from 'pages/dashboard/reservations/Reservations.interface';
import { SearchFormType } from 'shared/components/search-container/Search.interface';
import { AsyncThunkOptions } from 'shared/interfaces/config.interface';
import utils from 'shared/services/utilities.service';
import { clearPaymentSession, paymentNotAvailable } from 'shared/slices/payment.slice';
import { convertDateToISOString, formatDateStringToISO8601DateFormat } from 'utils/dateConverter';
import { store } from './root.store';

export const apiUrl = process.env.REACT_APP_BASE_URL as string;

export const getBrands = createAsyncThunk<
  GetBrandsAndSalesChannelsForTenantResponse.AsObject,
  { instance: IPublicClientApplication; account: AccountInfo }
>('brands', async ({ instance, account }) => {
  const request = new GetBrandsAndSalesChannelsForTenantRequest();

  request.setTenantid(account.tenantId);

  let token;
  try {
    const { accessToken } = await instance.acquireTokenSilent(silentTokenRequest(account));
    token = accessToken;
  } catch (e) {
    instance.loginRedirect();
  }

  return (
    await new BookingFlowPromiseClient(apiUrl).getBrandsAndSalesChannelsForTenant(request, {
      Authorization: `Bearer ${token}`,
    })
  ).toObject();
});

export const getBookingDetails = createAsyncThunk<
  BookingDetailsResponseItem.AsObject,
  {
    bookingReference: string;
    instance: IPublicClientApplication;
    account: AccountInfo;
    brandId: number;
    salesChannelId: number;
  }
>('booking-details', async ({ bookingReference, instance, account, brandId, salesChannelId }) => {
  const request = new BookingDetailsRequest();

  request.setBookingreferencesList([bookingReference]);
  request.setSaleschannelid(salesChannelId);
  request.setBrandid(brandId);
  request.setTenantid(account.tenantId);

  let token;
  try {
    const { accessToken } = await instance.acquireTokenSilent(silentTokenRequest(account));
    token = accessToken;
  } catch (e) {
    instance.loginRedirect();
  }

  return (
    await new BookingFlowPromiseClient(apiUrl).bookingDetails(request, {
      Authorization: `Bearer ${token}`,
    })
  ).toObject().bookingdetailsresponseitemsList[0];
});

export const getProduct = createAsyncThunk<
  AttractionDetailsResponse.AsObject,
  {
    productId: number;
    instance: IPublicClientApplication;
    account: AccountInfo;
    languagecode: string;
    brandId: number;
    salesChannelId: number;
  }
>('product', async ({ productId, instance, account, languagecode, brandId, salesChannelId }) => {
  const request = new AttractionDetailsRequest();
  request.setProductid(productId);
  request.setSaleschannelid(salesChannelId);
  request.setBrandid(brandId);
  request.setTenantid(account.tenantId);
  request.setLanguagecode(languagecode);

  let token;
  try {
    const { accessToken } = await instance.acquireTokenSilent(silentTokenRequest(account));
    token = accessToken;
  } catch (e) {
    instance.loginRedirect();
  }

  return (
    await new AttractionPromiseClient(apiUrl).attractionDetails(request, {
      Authorization: `Bearer ${token}`,
    })
  ).toObject();
});

export const getAvailabilityResults = createAsyncThunk<
  AvailabilityResponse.AsObject,
  {
    instance: IPublicClientApplication;
    account: AccountInfo;
    travelDate: string;
    productId: number;
    guestList: Array<GuestConfiguration>;
    brandId: number;
    salesChannelId: number;
  }
>(
  'availability',
  async ({ instance, account, travelDate, productId, guestList, brandId, salesChannelId }) => {
    let token;
    try {
      const { accessToken } = await instance.acquireTokenSilent(silentTokenRequest(account));
      token = accessToken;
    } catch (e) {
      instance.loginRedirect();
    }

    const request = new AvailabilityRequest();

    request.setSaleschannelid(salesChannelId);
    request.setBrandid(brandId);
    request.setTenantid(account.tenantId);
    request.setProductid(productId);
    request.setCurrencycode(utils.getCurrentCurrency());
    request.setGuestsList(guestList);
    request.setLanguagecode(utils.getCurrentLanguage());
    request.setTraveldate(travelDate);

    return (
      await new AttractionPromiseClient(apiUrl).availability(request, {
        Authorization: `Bearer ${token}`,
      })
    ).toObject();
  },
);

export const getAvailabilityRangesResults = createAsyncThunk<
  ProductAvailabilityRangesResponse.AsObject,
  {
    instance: IPublicClientApplication;
    account: AccountInfo;
    productId: number;
    brandId: number;
    salesChannelId: number;
  }
>('availabilityRanges', async ({ instance, account, productId, brandId, salesChannelId }) => {
  let token;
  try {
    const { accessToken } = await instance.acquireTokenSilent(silentTokenRequest(account));
    token = accessToken;
  } catch (e) {
    instance.loginRedirect();
  }

  const request = new ProductAvailabilityRangesRequest();

  request.setSaleschannelid(salesChannelId);
  request.setBrandid(brandId);
  request.setTenantid(account.tenantId);
  request.setProductid(productId);

  return (
    await new AttractionPromiseClient(apiUrl).getAvailableRanges(request, {
      Authorization: `Bearer ${token}`,
    })
  ).toObject();
});

export const getLocations = createAsyncThunk<
  AutoCompleteSearchResponse.AsObject,
  {
    searchString: string;
    instance: IPublicClientApplication;
    account: AccountInfo;
    brandId: number;
    salesChannelId: number;
  }
>('locations', async ({ searchString, instance, account, brandId, salesChannelId }) => {
  const request = new AutoCompleteSearchRequest();

  request.setLanguagecode(utils.getCurrentLanguage());
  request.setSearchstring(searchString);
  request.setSaleschannelid(salesChannelId);
  request.setBrandid(brandId);
  request.setTenantid(account.tenantId);
  request.setCategoriesList([Category.ATTRACTION, Category.ATTRACTION_LOCATION]);

  let token;
  try {
    const { accessToken } = await instance.acquireTokenSilent(silentTokenRequest(account));
    token = accessToken;
  } catch (e) {
    instance.loginRedirect();
  }
  return (
    await new AttractionPromiseClient(apiUrl).autoComplete(request, {
      Authorization: `Bearer ${token}`,
    })
  ).toObject();
});

export const getDiningLocations = createAsyncThunk<
  AutocompleteResponse.AsObject,
  {
    searchString: string;
    instance: IPublicClientApplication;
    account: AccountInfo;
    brandId: number;
    salesChannelId: number;
  }
>('diningLocations', async ({ searchString, instance, account, brandId, salesChannelId }) => {
  const request = new AutocompleteRequest();

  request.setLanguagecode(utils.getCurrentLanguage());
  request.setSearchstring(searchString);
  request.setSaleschannelid(salesChannelId);
  request.setBrandid(brandId);
  request.setTenantid(account.tenantId);
  request.setCategoriesList([
    AutocompleteCategory.DINING_LOCATION,
    AutocompleteCategory.DINING_VENUE,
  ]);
  request.setGeolocation();
  let token;
  try {
    const { accessToken } = await instance.acquireTokenSilent(silentTokenRequest(account));
    token = accessToken;
  } catch (e) {
    instance.loginRedirect();
  }

  return (
    await new DiningPromiseClient(apiUrl).autocomplete(request, {
      Authorization: `Bearer ${token}`,
    })
  ).toObject();
});

export const getDiningVenueDetails = createAsyncThunk<
  VenueDetailsResponse.AsObject,
  VenueDetailsRequest.AsObject & {
    instance: IPublicClientApplication;
    account: AccountInfo;
  }
>('getDiningVenueDetails', async (args): Promise<VenueDetailsResponse.AsObject> => {
  const { tenantid, brandid, saleschannelid, languagecode, venueidsList, instance, account } =
    args as VenueDetailsRequest.AsObject & {
      instance: IPublicClientApplication;
      account: AccountInfo;
    };

  const request = new VenueDetailsRequest();

  request.setTenantid(tenantid);
  request.setBrandid(brandid);
  request.setSaleschannelid(saleschannelid);
  request.setLanguagecode(languagecode);
  request.setVenueidsList(venueidsList);

  let token;
  try {
    const { accessToken } = await instance.acquireTokenSilent(silentTokenRequest(account));
    token = accessToken;
  } catch (e) {
    instance.loginRedirect();
  }

  return (
    await new DiningPromiseClient(apiUrl).venueDetails(request, {
      Authorization: `Bearer ${token}`,
    })
  ).toObject();
});

export const getEntertainmentLocations = createAsyncThunk<
  EntertainmentAutoCompleteResponse.AsObject,
  {
    searchString: string;
    instance: IPublicClientApplication;
    account: AccountInfo;
    brandId: number;
    salesChannelId: number;
  }
>(
  'entertainmentLocations',
  async ({ searchString, instance, account, brandId, salesChannelId }) => {
    const request = new EntertainmentAutocompleteRequest();

    request.setLanguagecode(utils.getCurrentLanguage());
    request.setSearchstring(searchString);
    request.setSaleschannelid(salesChannelId);
    request.setBrandid(brandId);
    request.setTenantid(account.tenantId);
    request.setCategoriesList([EntertainmentAutocompleteCategory.EVENT_LOCATION]);
    request.setGeolocation();
    let token;
    try {
      const { accessToken } = await instance.acquireTokenSilent(silentTokenRequest(account));
      token = accessToken;
    } catch (e) {
      instance.loginRedirect();
    }

    return (
      await new EntertainmentPromiseClient(apiUrl).autocomplete(request, {
        Authorization: `Bearer ${token}`,
      })
    ).toObject();
  },
);

export const getEntertainmentProductsOptions = createAsyncThunk<
  EntertainmentAutoCompleteResponse.AsObject,
  {
    searchString: string;
    instance: IPublicClientApplication;
    account: AccountInfo;
    brandId: number;
    salesChannelId: number;
  }
>(
  'entertainmentProductsOptions',
  async ({ searchString, instance, account, brandId, salesChannelId }) => {
    const request = new EntertainmentAutocompleteRequest();

    request.setLanguagecode(utils.getCurrentLanguage());
    request.setSearchstring(searchString);
    request.setSaleschannelid(salesChannelId);
    request.setBrandid(brandId);
    request.setTenantid(account.tenantId);
    request.setCategoriesList([
      EntertainmentAutocompleteCategory.EVENT,
      EntertainmentAutocompleteCategory.EVENT_PERFORMER,
      EntertainmentAutocompleteCategory.EVENT_VENUE,
    ]);
    request.setGeolocation();
    let token;
    try {
      const { accessToken } = await instance.acquireTokenSilent(silentTokenRequest(account));
      token = accessToken;
    } catch (e) {
      instance.loginRedirect();
    }

    return (
      await new EntertainmentPromiseClient(apiUrl).autocomplete(request, {
        Authorization: `Bearer ${token}`,
      })
    ).toObject();
  },
);

export const getVenueInfo = createAsyncThunk<
  VenueInfoEntity.AsObject[],
  {
    instance: IPublicClientApplication;
    account: AccountInfo;
    brandId: number;
    salesChannelId: number;
    venueidsList: number[];
  }
>('getVenueInfo', async ({ instance, account, brandId, salesChannelId, venueidsList }) => {
  const request = new VenueInfoRequest();

  request.setTenantid(account.tenantId);
  request.setBrandid(brandId);
  request.setSaleschannelid(salesChannelId);
  request.setLanguagecode(utils.getCurrentLanguage());
  request.setVenueidsList([...venueidsList]);

  let token;
  try {
    const { accessToken } = await instance.acquireTokenSilent(silentTokenRequest(account));
    token = accessToken;
  } catch (e) {
    instance.loginRedirect();
  }

  return (
    await new EntertainmentPromiseClient(apiUrl).venueInfo(request, {
      Authorization: `Bearer ${token}`,
    })
  ).toObject().venuesList;
});

export type GetEventInfoParams = {
  instance: IPublicClientApplication;
  account: AccountInfo;
  brandId: number;
  salesChannelId: number;
  eventidsList: number[];
};

export const getEventInfo = createAsyncThunk<EventInfoEntity.AsObject[], GetEventInfoParams>(
  'getEventInfo',
  async ({ instance, account, brandId, salesChannelId, eventidsList }) => {
    const request = new EventInfoRequest();

    request.setTenantid(account.tenantId);
    request.setBrandid(brandId);
    request.setSaleschannelid(salesChannelId);
    request.setLanguagecode(utils.getCurrentLanguage());
    request.setEventidsList([...eventidsList]);

    let token;
    try {
      const { accessToken } = await instance.acquireTokenSilent(silentTokenRequest(account));
      token = accessToken;
    } catch (e) {
      instance.loginRedirect();
    }

    return (
      await new EntertainmentPromiseClient(apiUrl).eventInfo(request, {
        Authorization: `Bearer ${token}`,
      })
    ).toObject().eventsList;
  },
);

export const getPerformerInfo = createAsyncThunk<
  PerformerInfoEntity.AsObject[],
  {
    instance: IPublicClientApplication;
    account: AccountInfo;
    brandId: number;
    salesChannelId: number;
    performeridsList: number[];
  }
>('getPerformerInfo', async ({ instance, account, brandId, salesChannelId, performeridsList }) => {
  const request = new PerformerInfoRequest();

  request.setTenantid(account.tenantId);
  request.setBrandid(brandId);
  request.setSaleschannelid(salesChannelId);
  request.setLanguagecode(utils.getCurrentLanguage());
  request.setPerformeridsList([...performeridsList]);

  let token;
  try {
    const { accessToken } = await instance.acquireTokenSilent(silentTokenRequest(account));
    token = accessToken;
  } catch (e) {
    instance.loginRedirect();
  }

  return (
    await new EntertainmentPromiseClient(apiUrl).performerInfo(request, {
      Authorization: `Bearer ${token}`,
    })
  ).toObject().performersList;
});

export const getPerformances = createAsyncThunk<
  CalendarPerformance.AsObject[],
  GetPerformancesRequest.AsObject & {
    instance: IPublicClientApplication;
    account: AccountInfo;
  }
>('getPerformances', async (args) => {
  const {
    tenantid,
    eventid,
    startdate,
    enddate,
    performanceid,
    account,
    brandid,
    saleschannelid,
    instance,
  } = args as GetPerformancesRequest.AsObject & {
    instance: IPublicClientApplication;
    account: AccountInfo;
  };

  const request = new GetPerformancesRequest();

  request.setEventid(eventid);
  if (startdate) {
    const date = new StringValue();
    date.setValue(startdate.value);
    request.setStartdate(date);
  }
  if (enddate) {
    const date = new StringValue();
    date.setValue(enddate.value);
    request.setEnddate(date);
  }
  if (performanceid) {
    const performance = new Int32Value();
    performance.setValue(performanceid.value);
    request.setPerformanceid(performance);
  }

  request.setSaleschannelid(saleschannelid);
  request.setBrandid(brandid);
  request.setTenantid(tenantid);

  let token;
  try {
    const { accessToken } = await instance.acquireTokenSilent(silentTokenRequest(account));
    token = accessToken;
  } catch (e) {
    instance.loginRedirect();
  }

  return (
    await new EntertainmentPromiseClient(apiUrl).getPerformances(request, {
      Authorization: `Bearer ${token}`,
    })
  ).toObject().performancesList;
});

interface AttractionSearchApiCallArgs {
  searchForm: SearchFormType;
  instance: IPublicClientApplication;
  account: AccountInfo;
  brandId: number;
  salesChannelId: number;
}

interface AttractionSearchClearArgs {
  isClear: boolean;
}

export type ReservedTicketAdditionalInfo = {
  eventId: string | null;
  performanceid: number;
  eventname: string | null;
  mainimageurl: string | null;
  selectedDay: Date | undefined;
};

export const reserveEntertainmentTicket = createAsyncThunk<
  (ReserveTicketsResponse.AsObject & ReservedTicketAdditionalInfo) | null,
  ReserveTicketsRequest.AsObject &
    ReservedTicketAdditionalInfo & {
      instance: IPublicClientApplication;
      account: AccountInfo;
    }
>(
  'reserveEntertainmentTicket',
  async (args): Promise<ReserveTicketsResponse.AsObject & ReservedTicketAdditionalInfo> => {
    const {
      brandid,
      saleschannelid,
      performanceid,
      ticketsList,
      currencycode,
      ticketsendmethod,
      instance,
      account,
      eventId,
      eventname,
      mainimageurl,
      selectedDay,
    } = args as ReserveTicketsRequest.AsObject & {
      instance: IPublicClientApplication;
      account: AccountInfo;
      eventId: string | null;
      performanceid: number;
      eventname: string | null;
      mainimageurl: string | null;
      selectedDay: any;
    };
    const request = new ReserveTicketsRequest();

    const mappedTicketsList = ticketsList.map((item) => {
      const itemToReserve = new ItemToReserve();
      itemToReserve.setTickettoken(item.tickettoken);
      itemToReserve.setQuantity(item.quantity);
      itemToReserve.setSeatcodesList(item.seatcodesList);
      return itemToReserve;
    });

    request.setTenantid(account.tenantId);
    request.setBrandid(brandid);
    request.setSaleschannelid(saleschannelid);
    request.setPerformanceid(performanceid);
    request.setTicketsList(mappedTicketsList);
    const languageCode = new StringValue();
    languageCode.setValue(utils.getCurrentLanguage());
    request.setLanguagecode(languageCode);
    request.setCurrencycode(currencycode);
    if (ticketsendmethod) {
      const stringticketsendmethod = new StringValue();
      stringticketsendmethod.setValue(ticketsendmethod.value);
      request.setTicketsendmethod(stringticketsendmethod);
    }

    let token;
    try {
      const { accessToken } = await instance.acquireTokenSilent(silentTokenRequest(account));
      token = accessToken;
    } catch (e) {
      instance.loginRedirect();
    }

    return {
      ...(
        await new EntertainmentPromiseClient(apiUrl).reserveTickets(request, {
          Authorization: `Bearer ${token}`,
        })
      ).toObject(),
      eventId,
      performanceid,
      eventname,
      mainimageurl,
      selectedDay,
    };
  },
);

export const attractionProductSearch = createAsyncThunk<
  ProductDetail.AsObject[] | null,
  AttractionSearchApiCallArgs | AttractionSearchClearArgs,
  AsyncThunkOptions
>('attractionProductSearch', async (args, options) => {
  if ((args as AttractionSearchClearArgs).isClear) {
    return null;
  }

  const { searchForm, account, instance, brandId, salesChannelId } =
    args as AttractionSearchApiCallArgs;

  const request = new SearchRequest();

  const searchType = searchForm.location?.type;
  const searchId = searchForm.location?.id;

  // TODO: Get values dynamically once BE can handle it
  request.setLanguagecode(utils.getCurrentLanguage());
  request.setCurrencycode(utils.getCurrentCurrency());

  if (searchType === Category.ATTRACTION && searchId) {
    request.setProductidsList([searchId]);
  }

  if (searchType === Category.ATTRACTION_LOCATION && searchId) {
    request.setLocationid(new Int32Value().setValue(searchId));
  }

  request.setSaleschannelid(salesChannelId);
  request.setBrandid(brandId);
  request.setTenantid(account.tenantId);

  // TODO: Add back the date when BE is ready
  request.setStartdate(formatDateStringToISO8601DateFormat(searchForm.date.startDate));
  request.setEnddate(formatDateStringToISO8601DateFormat(searchForm.date.endDate));

  let token;

  try {
    const { accessToken } = await instance.acquireTokenSilent(silentTokenRequest(account));
    token = accessToken;
  } catch (e) {
    instance.loginRedirect(loginRequest);
  }

  return (
    await new AttractionPromiseClient(apiUrl).search(request, { Authorization: `Bearer ${token}` })
  ).toObject().resultsList;
});

export const getReservations = createAsyncThunk<
  BookingSearchResponse.AsObject,
  {
    formData: ReservationForm;
    instance: IPublicClientApplication;
    account: AccountInfo;
  }
  // TODO: Refactor this function to reduce its Cognitive Complexity from 30 to the 15 allowed. [+14 locations]sonarlint(typescript:S3776)
>('reservations', async ({ formData, instance, account }) => {
  const request = new BookingSearchRequest();

  request.setTenantid(account.tenantId);

  if (formData.salesChannelId && formData.salesChannelId !== 'All') {
    const salesChannelIdAsInt32 = new Int32Value();
    salesChannelIdAsInt32.setValue(Number(formData.salesChannelId));
    request.setSaleschannelid(salesChannelIdAsInt32);
  }

  if (formData.brandId && formData.brandId !== 'All') {
    const brandIdAsInt32 = new Int32Value();
    brandIdAsInt32.setValue(Number(formData.brandId));
    request.setBrandid(brandIdAsInt32);
  }

  if (formData.reference) {
    const bookingReferenceAsStringValue = new StringValue();
    bookingReferenceAsStringValue.setValue(formData.reference);

    request.setBookingreference(bookingReferenceAsStringValue);
  }

  if (formData.partnerReference) {
    const bookingPartnerReferenceAsStringValue = new StringValue();
    bookingPartnerReferenceAsStringValue.setValue(formData.partnerReference);

    request.setPartnerbookingreference(bookingPartnerReferenceAsStringValue);
  }

  if (formData.customerFirstName) {
    const customerFirstNameAsStringValue = new StringValue();
    customerFirstNameAsStringValue.setValue(formData.customerFirstName);

    request.setCustomerfirstname(customerFirstNameAsStringValue);
  }

  if (formData.customerLastName) {
    const customerLastNameAsStringValue = new StringValue();
    customerLastNameAsStringValue.setValue(formData.customerLastName);

    request.setCustomerlastname(customerLastNameAsStringValue);
  }

  if (formData.createdBy) {
    const createdByAsStringValue = new StringValue();
    createdByAsStringValue.setValue(formData.createdBy);

    request.setCreatedby(createdByAsStringValue);
  }

  if (formData['travel-date']?.startDate) {
    const travelDateFromAsString = new StringValue();
    const travelDateFrom = convertDateToISOString(formData['travel-date'].startDate);

    if (travelDateFrom) {
      travelDateFromAsString.setValue(travelDateFrom);
    }

    request.setStartdatefrom(travelDateFromAsString);
  }

  if (formData['travel-date']?.endDate) {
    const travelDateToAsString = new StringValue();
    const travelDateTo = convertDateToISOString(formData['travel-date'].endDate);

    if (travelDateTo) {
      travelDateToAsString.setValue(travelDateTo);
    }

    request.setStartdateto(travelDateToAsString);
  }

  if (formData['booking-date']?.startDate) {
    const bookingDateFromAsString = new StringValue();
    const bookingDateFrom = convertDateToISOString(formData['booking-date'].startDate);

    if (bookingDateFrom) {
      bookingDateFromAsString.setValue(bookingDateFrom);
      request.setBookingdatefrom(bookingDateFromAsString);
    }
  }

  if (formData['booking-date']?.endDate) {
    const bookingDateToAsString = new StringValue();
    const bookingDateTo = convertDateToISOString(formData['booking-date'].endDate);

    if (bookingDateTo) {
      bookingDateToAsString.setValue(bookingDateTo);
      request.setBookingdateto(bookingDateToAsString);
    }
  }

  if (formData.status && formData.status !== 'All') {
    const statusAsString = new StringValue();
    statusAsString.setValue(formData.status);

    request.setBookingstatus(statusAsString);
  }

  if (formData.bookingType && formData.bookingType !== 'All') {
    const bookingTypeAsString = new StringValue();
    bookingTypeAsString.setValue(formData.bookingType);

    request.setComponenttype(bookingTypeAsString);
  }

  let token;
  try {
    const { accessToken } = await instance.acquireTokenSilent(silentTokenRequest(account));
    token = accessToken;
  } catch (e) {
    instance.loginRedirect();
  }
  return (
    await new BookingFlowPromiseClient(apiUrl).bookingSearch(request, {
      Authorization: `Bearer ${token}`,
    })
  ).toObject();
});

// ⬇️ Mock requests
// ****************
export const mockProductSearch = createAsyncThunk<ProductDetail.AsObject[], any>(
  'product',
  async (_formData) => {
    const mockPromise: Promise<ProductDetail.AsObject[]> = fetch('/mocks/search-results.json').then(
      (data) => data.json(),
    );

    await utils.delay(2000);

    return mockPromise;
  },
);

export const getMockAvailabilityResults = createAsyncThunk('mockAvailability', async () => {
  const mockPromise: Promise<AvailabilityResponse.AsObject> = fetch(
    '/mocks/availability.json',
  ).then((data) => data.json());

  await utils.delay(1000);

  // Dummy check for demo
  // if (travellers[0].guesttypenumber > 1) {
  //   throw new Error('Error!');
  // }

  return mockPromise;
});

export const getMockReservations = createAsyncThunk(
  'mock-reservations',
  async (formData?: ReservationForm) => {
    const mockPromise: Promise<Array<any>> = fetch('/mocks/reservations.json').then((data) => {
      if (formData?.reference) {
        throw new Error(i18next.t('reservation.error'));
      }

      return data.json();
    });

    await utils.delay(500);

    return mockPromise;
  },
);

export interface TagBase {
  attributeid: number;
  attributename: string;
  imageurl: string;
}

export interface Tag extends TagBase {
  sortorder: number;
  productCount?: number;
}

export const getTags = createAsyncThunk<
  TopCategoryItem.AsObject[],
  {
    locationid: number;
    instance: IPublicClientApplication;
    account: AccountInfo;
    languagecode: string;
    brandId: number;
    salesChannelId: number;
  }
>('tags', async ({ locationid, account, instance, languagecode, brandId, salesChannelId }) => {
  const request = new GetTopCategoryByLocationRequest();

  request.setLocationid(locationid);
  request.setLanguagecode(languagecode);
  request.setBrandid(brandId);
  request.setSaleschannelid(salesChannelId);
  request.setTenantid(account.tenantId);

  let token;
  try {
    const { accessToken } = await instance.acquireTokenSilent(silentTokenRequest(account));
    token = accessToken;
  } catch (e) {
    instance.loginRedirect();
  }

  return (
    await new AttractionPromiseClient(apiUrl).getTopCategoryByLocation(request, {
      Authorization: `Bearer ${token}`,
    })
  ).toObject().topcategoriesList;
});

export const downloadMockReservations = createAsyncThunk('reservations-download', async () => {
  const download = fetch('/mocks/reservations.json')
    .then((res) => res.blob())
    .then((data) => {
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(data);
      a.download = `reservation-list-${format(new Date(), 'yyyy-MM-dd')}`;
      a.click();
    });

  await utils.delay(200);

  return download;
});

export interface AccountResponseItem {
  name: string;
  value: string;
  enabled: boolean;
}

export const getMockAccount = createAsyncThunk('mock-account', async () => {
  const mockPromise: Promise<Array<AccountResponseItem>> = fetch('/mocks/account.json').then(
    (data) => data.json(),
  );

  await utils.delay(200);

  return mockPromise;
});

export interface HighSecurityResponse {
  isValidForHighSecurity: boolean;
}

export interface HighSecurityRequest {
  isClear: boolean;
}

export const getMockHighSecurity = createAsyncThunk(
  'mock-high-security',
  async (request?: HighSecurityRequest) => {
    const mockPromise: Promise<HighSecurityResponse> = Promise.resolve({
      isValidForHighSecurity: false,
    });

    await utils.delay(500);

    return request?.isClear ? null : mockPromise;
  },
);

export const postPaymentSession =
  ({ instance, account }: { instance: IPublicClientApplication; account: AccountInfo }) =>
  async (dispatch: Dispatch, getState: typeof store.getState) => {
    dispatch(clearPaymentSession());
    dispatch(paymentNotAvailable());
  };

export type MockPaymentStatusType = 'success' | 'failed' | 'pending';

interface PaymentStatusRequest {
  // simple mock statuses, tbd
  status: MockPaymentStatusType;
}

export const getMockPaymentStatus = createAsyncThunk(
  'mock-payment-status',
  async (request: PaymentStatusRequest) => {
    const mockPromise: Promise<PaymentStatusRequest> = Promise.resolve({ status: request.status });

    await utils.delay(500);

    return mockPromise;
  },
);

export const checkout = createAsyncThunk<
  CheckoutResponse.AsObject,
  {
    instance: IPublicClientApplication;
    account: AccountInfo;
    itemTokens: string[];
    brandId: number;
    salesChannelId: number;
  }
>('checkout', async ({ instance, account, itemTokens, brandId, salesChannelId }) => {
  const request = new CheckoutRequest();

  request.setBrandid(brandId);
  request.setSaleschannelid(salesChannelId);
  request.setTenantid(account.tenantId);
  request.setOptionbooktokensList(itemTokens);

  let token;
  try {
    const { accessToken } = await instance.acquireTokenSilent(silentTokenRequest(account));
    token = accessToken;
  } catch (e) {
    instance.loginRedirect();
  }

  return (
    await new BookingFlowPromiseClient(apiUrl).checkout(request, {
      Authorization: `Bearer ${token}`,
    })
  ).toObject();
});

export const validate = createAsyncThunk<
  BookingValidationResponse.AsObject,
  {
    instance: IPublicClientApplication;
    account: AccountInfo;
    itemTokens: string[];
    contactInfo: IContactInfo;
    bookingQuestionAnswers: BookingQuestionAnswer.AsObject[];
    brandId: number;
    salesChannelId: number;
  }
>(
  'validate',
  async ({
    instance,
    account,
    itemTokens,
    contactInfo,
    bookingQuestionAnswers,
    brandId,
    salesChannelId,
  }) => {
    const request = new BookingValidationRequest();
    const contact = new ContactInfo();
    const phone = new Phone();
    const answers: any[] = [];

    bookingQuestionAnswers.forEach((element) => {
      const answer = new BookingQuestionAnswer();
      answer.setQuestionid(element.questionid);
      answer.setAnswer(element.answer);
      element.guesttype && answer.setGuesttype(element.guesttype);

      if (element.unit) {
        const string = new StringValue();
        string.setValue(element.unit.value);
        answer.setUnit(string);
      }
      if (element.guestnum) {
        const string = new Int32Value();
        string.setValue(element.guestnum.value);
        answer.setGuestnum(string);
      }
      answers.push(answer);
    });

    phone.setCountrycode(contactInfo.phone?.countrycode || '');
    phone.setNumber(contactInfo.phone?.number || '');
    contact.setPhone(phone);
    contact.setEmail(contactInfo.email.answer);
    contact.setFirstname(contactInfo.firstname.answer);
    contact.setLastname(contactInfo.lastname.answer);
    request.setTenantid(account.tenantId);
    request.setBrandid(brandId);
    request.setSaleschannelid(salesChannelId);
    request.setOptiontoken(itemTokens[0]);
    request.setContactinfo(contact);
    request.setBookingquestionanswersList(answers);

    let token;
    try {
      const { accessToken } = await instance.acquireTokenSilent(silentTokenRequest(account));
      token = accessToken;
    } catch (e) {
      instance.loginRedirect();
    }

    return (
      await new BookingFlowPromiseClient(apiUrl).validateBooking(request, {
        Authorization: `Bearer ${token}`,
      })
    ).toObject();
  },
);

export const preBook = createAsyncThunk<
  PreBookResponse.AsObject,
  {
    instance: IPublicClientApplication;
    account: AccountInfo;
    optionToken: string;
    brandId: number;
    salesChannelId: number;
    productId: string;
  }
>('preBook', async ({ instance, account, optionToken, brandId, salesChannelId }) => {
  const request = new PreBookRequest();

  request.setBrandid(brandId);
  request.setSaleschannelid(salesChannelId);
  request.setTenantid(account.tenantId);
  request.setOptiontoken(optionToken);

  let token;
  try {
    const { accessToken } = await instance.acquireTokenSilent(silentTokenRequest(account));
    token = accessToken;
  } catch (e) {
    instance.loginRedirect();
  }

  return (
    await new BookingFlowPromiseClient(apiUrl).prebook(request, {
      Authorization: `Bearer ${token}`,
    })
  ).toObject();
});

export const book = createAsyncThunk<
  BookResponse.AsObject,
  {
    instance: IPublicClientApplication;
    account: AccountInfo;
    partnerBookingReference: string | undefined;
    contactInfo: IContactInfo;
    bookingQuestionAnswers: BookingQuestionAnswer.AsObject[];
    itemTokens: string[];
    brandId: number;
    salesChannelId: number;
  }
>(
  'book',
  async ({
    instance,
    account,
    partnerBookingReference,
    contactInfo,
    bookingQuestionAnswers,
    itemTokens,
    brandId,
    salesChannelId,
  }) => {
    const request = new BookRequest();

    const bookingItem = new BookingItem();
    const contact = new ContactInfo();
    const phone = new Phone();

    const answers: any[] = [];

    bookingQuestionAnswers.forEach((element) => {
      const answer = new BookingQuestionAnswer();
      answer.setQuestionid(element.questionid);
      answer.setAnswer(element.answer);
      element.guesttype && answer.setGuesttype(element.guesttype);

      if (element.unit) {
        const string = new StringValue();
        string.setValue(element.unit.value);
        answer.setUnit(string);
      }
      if (element.guestnum) {
        const string = new Int32Value();
        string.setValue(element.guestnum.value);
        answer.setGuestnum(string);
      }
      answers.push(answer);
    });

    bookingItem.setBookingquestionanswersList(answers);
    bookingItem.setOptionbooktoken(itemTokens[0]);

    phone.setCountrycode(contactInfo.phone?.countrycode || '');
    phone.setNumber(contactInfo.phone?.number || '');
    contact.setPhone(phone);
    contact.setEmail(contactInfo.email.answer);
    contact.setFirstname(contactInfo.firstname.answer);
    contact.setLastname(contactInfo.lastname.answer);

    if (contactInfo.address.value) {
      const addressString = new StringValue().setValue(contactInfo.address.value);
      contact.setAddress(addressString);
    }

    if (contactInfo.zipCode.value) {
      const zipCodeString = new StringValue().setValue(contactInfo.zipCode.value);
      contact.setZipcode(zipCodeString);
    }

    if (contactInfo.country.value) {
      const countryString = new StringValue().setValue(contactInfo.country.value);
      contact.setCountry(countryString);
    }

    if (contactInfo.city.value) {
      const cityString = new StringValue().setValue(contactInfo.city.value);
      contact.setCity(cityString);
    }

    request.setBrandid(brandId);
    request.setSaleschannelid(salesChannelId);
    request.setTenantid(account.tenantId);
    request.setBookingitemsList([bookingItem]);
    request.setContactinfo(contact);

    if (partnerBookingReference !== undefined) {
      const bookingRefStringValue = new StringValue();
      bookingRefStringValue.setValue(partnerBookingReference);
      request.setPartnerbookingreference(bookingRefStringValue);
    }

    let token;
    try {
      const { accessToken } = await instance.acquireTokenSilent(silentTokenRequest(account));
      token = accessToken;
    } catch (e) {
      instance.loginRedirect();
    }

    return (
      await new BookingFlowPromiseClient(apiUrl).book(request, {
        Authorization: `Bearer ${token}`,
      })
    ).toObject();
  },
);

export const checkBookingSessionStatus = createAsyncThunk<
  CheckBookingSessionStatusResponse.AsObject,
  {
    bookingSessionId: string;
    instance: IPublicClientApplication;
    account: AccountInfo;
    brandId: number;
    salesChannelId: number;
  }
>('bookingSession', async ({ instance, account, bookingSessionId, brandId, salesChannelId }) => {
  const request = new CheckBookingSessionStatusRequest();

  request.setBrandid(brandId);
  request.setSaleschannelid(salesChannelId);
  request.setTenantid(account.tenantId);
  request.setBookingsessionid(bookingSessionId);

  let token;
  try {
    const { accessToken } = await instance.acquireTokenSilent(silentTokenRequest(account));
    token = accessToken;
  } catch (e) {
    instance.loginRedirect();
  }

  return (
    await new BookingFlowPromiseClient(apiUrl).checkBookingSessionStatus(request, {
      Authorization: `Bearer ${token}`,
    })
  ).toObject();
});

export const preCancel = createAsyncThunk<
  PreCancelResponse.AsObject,
  {
    bookingReference: string;
    instance: IPublicClientApplication;
    account: AccountInfo;
    brandId: number;
    salesChannelId: number;
  }
>('preCancel', async ({ instance, account, bookingReference, brandId, salesChannelId }) => {
  const request = new PreCancelRequest();

  request.setBrandid(brandId);
  request.setSaleschannelid(salesChannelId);
  request.setTenantid(account.tenantId);
  request.setBookingreference(bookingReference);

  let token;
  try {
    const { accessToken } = await instance.acquireTokenSilent(silentTokenRequest(account));
    token = accessToken;
  } catch (e) {
    instance.loginRedirect();
  }

  return (
    await new BookingFlowPromiseClient(apiUrl).preCancel(request, {
      Authorization: `Bearer ${token}`,
    })
  ).toObject();
});

export const cancel = createAsyncThunk<
  CancelResponse.AsObject,
  {
    bookingReference: string;
    cancellationItems: CancellationItem[];
    instance: IPublicClientApplication;
    account: AccountInfo;
    brandId: number;
    salesChannelId: number;
  }
>(
  'cancel',
  async ({ instance, account, bookingReference, cancellationItems, brandId, salesChannelId }) => {
    const request = new CancelRequest();

    request.setBrandid(brandId);
    request.setSaleschannelid(salesChannelId);
    request.setTenantid(account.tenantId);
    request.setBookingreference(bookingReference);
    request.setBookingcomponentsList(cancellationItems);

    let token;
    try {
      const { accessToken } = await instance.acquireTokenSilent(silentTokenRequest(account));
      token = accessToken;
    } catch (e) {
      instance.loginRedirect();
    }

    return (
      await new BookingFlowPromiseClient(apiUrl).cancel(request, {
        Authorization: `Bearer ${token}`,
      })
    ).toObject();
  },
);

export const checkUser = createAsyncThunk<
  CheckUserResponse.AsObject,
  {
    accessToken: string;
  }
>('checkUser', async ({ accessToken }) => {
  const request = new CheckUserRequest();

  return (
    await new UserPromiseClient(apiUrl).checkUser(request, {
      Authorization: `Bearer ${accessToken}`,
    })
  ).toObject();
});
