import { Divider } from '@mui/material';

type OrientationType = 'horizontal' | 'vertical';

const BaseDivider = (props: { orientation: OrientationType }) => {
  const { orientation } = props;
  return (
    <Divider
      orientation={orientation}
      variant="middle"
      flexItem
      sx={{
        my: '0.8em',
        backgroundColor: (theme) => theme.palette.grey[300],
        height: '1px',
      }}
    />
  );
};

export const HorizontalDivider = () => <BaseDivider orientation="horizontal" />;
export const VerticalDivider = () => <BaseDivider orientation="vertical" />;
