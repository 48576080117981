import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import IconButton from '@mui/material/IconButton';
import { SxProps } from '@mui/system';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import 'style/themes/platform.scss';

const AddToBasketButton: FC<{
  handleClick: (...args: any) => void;
  buttonStyle?: SxProps;
  classname?: string;
  disabled?: boolean;
}> = ({ handleClick, buttonStyle, classname, disabled = false }) => {
  const { t } = useTranslation();
  return (
    <IconButton
      onClick={handleClick}
      sx={{ ...buttonStyle, backgroundColor: disabled ? 'var(--grey-main) !important' : 'unset' }}
      className={classname}
      disabled={disabled}
    >
      <span>{t('general.addToBasket')}</span>
      <AddShoppingCartIcon />
    </IconButton>
  );
};

export default AddToBasketButton;
