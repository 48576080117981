import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Currency } from 'shared/interfaces/config.interface';

export interface UserSettingsState {
  currency: Currency;
  language: string;
  timezone: string;
  brandId: number;
  salesChannelId: number;
}

export const initialState: UserSettingsState = {
  currency: 'EUR',
  language: '',
  timezone: '',
  brandId: -1,
  salesChannelId: -1,
};

export const userSettingsSlice = createSlice({
  name: 'user-settings',
  initialState,
  reducers: {
    setCurrency: (state, action: PayloadAction<Currency>) => {
      state.currency = action.payload;
    },
    setLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload;
    },
    setTimezone: (state, action: PayloadAction<string>) => {
      state.timezone = action.payload;
    },
    setBrandId: (state, action: PayloadAction<number>) => {
      state.brandId = action.payload;
    },
    setSalesChannelId: (state, action: PayloadAction<number>) => {
      state.salesChannelId = action.payload;
    },
  },
});
export const { setCurrency, setLanguage, setTimezone, setBrandId, setSalesChannelId } =
  userSettingsSlice.actions;
export default userSettingsSlice.reducer;
