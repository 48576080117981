import { CalendarPerformance } from '@BookingPlatform/grpc/v1/Entertainment/Entertainment_pb';
import { Button, SxProps, Typography } from '@mui/material';
import { setSelectedPerformance } from 'pages/entertainment/slices/eventDetails.slice';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import 'shared/components/search-container/SearchContainer.scss';
import { AppDispatch } from 'shared/store/root.store';
import './EventDetails.css';

const SelectButtonStyle = {
  border: '1px solid #6B9E9A',
  background: '#FFFFFF',
  color: '#6B9E9A',
};

const SelectedButtonStyle = {
  border: '1px solid transparent',
  background: '#6B9E9A',
  color: '#FFFFFF',
};

export const ButtonBase = (props: {
  handleClick: () => void;
  buttonText: string;
  style?: SxProps;
}) => {
  const { handleClick, buttonText, style } = props;

  return (
    <Button
      sx={{
        borderRadius: '15px',
        padding: '15px 25px',
        fontSize: '14px',
        ':hover': {
          backgroundColor: '#e0efe9',
          color: 'black',
        },
        ...style,
      }}
      onClick={handleClick}
    >
      <Typography>{buttonText}</Typography>
    </Button>
  );
};

export const SelectButton = (props: { performance: CalendarPerformance.AsObject }) => {
  const dispatch = useDispatch<AppDispatch>();

  const { performance } = props;
  const { t } = useTranslation();

  const handleClick = () => {
    dispatch(setSelectedPerformance(performance));
  };

  return (
    <ButtonBase
      handleClick={handleClick}
      buttonText={t('generic.select')}
      style={SelectButtonStyle}
    />
  );
};

export const SelectedButton = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();

  const handleClick = () => {
    dispatch(setSelectedPerformance(null));
  };

  return (
    <ButtonBase
      handleClick={handleClick}
      buttonText={t('generic.selected')}
      style={SelectedButtonStyle}
    />
  );
};
