import { ProductDetail } from '@BookingPlatform/grpc/v1/Attraction/Common/ProductDetail_pb';
import { DateTime } from 'luxon';
import {
  CheckboxItem,
  DurationNames,
  FilterFormKey,
  IFilterForm,
  specialAttributes,
  TimeNames,
} from '../Results.interface';

class FilterService {
  public priceFilter = (filter: IFilterForm, item: ProductDetail.AsObject) => {
    const fromPrice = Number(item.fromprice?.value || 0);
    return fromPrice >= filter.price[0] && fromPrice <= filter.price[1];
  };

  public ratingFilter = (filter: IFilterForm, item: ProductDetail.AsObject) => {
    const rating = Math.round(Number(item.reviewrating?.value || 0) * 2) / 2;
    return rating >= Number(filter[FilterFormKey.Rating]) || rating === 0;
  };

  public specialsFilter = (filter: IFilterForm, item: ProductDetail.AsObject) => {
    const specialsFilters = filter.specials.filter((checkbox) => checkbox.value);
    if (!item.attributeidlistList || !specialsFilters.length) return true;

    return specialsFilters.some((special) =>
      item.attributeidlistList.includes(this.getTagIdForSpecialFilter(special)),
    );
  };

  private getTagIdForSpecialFilter = (specialCheckbox: CheckboxItem) => {
    const tagId = specialAttributes.find((special) => special.name === specialCheckbox.name)?.tagId;

    if (!tagId) {
      throw new Error('TagId not found.');
    }

    return tagId;
  };

  public durationFilter = (filter: IFilterForm, item: ProductDetail.AsObject) => {
    const durationFilters = filter.duration.filter((checkbox) => checkbox.value);
    if (!item.durationfromminutes?.value || !durationFilters.length) return true;

    return durationFilters.some((duration) => {
      const fromMinutes = Number(item.durationfromminutes?.value);
      const toMinutes = Number(item.durationtominutes?.value ?? fromMinutes);

      switch (duration.name) {
        case DurationNames.UnderOneHour:
          return fromMinutes <= 60 || toMinutes <= 60;
        case DurationNames.UpToFourHours:
          return (fromMinutes >= 60 || toMinutes >= 60) && (fromMinutes <= 240 || toMinutes <= 240);
        case DurationNames.UpToOneDay:
          return (
            (fromMinutes >= 240 || toMinutes >= 240) && (fromMinutes <= 1440 || toMinutes <= 1440)
          );
        case DurationNames.OverOneDay:
          return fromMinutes > 1440 || toMinutes > 1440;
        default:
          return false;
      }
    });
  };

  private roundCeilHour = (time: DateTime) => {
    const minutes = Number(time.toFormat('m'));
    return minutes > 0 ? Number(time.toFormat('H')) + 1 : Number(time.toFormat('H'));
  };

  public timeOfDayFilter = (filter: IFilterForm, item: ProductDetail.AsObject) => {
    const timeOfDayFilters = filter.time.filter((checkbox) => checkbox.value);
    if (!item.timedentrylistList?.length || !timeOfDayFilters.length) return true;

    return timeOfDayFilters.some((timeOfDay) =>
      item.timedentrylistList.some((entryTime) => {
        const entryDT = DateTime.fromFormat(entryTime, 'HH:mm');
        const entryHour = this.roundCeilHour(entryDT);

        switch (timeOfDay.name) {
          case TimeNames.SixToTwelve:
            return entryHour >= 6 && entryHour <= 12;
          case TimeNames.TwelveToFive:
            return entryHour >= 12 && entryHour <= 17;
          case TimeNames.FiveToTwelve:
            return entryHour > 17 && entryHour <= 24;
          default:
            return false;
        }
      }),
    );
  };
}

const filterService = new FilterService();
export default filterService;
