import { useMsal } from '@azure/msal-react';
import { DateTime } from 'luxon';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { SearchQuery } from 'shared/components/search-container/Search.interface';
import ResultsContainer from 'shared/components/search-results/ResultsContainer';
import useCanDispatch from 'shared/hooks/useCanDispatch';
import requestStoreService, { RequestStatus } from 'shared/store/request.reducer';
import { attractionProductSearch, getTags } from 'shared/store/request.thunk';
import { AppDispatch } from 'shared/store/root.store';
import useTranslatedTitle from '../../account/hooks/useTranslatedTitle';
import SearchForm from '../search/SearchForm';
import { setSearch, setSelectedTags } from '../slices/search.slice';
import Loader from './Loader';
import Results from './Results';

const SearchResultsPage: FC = () => {
  useTranslatedTitle('searchResultsPage.thingsToDo.resultFilters.title');

  const thingsToDoSearchResults = useSelector(
    requestStoreService.selector('thingsToDoSearchResults'),
  );
  const { currency, language, brandId, salesChannelId } = useSelector(
    requestStoreService.selector('userSettings'),
  );
  const [searchParams] = useSearchParams();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const dispatch = useDispatch<AppDispatch>();
  const { instance, accounts } = useMsal();

  const searchParam = searchParams.get('search');
  const canDispatch = useCanDispatch();

  useEffect(() => {
    if (canDispatch && searchParam) {
      const searchQuery = JSON.parse(searchParam) as SearchQuery;
      const startDate = DateTime.fromFormat(searchQuery.startDate, 'yyyyMMdd').toJSDate();
      const endDate = DateTime.fromFormat(searchQuery.endDate, 'yyyyMMdd').toJSDate();

      dispatch(
        attractionProductSearch({
          searchForm: { date: { startDate, endDate }, location: searchQuery.location },
          instance,
          account: accounts[0],
          brandId,
          salesChannelId,
        }),
      );

      dispatch(
        getTags({
          locationid: searchQuery.location.id,
          account: accounts[0],
          instance,
          languagecode: language,
          brandId,
          salesChannelId,
        }),
      );

      dispatch(
        setSearch({
          location: searchQuery.location,
          date: { startDate: startDate.toString(), endDate: endDate.toString() },
        }),
      );

      dispatch(setSelectedTags(new Set<number>()));
    }
  }, [
    currency,
    language,
    brandId,
    salesChannelId,
    searchParams,
    accounts,
    instance,
    searchParam,
    canDispatch,
    dispatch,
  ]);

  return (
    <>
      <div className="container pt-4">
        <SearchForm />
      </div>
      {!canDispatch || thingsToDoSearchResults.status === RequestStatus.Loading ? (
        <Loader />
      ) : (
        <ResultsContainer>
          <Results />
        </ResultsContainer>
      )}
    </>
  );
};

export default SearchResultsPage;
