import {
  BookingDetailsResponseItem,
  CheckBookingSessionStatusResponse,
} from '@BookingPlatform/grpc/v1/BookingFlow_pb';
import { Draft, createSlice } from '@reduxjs/toolkit';
import { RequestState, RequestStatus } from 'shared/store/request.reducer';
import { checkBookingSessionStatus, getBookingDetails } from 'shared/store/request.thunk';

export interface BookingSessionState {
  bookingSession?: RequestState<CheckBookingSessionStatusResponse.AsObject>;
  bookingDetails?: RequestState<BookingDetailsResponseItem.AsObject>;
}

const initialState: BookingSessionState = {};

export const bookingSessionSlice = createSlice({
  name: 'booking-session',
  initialState,
  reducers: {
    resetBookingSession: (state) => {
      state.bookingSession = undefined;
      state.bookingDetails = undefined;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(checkBookingSessionStatus.pending, (state) => {
        state.bookingSession = {
          status: RequestStatus.Loading,
          value: undefined,
          error: undefined,
        };
      })
      .addCase(checkBookingSessionStatus.fulfilled, (state, action) => {
        state.bookingSession = {
          status: RequestStatus.Idle,
          value: action.payload as Draft<CheckBookingSessionStatusResponse.AsObject>,
          error: undefined,
        };
      })
      .addCase(checkBookingSessionStatus.rejected, (state, action) => {
        state.bookingSession = {
          status: RequestStatus.Failed,
          value: undefined,
          error: action.error,
        };
        // selectively show toast only
        // toast.error(action.type + '<br />' + action.error.message, { autoClose: 10000, pauseOnHover: true });
      });

    builder
      .addCase(getBookingDetails.pending, (state) => {
        state.bookingDetails = {
          status: RequestStatus.Loading,
          value: undefined,
          error: undefined,
        };
      })
      .addCase(getBookingDetails.fulfilled, (state, action) => {
        state.bookingDetails = {
          status: RequestStatus.Idle,
          value: action.payload as Draft<BookingDetailsResponseItem.AsObject>,
          error: undefined,
        };
      })
      .addCase(getBookingDetails.rejected, (state, action) => {
        state.bookingDetails = {
          status: RequestStatus.Failed,
          value: undefined,
          error: action.error,
        };
        // selectively show toast only
        // toast.error(action.type + '<br />' + action.error.message, { autoClose: 10000, pauseOnHover: true });
      });
  },
});

export const { resetBookingSession } = bookingSessionSlice.actions;
export default bookingSessionSlice.reducer;
