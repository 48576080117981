import { deepmerge } from '@mui/utils';
import { IConfig } from 'shared/interfaces/config.interface';

class ConfigService {
  public getConfig = async () => {
    const configName = process.env.REACT_APP_SITE || 'platfrom';
    const localConfigPromise = import(`assets/configs/${configName}.json`);
    const localConfigModule = await localConfigPromise;

    const remoteConfigPromise = await fetch('/mocks/remote-config.json');
    const remoteConfig: IConfig = await remoteConfigPromise.json();

    const mergedConfig: IConfig = deepmerge(localConfigModule.default, remoteConfig);

    return mergedConfig;
  };
}

const configService = new ConfigService();
export default configService;
