import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import SearchContainer from 'shared/components/search-container/SearchContainer';
import 'shared/components/search-container/SearchContainer.scss';
import useTranslatedTitle from '../account/hooks/useTranslatedTitle';
import './Dining.scss';
import DiningSearchForm from './dining-search-form/DiningSearchForm';

const Dining: FC = () => {
  useTranslatedTitle('productDetails.dining.browserTitle');

  const { t } = useTranslation();

  return (
    <div className="search-container dining-search-header">
      <SearchContainer
        title={t('productDetails.dining.title')}
        subtitle={t('productDetails.dining.subtitle')}
      >
        <DiningSearchForm />
      </SearchContainer>
    </div>
  );
};

export default Dining;
