import i18next from 'i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';

const defaultLanguage = localStorage.getItem('languageCode') as string;

i18next
  .use(
    resourcesToBackend((language, _namespace, callback) => {
      import(`/src/assets/locales/${language.toLowerCase()}.json`)
        .then(({ default: resources }) => {
          callback(null, resources);
        })
        .catch((error) => {
          callback(error, null);
        });
    }),
  )
  .use(initReactI18next)
  .init({
    lng: defaultLanguage,
    fallbackLng: 'en-US',
    ns: [],
    interpolation: { escapeValue: false },
  });

const i18nConfig = i18next;

export default i18nConfig;
