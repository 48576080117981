const generateTimeOptions = () => {
  const times = [];
  const startTime = new Date();
  startTime.setHours(0, 0, 0, 0); // Set initial time to midnight

  const intervalInMinutes = 30;

  while (startTime.getDate() === new Date().getDate()) {
    const option = startTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    times.push(option);
    startTime.setMinutes(startTime.getMinutes() + intervalInMinutes);
  }

  return times;
};

export default generateTimeOptions;
