import { setSelectedTags } from 'pages/things-to-do/slices/search.slice';
import { FC, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SearchTag from 'shared/components/slider/SearchTag';
import Slider from 'shared/components/slider/Slider';
import requestStoreService from 'shared/store/request.reducer';
import { Tag } from 'shared/store/request.thunk';
import { AppDispatch } from 'shared/store/root.store';

const ThingsToDoTagSlider: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { value: allTags } = useSelector(requestStoreService.selector('searchTags'));
  const { value: thingsToDoSearchResults } = useSelector(
    requestStoreService.selector('thingsToDoSearchResults'),
  );
  const { selectedTagIds } = useSelector(requestStoreService.selector('searchForm'));

  const allTagsWithImage = useMemo(() => {
    if (!allTags || !thingsToDoSearchResults) return [];

    return allTags.map((tag) => {
      const productsHaveTag = thingsToDoSearchResults.filter((product) =>
        product.attributeidlistList.includes(tag.attributeid),
      );
      return {
        ...tag,
        imageurl:
          productsHaveTag[Math.floor(Math.random() * productsHaveTag.length)]?.mainimageurl ||
          tag.imageurl,
        productCount: productsHaveTag.length,
      };
    });
  }, [allTags, thingsToDoSearchResults]);

  const allTagsByLocation = useMemo(() => {
    if (!thingsToDoSearchResults) return [];

    const allSearchResultTagIds = new Set(
      thingsToDoSearchResults.map((product) => product.attributeidlistList).flat(),
    );

    return allTagsWithImage.filter((tag) => allSearchResultTagIds.has(tag.attributeid));
  }, [allTagsWithImage, thingsToDoSearchResults]) as Tag[];

  const sortFunction = useCallback(
    (a: Tag, b: Tag) => {
      return (
        Number(selectedTagIds.has(b.attributeid)) - Number(selectedTagIds.has(a.attributeid)) ||
        (b.productCount as number) - (a.productCount as number)
      );
    },
    [selectedTagIds],
  );

  const sortedTags = useMemo(
    () => allTagsByLocation.slice().sort(sortFunction),
    [allTagsByLocation, sortFunction],
  );

  const toggleTag = (tag: Tag) => {
    const newSet = new Set(selectedTagIds);
    if (!newSet.delete(tag.attributeid)) {
      newSet.add(tag.attributeid);
    }
    dispatch(setSelectedTags(newSet));
  };

  return (
    <Slider
      items={sortedTags}
      selectedItemIds={selectedTagIds}
      onItemClick={toggleTag}
      renderItem={(tag, active, onClick) => (
        <SearchTag key={tag.attributeid} tag={tag} active={active} onClick={onClick} />
      )}
    />
  );
};

export default ThingsToDoTagSlider;
