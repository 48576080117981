import { AutoCompleteResponse as EntertainmentAutoCompleteResponse } from '@BookingPlatform/grpc/v1/Entertainment/Entertainment_pb';
import { AsyncThunk } from '@reduxjs/toolkit';
import {
  Details,
  EntProductThunkArgument,
  EntertainmentSearchFormTypes,
  ThunkActionReturnType,
  ThunkArgument,
  UseGetEntertainmentProductInfoReturnType,
} from 'pages/entertainment/Entertainment.interface';
import { useSelector } from 'react-redux';
import useCanDispatch from 'shared/hooks/useCanDispatch';
import requestStoreService from 'shared/store/request.reducer';
import {
  getEntertainmentLocations,
  getEventInfo,
  getPerformerInfo,
  getVenueInfo,
} from 'shared/store/request.thunk';

export const DEFAULT_IMAGE =
  'https://images.unsplash.com/photo-1492684223066-81342ee5ff30?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D';

const useGetEntertainmentProductDetails = (
  searchQuery: EntertainmentSearchFormTypes | undefined,
): UseGetEntertainmentProductInfoReturnType => {
  const venueDetails = useSelector(requestStoreService.selector('getVenueInfo'));
  const eventDetails = useSelector(requestStoreService.selector('getEventInfo'));
  const performerDetails = useSelector(requestStoreService.selector('getPerformerInfo'));
  const canDispatch = useCanDispatch();

  const details: Details = {
    getDetails: getEntertainmentLocationsWrapper,
    productDetails: {
      title: '',
      imageUrl: '',
      additionalInfo: [],
    },
  };

  if (canDispatch && searchQuery) {
    if (searchQuery.venue) {
      details.getDetails = getVenueDetailsWrapper;
      if (venueDetails.value && venueDetails.value[0].venuename) {
        details.productDetails.title = venueDetails.value[0].venuename;
        details.productDetails.additionalInfo = [
          venueDetails.value[0].venuename,
          venueDetails.value[0].venuelocation,
        ];
        if (venueDetails.value[0].mainimageurl?.value) {
          details.productDetails.imageUrl = venueDetails.value[0].mainimageurl?.value;
        } else {
          details.productDetails.imageUrl = DEFAULT_IMAGE;
        }
      }
    } else if (searchQuery.event) {
      details.getDetails = getEventDetailsWrapper;
      if (eventDetails.value && eventDetails.value[0].eventname) {
        details.productDetails.title = eventDetails.value[0].eventname;
        details.productDetails.additionalInfo = [
          eventDetails.value[0].venuename,
          eventDetails.value[0].venuelocation,
        ];
        if (eventDetails.value[0].mainimageurl?.value) {
          details.productDetails.imageUrl = eventDetails.value[0].mainimageurl?.value;
        } else {
          details.productDetails.imageUrl = DEFAULT_IMAGE;
        }
      }
    } else if (searchQuery?.performer) {
      details.getDetails = getPerformerDetailsWrapper;
      if (performerDetails.value && performerDetails.value[0].performername) {
        details.productDetails.title = performerDetails.value[0].performername;
        details.productDetails.additionalInfo = undefined;
        if (performerDetails.value[0].mainimageurl?.value) {
          details.productDetails.imageUrl = performerDetails.value[0].mainimageurl?.value;
        } else {
          details.productDetails.imageUrl = DEFAULT_IMAGE;
        }
      }
    } else {
      details.getDetails = getEntertainmentLocationsWrapper;
      details.productDetails = {
        imageUrl: undefined,
        title: searchQuery.location.name,
        additionalInfo: searchQuery.location.additionaldata?.value
          ? [searchQuery.location.additionaldata?.value]
          : undefined,
      };
    }
  }

  return { details };
};

export default useGetEntertainmentProductDetails;

const getEntertainmentLocationsWrapper = getEntertainmentLocations as AsyncThunk<
  EntertainmentAutoCompleteResponse.AsObject,
  ThunkArgument,
  Record<string, never>
>;

const getVenueDetailsWrapper = getVenueInfo as AsyncThunk<
  ThunkActionReturnType,
  EntProductThunkArgument,
  Record<string, never>
>;

const getEventDetailsWrapper = getEventInfo as AsyncThunk<
  ThunkActionReturnType,
  EntProductThunkArgument,
  Record<string, never>
>;

const getPerformerDetailsWrapper = getPerformerInfo as AsyncThunk<
  ThunkActionReturnType,
  EntProductThunkArgument,
  Record<string, never>
>;
