import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface IErrorTitle {
  title: string;
  subtitle: string;
}

const ErrorTitle: FC<IErrorTitle> = ({ title, subtitle }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="heading mb-3">
        <h1 className="h1 mb-0">
          <ErrorOutlineIcon
            fontSize="large"
            style={{ position: 'relative', top: 4 }}
            className="mr-2"
          />
          <span>{t('errorBoundary.title')}</span>
        </h1>
      </div>

      <h2
        className="h4 mt-4"
        dangerouslySetInnerHTML={{ __html: t('errorBoundary.subtitle') }}
      ></h2>
    </>
  );
};

export default ErrorTitle;
