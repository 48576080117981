import { Category } from '@BookingPlatform/grpc/v1/Attraction/Attraction_pb';
import { useMsal } from '@azure/msal-react';
import { Grid, SelectChangeEvent } from '@mui/material';
import Button from '@mui/material/Button';
import SearchIcon from 'assets/svg-icons/SearchIcon';
import { DateTime } from 'luxon';
import { ChangeEvent, FC, SyntheticEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import SearchButton from 'shared/components/buttons/SearchButton';
import DateRangeSelector from 'shared/components/date-range-selector/DateRangeSelector';
import { SearchFormType, SearchQuery } from 'shared/components/search-container/Search.interface';
import 'shared/components/search-container/SearchContainer.scss';
import useGetSearchQuery from 'shared/hooks/useGetSearchQuery';
import { PageRoute } from 'shared/interfaces/config.interface';
import utils from 'shared/services/utilities.service';
import { attractionProductSearch, getProduct } from 'shared/store/request.thunk';
import { AppDispatch, RootState } from 'shared/store/root.store';
import { setSearch, setSelectedProduct, setSelectedTags } from '../slices/search.slice';
import SearchField from './search-field/SearchField';

export const SearchForm: FC = () => {
  const [, setSearchParams] = useSearchParams();
  const [shouldRedirectDirectlyTo, setShouldRedirectDirectlyTo] = useState<false | string>(false);
  const { brandId, salesChannelId } = useSelector((state: RootState) => state.userSettings);

  const { instance, accounts } = useMsal();
  const navigate = useNavigate();

  const date = new Date();
  const { t } = useTranslation();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const dispatch = useDispatch<AppDispatch>();
  const searchForm = useSelector((state: RootState) => state.searchForm);

  const {
    setValue,
    getValues,
    formState: { errors },
    clearErrors,
    setError,
  } = useForm<SearchFormType>({
    defaultValues: {
      location: searchForm.location ?? undefined,
      date: {
        startDate: new Date(searchForm.date.startDate ?? new Date().setDate(date.getDate() + 14)),
        endDate: new Date(searchForm.date.endDate ?? new Date().setDate(date.getDate() + 15)),
      },
    },
  });

  const submitForm = async (e: SyntheticEvent) => {
    e.preventDefault();
    const { location, date } = getValues();
    if (!location) {
      setError('location', {});
    } else if (Object.keys(errors).length === 0) {
      const searchParams = JSON.stringify({
        startDate: DateTime.fromJSDate(date.startDate).toFormat('yyyyMMdd'),
        endDate: DateTime.fromJSDate(date.endDate).toFormat('yyyyMMdd'),
        location: {
          ...location,
          name: encodeURIComponent(location.name),
        },
      });
      navigate(`${PageRoute.ThingsToDo}${PageRoute.SearchResults}?search=${searchParams}`);
    }
  };

  const searchQuery = useGetSearchQuery();

  useEffect(() => {
    if (searchQuery) {
      setShouldRedirectDirectlyTo(
        (searchQuery as SearchQuery).location.type === Category.ATTRACTION
          ? `${PageRoute.ProductDetails}/${searchQuery.location.id}`
          : false,
      );
    }
    // This should only run at initialization don't add parameters
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form className="form-wrap box" onSubmit={submitForm}>
      <Grid
        container
        sx={{
          margin: 0,
          columnGap: '1em',
          rowGap: '0.2em',
          display: 'grid',
          gridTemplateColumns: 'repeat(13, 1fr)',
        }}
      >
        <Grid item sx={{ gridColumnStart: 1, gridColumnEnd: 10 }}>
          <SearchField
            id="location"
            label={t('productDetails.thingsToDo.searchLabel')}
            placeholder={t('productDetails.thingsToDo.searchPlaceholder') + '...'}
            defaultValue={getValues().location}
            error={!!errors.location}
            onChange={(event: ChangeEvent<any> | SelectChangeEvent<any>) => {
              setValue('location', event.target.value);
              if (event.target.value.type === Category.ATTRACTION_LOCATION) {
                setShouldRedirectDirectlyTo(false);
              } else {
                // attraction case
                setShouldRedirectDirectlyTo(
                  `${PageRoute.ProductDetails}/:${event.target.value.id}`,
                );
              }

              event.target.name ? clearErrors() : setError('location', {});
            }}
          />
        </Grid>

        <Grid item sx={{ gridColumnStart: 10, gridColumnEnd: 13 }}>
          <DateRangeSelector
            id="date"
            label={t('generic.travelDate')}
            onChange={(event: ChangeEvent<any> | SelectChangeEvent<any>) => {
              setValue('date', event.target.value);
            }}
            defaultValue={getValues().date}
            minDate={new Date()}
            maxDate={new Date(date.getFullYear() + 1, date.getMonth(), date.getDate())}
            rangeLimit={14}
          />
        </Grid>

        <Grid item sx={{ gridColumnStart: 13 }}>
          {shouldRedirectDirectlyTo ? (
            <Button
              className="save-button"
              color="primary"
              variant="contained"
              startIcon={<SearchIcon fontSize="small" />}
              onClick={() => {
                const { location, date } = getValues();
                dispatch(
                  getProduct({
                    productId: location?.id || 0,
                    instance,
                    account: accounts[0],
                    languagecode: utils.getCurrentLanguage(),
                    brandId,
                    salesChannelId,
                  }),
                );

                dispatch(
                  attractionProductSearch({
                    searchForm: getValues(),
                    instance,
                    account: accounts[0],
                    brandId,
                    salesChannelId,
                  }),
                );

                dispatch(
                  setSearch({
                    location: location,
                    date: {
                      startDate: date.startDate.toString(),
                      endDate: date.endDate.toString(),
                    },
                  }),
                );
                location && dispatch(setSelectedProduct(location.id));
                setSearchParams({
                  search: JSON.stringify({
                    startDate: DateTime.fromJSDate(date.startDate).toFormat('yyyyMMdd'),
                    endDate: DateTime.fromJSDate(date.endDate).toFormat('yyyyMMdd'),
                    location: location,
                  }),
                });

                dispatch(setSelectedTags(new Set<number>()));

                navigate(shouldRedirectDirectlyTo);
              }}
              sx={{ height: '100%' }}
            >
              {t('generic.search')}
            </Button>
          ) : (
            <SearchButton sxProps={{ height: '100%' }} />
          )}
        </Grid>
      </Grid>
    </form>
  );
};

export default SearchForm;
