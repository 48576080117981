import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { CssBaseline } from '@mui/material';
import Dining from 'pages/dining/Dining';
import EventDetailsPage from 'pages/entertainment/results/EventDetailsPage/EventDetailsPage';
import LoadingPage from 'pages/loading-page/LoadingPage';
import Login from 'pages/login/Login';
import PaymentStatus from 'pages/payment-status/PaymentStatus';
import SearchResultsPage from 'pages/things-to-do/results/SearchResultsPage';
import { lazy, Suspense } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import ErrorBoundary from 'shared/components/error-boundary/ErrorBoundary';
import Footer from 'shared/components/footer/Footer';
import Header from 'shared/components/header/Header';
import HighSecurityTemplate from 'shared/components/HighSecurityTemplate';
import PageMetrics from 'shared/components/metrics/PageMetrics';
import PreBookKeepAliveProvider from 'shared/components/prebook-keepalive/PreBookKeepAliveProvider';
import { PageRoute } from 'shared/interfaces/config.interface';

const NotFound = lazy(() => import('pages/not-found/NotFound'));
const Dashboard = lazy(() => import('pages/dashboard/Dashboard'));
const ThingsToDo = lazy(() => import('pages/things-to-do/ThingsToDo'));
const Entertainment = lazy(() => import('pages/entertainment/Entertainment'));
const ProductDetails = lazy(() => import('pages/product-details/ProductDetails'));
const DiningDetails = lazy(() => import('pages/dining-details/DiningDetails'));
const ThingsToDoCheckoutPage = lazy(() => import('pages/checkout/ThingsToDoCheckoutPage'));
const EntertainmentCheckoutPage = lazy(() => import('pages/checkout/EntertainmentCheckoutPage'));
const BookingDetailsPage = lazy(() => import('pages/booking-details/BookingDetailsPage'));
const Account = lazy(() => import('pages/account/Account'));
const BookingCancellation = lazy(
  () => import('pages/booking-cancellation/BookingCancellationPage'),
);
const BookingCancellationConfirmation = lazy(
  () => import('pages/booking-cancellation-confirmation/BookingCancellationConfirmation'),
);
const EntertainmentSearchResultsPage = lazy(
  () => import('pages/entertainment/results/EntertainmentSearchResultsPage'),
);

function App() {
  const { inProgress } = useMsal();

  return (
    <>
      <ToastContainer position="bottom-center" limit={1} />
      {inProgress === 'none' && (
        <UnauthenticatedTemplate>
          <Login />
        </UnauthenticatedTemplate>
      )}
      <CssBaseline enableColorScheme />
      <AuthenticatedTemplate>
        <Router>
          <PreBookKeepAliveProvider>
            <Header />
            <PageMetrics />
            <Suspense fallback={<LoadingPage />}>
              <ErrorBoundary>
                <Routes>
                  <Route path="*" element={<NotFound />} />
                  <Route path="/" element={<Dashboard />} />
                  <Route
                    path={PageRoute.ThingsToDo}
                    element={
                      <HighSecurityTemplate>
                        <ThingsToDo />
                      </HighSecurityTemplate>
                    }
                  />
                  <Route
                    path={PageRoute.Entertainment}
                    element={
                      <HighSecurityTemplate>
                        <Entertainment />
                      </HighSecurityTemplate>
                    }
                  />
                  <Route
                    path={PageRoute.Dining}
                    element={
                      <HighSecurityTemplate>
                        <Dining />
                      </HighSecurityTemplate>
                    }
                  />
                  <Route
                    path={`${PageRoute.ThingsToDo}${PageRoute.SearchResults}`}
                    element={<SearchResultsPage />}
                  />
                  <Route
                    path={`${PageRoute.Entertainment}${PageRoute.SearchResults}`}
                    element={<EntertainmentSearchResultsPage />}
                  />
                  <Route
                    path={`${PageRoute.ProductDetails}/:productId`}
                    element={<ProductDetails />}
                  />
                  <Route
                    path={`${PageRoute.EventDetails}/:eventId`}
                    element={<EventDetailsPage />}
                  />
                  <Route
                    path={`${PageRoute.DiningDetails}/:productId`}
                    element={<DiningDetails />}
                  />
                  <Route
                    path={`${PageRoute.Checkout}${PageRoute.ThingsToDo}`}
                    element={
                      <HighSecurityTemplate>
                        <ThingsToDoCheckoutPage />
                      </HighSecurityTemplate>
                    }
                  />
                  <Route
                    path={`${PageRoute.Checkout}${PageRoute.Entertainment}`}
                    element={
                      <HighSecurityTemplate>
                        <EntertainmentCheckoutPage />
                      </HighSecurityTemplate>
                    }
                  />
                  <Route
                    path={`${PageRoute.BookingDetails}/:bookingReference`}
                    element={
                      <HighSecurityTemplate>
                        <BookingDetailsPage />
                      </HighSecurityTemplate>
                    }
                  />
                  {!!process.env.REACT_APP_FEAT_USERSETTINGS && (
                    <Route path={PageRoute.Account} element={<Account />} />
                  )}
                  <Route path={PageRoute.PaymentResult} element={<PaymentStatus />} />
                  <Route
                    path={`${PageRoute.BookingCancellation}/:bookingReference`}
                    element={
                      <HighSecurityTemplate>
                        <BookingCancellation />
                      </HighSecurityTemplate>
                    }
                  />
                  <Route
                    path={`${PageRoute.BookingCancellationConfirmation}/:bookingReference`}
                    element={
                      <HighSecurityTemplate>
                        <BookingCancellationConfirmation />
                      </HighSecurityTemplate>
                    }
                  />
                </Routes>
              </ErrorBoundary>
            </Suspense>
            <Footer />
          </PreBookKeepAliveProvider>
        </Router>
      </AuthenticatedTemplate>
    </>
  );
}

export default App;
