import { createContext, Dispatch, ReactNode, SetStateAction, useState } from 'react';

interface IHighSecurityContext {
  isTokenTooOld: boolean;
  setIsTokenTooOld: Dispatch<SetStateAction<boolean>>;
}

export const HighSecurityContext = createContext({} as IHighSecurityContext);

const HighSecurityProvider = ({ children }: { children: ReactNode }) => {
  const [isTokenTooOld, setIsTokenTooOld] = useState<boolean>(false);

  return (
    <HighSecurityContext.Provider value={{ isTokenTooOld, setIsTokenTooOld }}>
      {children}
    </HighSecurityContext.Provider>
  );
};

export default HighSecurityProvider;
