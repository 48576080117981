import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { IconedListItem } from 'shared/interfaces/config.interface';
import handleKeyDown from 'utils/onEnterKeydown';
import './NavSelector.scss';

interface NavDisabledInfo {
  isDisabled: boolean;
  message: string;
  linkUrl?: string;
  linkText?: string;
}

interface Props<T> {
  list: Array<IconedListItem<T>>;
  defaultItem?: IconedListItem<T>;
  disabled?: NavDisabledInfo;
  title: string;
  onChange: (_: T) => void;
  outerItemValue?: T;
}

const NavSelector = <T,>({
  list,
  title,
  onChange,
  disabled,
  defaultItem = list[0],
  outerItemValue,
}: Props<T>) => {
  const { t } = useTranslation();
  const [activeItem, setActiveItem] = useState<IconedListItem<T>>(defaultItem);

  useEffect(() => {
    const item = list.find((item) => item.value === outerItemValue);

    if (outerItemValue && item) {
      setActiveItem(item);
    }
  }, [outerItemValue, list]);

  const handleClick = (item: IconedListItem<T>) => {
    onChange(item.value);
    setActiveItem(item);
  };

  return (
    <div className={`nav-selector${disabled?.isDisabled ? ' disabled' : ''}`}>
      <div className="button">
        {activeItem?.iconUrl ? (
          <img className="icon" width="20" src={activeItem?.iconUrl} aria-hidden="true" alt="" />
        ) : (
          <div className="icon-string">{activeItem?.iconString}</div>
        )}

        <span className="label">{activeItem?.label}</span>
      </div>
      <div className="dropdown-wrap">
        <div className="dropdown">
          {disabled?.isDisabled && (
            <div className="disabled-message">
              {t(disabled.message)}
              {disabled.linkUrl && <Link to={disabled.linkUrl}>{t(disabled.linkText ?? '')}</Link>}
            </div>
          )}

          {!disabled?.isDisabled && (
            <>
              <div className="selector-title">{title}</div>
              {list.map((item, index) => (
                <div
                  onKeyDown={(event) => handleKeyDown(event, () => handleClick(item))}
                  className={`item ${activeItem.value === item.value ? 'active' : ''}`}
                  key={index}
                  onClick={() => handleClick(item)}
                >
                  {item.iconUrl ? (
                    <img className="icon" width="20" src={item.iconUrl} aria-hidden="true" alt="" />
                  ) : (
                    <div className="icon-string">{item.iconString}</div>
                  )}
                  <span className="label">{item.label}</span>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

NavSelector.defaultProps = {
  defaultItem: undefined,
  disabled: undefined,
  outerItemValue: undefined,
};

export default NavSelector;
