import { EventType } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { ReactNode, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { checkUser } from 'shared/store/request.thunk';
import { AppDispatch } from 'shared/store/root.store';

interface LoginInterceptorProps {
  children: ReactNode;
}

const LoginInterceptor = ({ children }: LoginInterceptorProps) => {
  const { instance } = useMsal();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const callbackId = instance.addEventCallback(
      (message: { eventType: EventType; payload: any }) => {
        if (message.eventType === EventType.LOGIN_SUCCESS) {
          dispatch(checkUser({ accessToken: message.payload.accessToken }));
        }
      },
    );

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [dispatch, instance]);

  return <>{children}</>;
};

export default LoginInterceptor;
