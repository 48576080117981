import { KeyboardEvent } from 'react';

// handlerMethod can have any number of arguments of any type
// any tpye is on purpose
const handleKeyDown = <T extends (...args: any[]) => any>(
  e: KeyboardEvent<HTMLDivElement>,
  handlerMethod: T,
) => {
  if (e.key === 'Enter' || e.key === ' ') {
    handlerMethod();
  }
};

export default handleKeyDown;
