import { CheckoutResponse } from '@BookingPlatform/grpc/v1/BookingFlow_pb';
import { Draft, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { RequestState, RequestStatus } from 'shared/store/request.reducer';
import { checkout } from 'shared/store/request.thunk';

const initialState: RequestState<CheckoutResponse.AsObject> = {
  value: undefined,
  status: RequestStatus.Null,
  error: undefined,
};

export const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    resetCheckout: (state) => {
      state.value = undefined;
      state.status = RequestStatus.Idle;
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkout.pending, (state) => {
        state.status = RequestStatus.Loading;
        state.value = undefined;
        state.error = undefined;
      })
      .addCase(checkout.fulfilled, (state, action) => {
        state.status = RequestStatus.Idle;
        state.value = action.payload as Draft<CheckoutResponse.AsObject>;
      })
      .addCase(checkout.rejected, (state, action) => {
        state.status = RequestStatus.Failed;
        state.value = undefined;
        state.error = action.error;
        if (action.error) {
          toast.error(action.error.message || 'Unknown error occurred!');
          toast.clearWaitingQueue();
        }
      });
  },
});

export const { resetCheckout } = checkoutSlice.actions;
export default checkoutSlice.reducer;
