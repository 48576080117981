import { format, parseISO } from 'date-fns';
import dayjs from 'dayjs';
import { DateTime } from 'luxon';

const backendDateFormat = 'yyyy-MM-dd';
const uiDateFormat = 'dd/MM/yyyy';
const timeFormat = 'HH:mm';

// from Date --> yyyy-MM-dd
export function convertDateToISOString(date: Date): string | null {
  if (!date) return null;
  const dateTime = DateTime.fromJSDate(date);
  return dateTime.isValid ? dateTime.toISODate() : null;
}

// from YYYY-MM-DD --> dd/MM/yyyy
export function convertBackendDateToUiFormat(date: string) {
  return date ? DateTime.fromFormat(date, backendDateFormat).toFormat(uiDateFormat) : '';
}

export function getGormattedTime(date: string, timezone: string | undefined) {
  const dateTime = DateTime.fromISO(date, { setZone: true, zone: timezone });
  const formattedTime = dateTime.toFormat('HH:mm');
  return formattedTime;
}

// date param: YYYY-MM-DDTHH:MM+01:00
export function convertISOStringToLongUiFormat(
  date: string,
  timezone: string | undefined,
  userLocale: string,
) {
  const formattedTime = getGormattedTime(date, timezone);
  const dayofweek = new Date(date).toLocaleDateString(userLocale, { weekday: 'short' });
  const formattedDate = getLocaleFormattedDateFromString(date, userLocale);

  const timezoneString = timezone ? `(${timezone})` : '';
  const dayDateTimeTimezone = `${dayofweek} ${formattedDate} ${formattedTime} ${timezoneString}`;
  return dayDateTimeTimezone;
}

export function formatDateToUiFormat(date: Date) {
  return format(date, uiDateFormat);
}

export function formatDateStringToISO8601DateFormat(date: Date) {
  return format(date, backendDateFormat);
}

export function formatTime(date: Date) {
  return format(date, timeFormat);
}

export function convertMinutesToDuration(minutes: number) {
  const hours = Math.floor(minutes / 60);
  const leftoverMinutes = minutes - 60 * hours;
  const hoursPlural = hours > 1 ? 's' : '';
  const minutesPlural = leftoverMinutes > 1 ? 's' : '';

  return (
    (hours > 0 ? `${hours} hour${hoursPlural} ` : '') +
    (leftoverMinutes > 0 ? `${leftoverMinutes} minute${minutesPlural}` : '')
  );
}

export function getDayOfWeekDateFns(dateString: string) {
  const date = parseISO(dateString);
  return format(date, 'EEE');
}

export function formatToMonthDay(dateString: string) {
  const date = parseISO(dateString);
  return format(date, 'MMM d');
}

export function formatStringYYYYMMDDToDate(date: string) {
  return parseISO(date);
}

// 2024-01-15 --> 2024-01
export function formatStringYYYYMMDDToYYYYMM(date: string) {
  const [year, month] = date.split('-').slice(0, 2);
  return `${year}-${month}`;
}

// 2024-01-15 --> 2024.01.15.
export function formatStringYYYYMMDDToUIFormat(date: string) {
  const formattedDate = format(parseISO(date), 'yyyy.MM.dd');
  return formattedDate;
}
// '2024-02' --> { startDate: 2024-02-01, endDate: '2024-02-29'}
export function getStartAndEndOfMonth(yearAndMonth: string) {
  const startDate = dayjs(`${yearAndMonth}-01`).startOf('month');
  const endDate = dayjs(`${yearAndMonth}-01`).endOf('month');
  return { startDate: startDate.format('YYYY-MM-DD'), endDate: endDate.format('YYYY-MM-DD') };
}

export function formatDateToYYYYMM(date: Date): string {
  return dayjs(date).format('YYYY-MM');
}

interface DateRange {
  startdate: string;
  enddate: string;
}

export const getCurrentYearDateRange = (): DateRange => {
  const startdate = dayjs().startOf('year').format('YYYY-MM-DD');
  const enddate = dayjs().endOf('year').format('YYYY-MM-DD');

  return {
    startdate,
    enddate,
  };
};

// date param: YYYY-MM-DD
export const getLocaleFormattedDateFromString = (
  date: string,
  userLocale: string,
  options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  },
): string => {
  const dateTime = DateTime.fromISO(date);
  const formattedDate = dateTime.setLocale(userLocale).toLocaleString(options);
  return formattedDate.replace(/\s/g, '');
};

export const getLocaleFormattedDateFromDate = (
  date: Date,
  userLocale: string,
  options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  },
): string => {
  const dateTime = DateTime.fromJSDate(date);
  const formattedDate = dateTime.setLocale(userLocale).toLocaleString(options);
  return formattedDate.replace(/\s/g, '');
};

export const getTodayDate = () => {
  const today = DateTime.local().startOf('day');
  return today.toJSDate();
};
