import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const useTranslatedTitle = (titleKey: string) => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t(titleKey);
  }, [t, titleKey]);
};

export default useTranslatedTitle;
