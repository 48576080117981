import { useMsal } from '@azure/msal-react';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { PageRoute } from 'shared/interfaces/config.interface';
import { getBookingDetails } from 'shared/store/request.thunk';
import { RootState, useAppDispatch } from 'shared/store/root.store';
import EntertainmentBookingConfirmation from './EntertainmentBookingConfirmation';
import ThingsToDoBookingConfirmation from './ThingsToDoBookingConfirmation';

const Success = () => {
  const { state } = useLocation();
  const dispatch = useAppDispatch();
  const { accounts, instance } = useMsal();
  const { brandId, salesChannelId } = useSelector((state: RootState) => state.userSettings);
  const { bookingSession } = useSelector((state: RootState) => state.bookingSession);

  useEffect(() => {
    if (bookingSession?.value?.bookingreference) {
      dispatch(
        getBookingDetails({
          bookingReference: bookingSession.value.bookingreference.value,
          instance,
          account: accounts[0],
          brandId,
          salesChannelId,
        }),
      );
    }
  }, [
    dispatch,
    accounts,
    instance,
    bookingSession?.value?.bookingreference,
    brandId,
    salesChannelId,
  ]);

  const isEntertainmentCheckout = useMemo(
    () => state?.checkoutType === PageRoute.Entertainment.split('/').pop(),
    [state?.checkoutType],
  );
  const isThingsToDoCheckout = useMemo(
    () => state?.checkoutType === PageRoute.ThingsToDo.split('/').pop(),
    [state?.checkoutType],
  );

  return (
    <>
      {isEntertainmentCheckout && <EntertainmentBookingConfirmation />}
      {isThingsToDoCheckout && <ThingsToDoBookingConfirmation />}
    </>
  );
};

export default Success;
