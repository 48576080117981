import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const EmptyBasket = () => {
  const { t } = useTranslation();

  return (
    <Typography
      sx={{
        textAlign: 'center',
        padding: '20px',
        fontStyle: 'italic',
      }}
    >
      {t('productDetails.entertainment.emptyBasketTitle')}
    </Typography>
  );
};

export default EmptyBasket;
