import {
  AvailabilityStatus,
  SupplierPerformanceAvailability,
} from '@BookingPlatform/grpc/v1/Entertainment/Entertainment_pb';
import { Box, Button, Typography } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import 'style/themes/platform.scss';
import SeatingMapContainer from './SeatingMap/SeatingMapContainer';
import TicketTypeList from './TicketTypeList';

const TicketSupplier: React.FC<{ supplier: SupplierPerformanceAvailability.AsObject }> = ({
  supplier,
}) => {
  const { t } = useTranslation();

  const isUnavailable = (status: AvailabilityStatus): status is AvailabilityStatus.UNAVAILABLE => {
    return status === AvailabilityStatus.UNAVAILABLE;
  };

  const isSeatMapAvailable = React.useMemo(
    () => !!supplier.availabilitydetails?.seatplandata?.value,
    [supplier.availabilitydetails?.seatplandata?.value],
  );

  return (
    <>
      {!isUnavailable(supplier.availability) && (
        <Box
          sx={{
            p: 3,
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
          }}
        >
          {isSeatMapAvailable && <SeatingMapContainer supplier={supplier} />}
          {!isSeatMapAvailable && supplier.availabilitydetails?.tickettypesList && (
            <TicketTypeList
              supplierName={supplier.suppliername}
              availabilityDetails={supplier.availabilitydetails}
            />
          )}
          {supplier.redirectlink?.value ? (
            <Button
              href={supplier.redirectlink.value}
              target="_blank"
              sx={{
                color: 'white',
                background: 'var(--primary-main)',
                padding: '15px 25px',
                borderRadius: '15px',
                maxWidth: 'fit-content',
                margin: '0 auto',
                ':hover': {
                  color: 'white',
                  background: 'var(--primary-main)',
                  cursor: 'pointer',
                },
              }}
            >
              {t('general.bookOnExternalSite')}
            </Button>
          ) : null}
        </Box>
      )}
      {isUnavailable(supplier.availability) && (
        <Typography sx={{ margin: '3em', textAlign: 'center' }}>
          {t('productDetails.entertainment.noAvailableTicketsforPerformance')}
        </Typography>
      )}
    </>
  );
};

export default TicketSupplier;
