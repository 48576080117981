import dayjs from 'dayjs';
import { DateTime } from 'luxon';

// initialTime parameter: ISO 8601 string
// example: 2024-06-05T12:59:17.0000000Z
export const calculateTimeLeft = (initialTime: string) => {
  const target = new Date(initialTime).getTime();
  const now = new Date().getTime();
  const difference = target - now;

  const timeLeft = {
    days: Math.floor(difference / (1000 * 60 * 60 * 24)),
    hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
    minutes: Math.floor((difference / 1000 / 60) % 60),
    seconds: Math.floor((difference / 1000) % 60),
  };

  return difference > 0 ? timeLeft : null;
};

export function convertToMilitaryTime(time24hr: string) {
  const militaryTime = dayjs(time24hr, 'HH:mm').format('h:mm A');
  return militaryTime;
}

export function convertTimeTo12HourFormatLuxon(timeString: string) {
  const time = DateTime.fromFormat(timeString, 'HH:mm');
  return time.toFormat('h:mm a');
}
